import {Button, Center, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, VStack} from "@chakra-ui/react";
import {ReferralCard} from "../../../../components/referral/ReferralCard";
import {IoIosRefresh} from "react-icons/io";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {dashboardRoutePaths} from "../../../../routes/dashboard/dashboardRoutePaths";
import {IoWallet} from "react-icons/io5";

export function Referral(props){
    const userDetails = useSelector((state)=>{return state.userData})
    const [referralTree, setReferralTree] = useState({l0:[],l1:[],l2:[],l3:[],l4:[],l5:[],})

    const nav = useNavigate()

    useEffect(_=>{
        if(userDetails !== null && userDetails !== undefined){
            if(userDetails.referralData !== null && userDetails.referralData !== undefined){
                setReferralTree(userDetails.referralData)
            }
        }
    },[userDetails])

    return(
        <div>
            <div className={'md:p-4 p-2 flex w-full grid md:grid-flow-col grid-flow-row justify-between gap-4'}>
                <div className={'w-full'}>
                    <div className={'text-3xl text-left w-full font-semibold'}>
                        Referrals
                    </div>
                    <div className={'text-left w-full'}>Get details of your referral networks here</div>
                </div>
                <div className={'grid md:grid-flow-col grid-flow-row gap-4 w-full'}>
                    <Button colorScheme={'green'}
                            leftIcon={<IoWallet/>}
                            onClick={_=>{nav(`../${dashboardRoutePaths.referral_returns}`)}}>Show Wallet</Button>
                    <Button leftIcon={<IoIosRefresh/>}>Refresh Data</Button>
                </div>
            </div>
            <div>
                <Tabs variant='soft-rounded' colorScheme='green' size='md'>
                    <TabList className={'md:p-4 p-2'}>
                        <Tab>Direct</Tab>
                        <Tab>Level 1</Tab>
                        <Tab>Level 2</Tab>
                        <Tab>Level 3</Tab>
                        <Tab>Level 4</Tab>
                        <Tab>Level 5</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <div className={'w-full h-full grid grid-flow-cols md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-4'}>
                                {
                                    referralTree.l0.map((data, index)=>{
                                        return(
                                            <ReferralCard key={index} data={data}/>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={'w-full h-full grid grid-flow-cols md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-4'}>
                                {
                                    referralTree.l1.map((data, index)=>{
                                        return(
                                            <ReferralCard key={index} data={data}/>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={'w-full h-full grid grid-flow-cols md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-4'}>
                                {
                                    referralTree.l2.map((data, index)=>{
                                        return(
                                            <ReferralCard key={index} data={data}/>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={'w-full h-full grid grid-flow-cols md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-4'}>
                                {
                                    referralTree.l3.map((data, index)=>{
                                        return(
                                            <ReferralCard key={index} data={data}/>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={'w-full h-full grid grid-flow-cols md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-4'}>
                                {
                                    referralTree.l4.map((data, index)=>{
                                        return(
                                            <ReferralCard key={index} data={data}/>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={'w-full h-full grid grid-flow-cols md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-4'}>
                                {
                                    referralTree.l5.map((data, index)=>{
                                        return(
                                            <ReferralCard key={index} data={data}/>
                                        )
                                    })
                                }
                            </div>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </div>
    )
}
