import {useSelector} from "react-redux";
import {Avatar, Button} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {NavButton} from "./NavButton";
import {BiSupport} from "react-icons/bi";
import {Logo} from "../brand/Logo";
import {useNavigate} from "react-router-dom";
import {dashboardRoutePaths} from "../../routes/dashboard/dashboardRoutePaths";
import {NavProfileSection} from "./NavProfileSection";
import {Loading} from "../loading/Loding";
import {DataSafeRender} from "../dataSafe/DataSafeRender";
import {TextLogo} from "../brand/TextLogo";

export function NavBar(props){
    const nav = useNavigate()
    const className = props.className
    const userData = useSelector(state=>{return state.userData})
    const [userDetails, setUserDetails] = useState()

    useEffect(_=>{
        if(userData !== null && userData !== undefined){
            if(userData.data !== null && userData.data !== undefined && userData.isLoaded){
                setUserDetails(userData.data)
            }
        }
    },[userData])

    useEffect(_=>{

    },[userDetails])

    return(
        <div className={`min-w-[200px] max-w-[200px] min-h-screen justify-self-stretch p-4 ${className} border-r border-dotted border-black/30`}>
            <div className={'grid gap-4 grid-flow-row'}>
                <div className={'text-3xl mb-8'}><TextLogo/></div>
                <NavButton text={'Home'} icon={'fi fi-rr-home'} action={_=>{nav('')}}/>
                <NavButton text={'Profile'} icon={'fi fi-rr-user'} action={_=>{nav(dashboardRoutePaths.profile)}}/>
                <NavButton text={'Deposit'} icon={'fi fi-rr-credit-card'} action={_=>{nav(dashboardRoutePaths.deposit)}}/>
                <NavButton text={'Referrals'} icon={'fi fi-rr-users'} action={_=>{nav(dashboardRoutePaths.referral)}}/>
                <NavButton text={'Salary'} icon={'fi fi-rr-users'} action={_=>{nav(dashboardRoutePaths.salary)}}/>
            </div>
        </div>
    )
}
