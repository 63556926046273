import {collection, getFirestore, addDoc, updateDoc} from "firebase/firestore";
import {firestorePaths} from "../firestorePaths";

export function addDeposit(email, data){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        if(email !== null && email !== undefined && data !== null && data !== undefined){
            const colRef = collection(db, firestorePaths.collections.deposits)
            addDoc(colRef, data)
                .then(ref=>
                    updateDoc(ref, {id: ref.id})
                        .then(_=>resolve(0))
                        .catch(e=>reject(e))
                )
                .catch(e=>reject(e))
        }else reject(Error('Email or data is null/undefined'))
    })
}
