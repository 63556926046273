import {getAuth, sendEmailVerification, sendPasswordResetEmail} from "firebase/auth";
import clearAllState from "../../redux/tools/clearAllState";

export function logout(){
    return new Promise((resolve, reject)=>{
        const auth = getAuth()
        auth.signOut()
            .then(r => {
                    clearAllState()
                    resolve(0)
                }
            )
            .catch(err=>reject(err))
    })
}

export function sendEmailVerificationLink(){
    return new Promise((resolve, reject)=>{
        const auth = getAuth()
        sendEmailVerification(auth.currentUser).then(_=>{
            resolve(auth.currentUser.email)
        }).catch(err=>{
            reject(err)
        })
    })
}

export function sendPasswordResetLink(email){
    return new Promise((resolve, reject)=>{
        if(email !== null && email !== undefined && email !== ''){
            const auth = getAuth();
            sendPasswordResetEmail(auth, email).then(_=>{
                resolve(0)
            }).catch(e=>{
                reject(e)
            })
        } else reject(Error("Invalid email"))
    })
}
