import scribble from "../../raw/img/vectors/circle_connect_scribble.svg"
export function ReferralNotAllowedCard(props){
    return(
        <div className={`group rounded-lg w-full h-full lg:col-span-2 bg-black min-h-[150px] text-background`}
             style={{backgroundImage: `url(${scribble})`,
                 backgroundRepeat: "no-repeat",
                 backgroundPosition: "110% 110%",
                 backgroundSize: "50% 100%"}}>
            <div className={'align-middle h-full p-6 rounded-lg group-hover:backdrop-blur-lg transition ease-in-out delay-100'}>
                <div className={'text-left text-3xl'}>Referral Link</div>
                <div className={'mt-4 text-left text-[1.2em]'}>
                    <p>
                        <div>
                            Sorry, you are not yet eligible for our referral program yet. <br/>
                            Contact us if you think it's a mistake and we have missed something.</div>
                    </p>
                </div>
                <div className={'mt-8 border-2 pl-2 border-white/30 border-dashed rounded-lg h-fit w-fit'}>
                    <div className={'lg:text-md text-left text-lg text-background p-2 w-full'}>
                        You are not yet eligible for referral.
                    </div>
                </div>
            </div>
        </div>
    )
}
