import {Button, Center, HStack, Input, useToast} from "@chakra-ui/react";
import {useState} from "react";
import {sendPasswordResetLink} from "../../firebase/auth/callbacks";
import {ErrorToast, SuccessToast} from "../../utils/ToastBuilder";
import LottiePlayer from "../../components/animated/LottiePlayer";
import forgotPassAnim from "../../raw/anim/forgotPassword.json";
import {PrimaryButton} from "../../components/buttons/PrimaryButton";
import AnimDivClick from "../../components/animated/AnimDivClick";
import {mainRoutePaths} from "../../routes/mainRoutePaths";
import {useNavigate} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import gradientBG from "../../raw/img/vectors/gradient.svg";
import {TopBar} from "../../components/nav/TopBar";

export function ForgotPassword(props){
    const [email, setEmail] = useState('')
    const nav = useNavigate()
    const toast = useToast()

    return(
        <div className={'w-full h-fit text-center'}>
            <TopBar/>
            <div className={'grid lg:grid-cols-2 gap-4'}>
                <div  style={{opacity:0.8}}  className={'grid place-content-center p-8'}>
                    <LottiePlayer options={{loop: true, autoPlay: true, animationData: forgotPassAnim}} />
                </div>
                <div className={'w-full h-full m-auto justify-center text-left p-4'}>
                    <div className={'mt-8 text-2xl font-bold'}>Forgot password</div>
                    <div className={'mt-4 mb-8'}>Enter your registered email address to get password reset link</div>
                    <Input
                        borderColor={'black'}
                        _placeholder={{ opacity: 0.7, color: 'black' }}
                        placeholder={'Email'}
                        type={'email'}
                        value={email}
                        onChange={e=>{setEmail(e.target.value)}}/>
                    <div className={'mt-8 flex place-content-between'}>
                        <div className={'grid place-content-start'}>
                            <PrimaryButton className={'p-4 w-fit'} onClick={_=>{
                                sendPasswordResetLink(email.toLowerCase().trim()).then(_=>{
                                    toast(SuccessToast("Successful", `Sent reset link to ${email}`))
                                }).catch(e=>{
                                    toast(ErrorToast("Failed", e.message))
                                })
                            }
                            }>Confirm</PrimaryButton>
                        </div>
                        <AnimDivClick
                            className={'grid place-content-center font-semibold text-md w-fit border-black rounded-md hover:text-red-600 hover:cursor-pointer text-[1.2em]'}
                            onClick={_=>{nav(-1)}}>
                            <HStack>
                                <BiArrowBack/>
                                <div>Go Back</div>
                            </HStack>
                        </AnimDivClick>
                    </div>
                </div>
            </div>
        </div>
    )
}
