/**
 * @author Aman K. Nirala
 * @copyright trade-cops-india
 * @date 28-11-2022
 */

import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {UserModel} from "../../../firebase/models/UserModel";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay, Badge,
    Button,
    HStack, Input, InputGroup, InputLeftAddon,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr, useDisclosure, useToast,
    VStack
} from "@chakra-ui/react";
import {ErrorToast, SuccessToast} from "../../../utils/ToastBuilder";
import {PaymentStatusChip} from "../../../components/status/PaymentStatusChip";
import Payment_Status from "../../../utils/Payment_Status";
import {useNavigate} from "react-router-dom";
import {dashboardRoutePaths} from "../../../routes/dashboard/dashboardRoutePaths";
import {GetDeposits} from "../../../firebase/database/get/GetDeposits";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import {setDepositList} from "../../../redux/reducer/userDepositsReducer";
import {IoIosRefresh} from "react-icons/io";
import {FiInfo} from "react-icons/fi";
import {MdAttachMoney} from "react-icons/md";
import {ReferralCard} from "../../../components/referral/ReferralCard";
import {format} from "date-fns";

export function Deposit(props){
    const userData = useSelector(state=>{return state.userData})
    const userDeposits= useSelector(state=>{return state.userDeposits})
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const toast = useToast()
    const nav = useNavigate()
    const dispatch = useDispatch()

    const [minAmount, setMinAmount] = useState(100000)
    const [userDetails, setUserDetails] = useState(UserModel())
    const [depositAmountText, setDepositAmountText] = useState('')
    const [tableList, setTableList] = useState([])
    const [currentDepositsBalance, setCurrentDepositsBalance] = useState(0.0)
    const [isRefreshBusy, setIsRefreshBusy] = useState(false)

    const handelDepositPayClick = ()=>{
        let amount;
        try{
            amount = parseInt(depositAmountText)
        }catch(e){
            toast(ErrorToast('Invalid amount', e.message))
            return;
        }
        if(amount >= minAmount){
            nav(`../${dashboardRoutePaths.payment}/${amount}`)
        }else{
            toast(ErrorToast('Low amount', `Amount must be more than or equal to ₹${minAmount}`))
        }
    }

    function refreshData(){
        setIsRefreshBusy(true)
        if(userDetails !== undefined) {
            GetDeposits(userDetails[firestorePaths.doc.user.email]).then(list => {
                setIsRefreshBusy(false)
                dispatch(setDepositList(list))
            }).catch(e => {
                setIsRefreshBusy(false)
                toast(ErrorToast('Error', e.message))
            })
        }
    }

    useEffect(_=>{
        if(userData !== null && userData !== undefined && userData.isLoaded)
            setUserDetails(userData.data)
    },[userData])

    useEffect(_=>{
        if(userDetails !== undefined) {
            if(userDetails[firestorePaths.doc.user.email] !== null){
                if(userDeposits.data.length === 0)
                    GetDeposits(userDetails[firestorePaths.doc.user.email]).then(list => {
                        dispatch(setDepositList(list))
                    }).catch(e => {
                        toast(ErrorToast('Error', e.message))
                    })
            }
        }
    },[userDetails])

    useEffect(_=>{
        if(userDeposits.isLoaded) {
            if(userDeposits.data.length !== 0)
                setTableList(userDeposits.data)
            if(userDeposits.data.length !== 0){
                let balance = 0;
                userDeposits.data
                    .filter(r=>(r[firestorePaths.doc.deposit.status] === Payment_Status.COMPLETE))
                    .forEach(element=>{
                        balance += parseFloat(element[firestorePaths.doc.deposit.amount])
                    })
                setCurrentDepositsBalance(balance)
            }else setCurrentDepositsBalance(0.0)
        }
    },[userDeposits])

    return(
        <div className={'w-full max-w-full h-full p-8'}>
            <div className={'text-left'}>
                <div className={'grid md:grid-flow-col grid-flow-row gap-8 justify-between'}>
                    <div className={'text-left bg-emerald-500 shadow-emerald-300 shadow-xl rounded-xl p-6 text-white'}>
                        <div className={'text-xl font-semibold'}>Current Deposits</div>
                        <div className={'text-3xl font-bold'}>₹ {currentDepositsBalance}</div>
                    </div>
                    <div className={'grid md:grid-cols-2 md:grid-flow-col grid-flow-row gap-2'}>
                        <Button colorScheme={'green'} onClick={onOpen} leftIcon={<MdAttachMoney/>}>Deposit Amount</Button>
                        {/*<Button colorScheme={'blue'} className={'w-full'} leftIcon={<FiInfo/>}>Show Plans</Button>*/}
                        <Button colorScheme={'gray'} className={'w-full'} leftIcon={<IoIosRefresh/>}
                                onClick={_=>{refreshData()}} isLoading={isRefreshBusy}>Refresh</Button>
                    </div>
                </div>
                <div className={'mt-4 pt-8 rounded-lg'}>
                    <TableContainer>
                        <Table  size={'sm'}>
                            <Thead>
                                <Tr>
                                    <Th>T. Date</Th>
                                    <Th>Amount</Th>
                                    <Th>T. No.</Th>
                                    <Th>Payment Type</Th>
                                    <Th>Status</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    tableList.map((data, index)=>{
                                        return (<Tr key={index} className={''}>
                                                    <Td>{format(new Date(data[firestorePaths.doc.deposit.date]), "dd/MM/yyyy")}</Td>
                                                    <Td className={'font-bold'}>₹ {data[firestorePaths.doc.deposit.amount]}</Td>
                                                    <Td>#{data[firestorePaths.doc.deposit.transaction_number]}</Td>
                                                    <Td>{data[firestorePaths.doc.deposit.type]}</Td>
                                                    <Td><PaymentStatusChip status={data[firestorePaths.doc.deposit.status]}/></Td>
                                                    <Td size={'sm'}>
                                                        <VStack>
                                                            {/*<Button
                                                                className={'w-full'}
                                                                size={'sm'}
                                                                isDisabled={
                                                                    (data[firestorePaths.doc.deposit.status] !== Payment_Status.COMPLETE)}
                                                                colorScheme={'green'}>
                                                                Show Earnings
                                                            </Button>*/}
                                                            <Button
                                                                className={'w-full'}
                                                                onClick={_=>{
                                                                    nav(`../${dashboardRoutePaths.deposit}/${data[firestorePaths.doc.deposit.id]}`)
                                                                }}
                                                                size={'sm'}>
                                                                View Details
                                                            </Button>
                                                        </VStack>
                                                    </Td>
                                                </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered>
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>Enter amount to deposit</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <VStack>
                            <div className={'text-left w-full pb-2'}>Please enter an amount of at least
                                <Badge colorScheme='gray' fontSize={'1rem'} className={'ml-2 mr-2'}>₹{minAmount}</Badge></div>
                            <InputGroup>
                                <InputLeftAddon children='₹' />
                                <Input type='number' placeholder='Amount' value={depositAmountText}
                                       onChange={e=>{setDepositAmountText(e.target.value)}}/>
                            </InputGroup>
                        </VStack>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={'w-1/3'} colorScheme='green' ml={3} onClick={handelDepositPayClick}>
                            Pay
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    )
}
