import noise from "../../../raw/img/graphics/noise.png";
import {motion} from "framer-motion";
import AnimDivClick from "../../animated/AnimDivClick";
function KYCStepCard({className, count, title, details, scribble}){
    return(
        <AnimDivClick
            className={`${className} hover:bg-white/10 hover:shadow-xl border-[2px] border-black/10 hover:border-transparent hover:z-50 hover:backdrop-blur-lg rounded-xl bg-transparent h-[150px] w-full justify-self-center place-self-center m-auto`}>
            <div className={'w-full h-full'}
                 style={{backgroundImage: `url(${scribble})`,
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "right",
                     backgroundSize: "50% 100%"}}>
                <div className={'grid content-end w-full h-full p-4 rounded-xl hover:backdrop-blur-2xl'}>
                    <div className={'w-full text-left'}>Step {count}</div>
                    <div className={'w-full text-left text-xl mt-4'}>{title}</div>
                    <div className={'w-full mt-2'}>{details}</div>
                </div>
            </div>
        </AnimDivClick>
    )
}

export default KYCStepCard
