import gradientBG from "../../../raw/img/vectors/gradient.svg"
import AadhaarCard from "../../../components/cards/aadhaarCard";
import useProgress from "../../../components/progressBar/hooks/useProgress";
import {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {AnimButtonContainer} from "../../../components/buttons/AnimButtonContainer";
import {PrimaryButton} from "../../../components/buttons/PrimaryButton";
import DigiLockerCreateKYCUrl from "../../../firebase/functions/DigiLockerCreateKYCUrl";
import useAuthUserState from "../../../hooks/state/useAuthUserState";
import {DataSafeRender} from "../../../components/dataSafe/DataSafeRender";
import {Loading} from "../../../components/loading/Loding";
import useUserDataState from "../../../hooks/state/useUserDataState";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import {ConditionalRender} from "../../../components/dataSafe/ConditionalRender";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import {AiOutlineAlignRight, AiOutlineArrowRight} from "react-icons/ai";
import {HStack, useToast} from "@chakra-ui/react";
import LogoutButton from "../../../components/buttons/LogoutButton";
import {useNavigate} from "react-router-dom";
import {BiRefresh, BiShield} from "react-icons/bi";
import {kycV2RoutePaths} from "../../../routes/kyc_v2/kycV2RoutePaths";
import {ErrorToast} from "../../../utils/ToastBuilder";
import avatarM from "../../../raw/img/vectors/avatar_m.svg"
function AadhaarKYCScreen(){
    const [progress,_,__,setProgress] = useProgress()
    const aadhaarCardHolderRef = useRef()
    const nav = useNavigate()
    const toast = useToast()

    const [authUser, authToken, ___] = useAuthUserState()
    const [userData, referralData, refreshReferralData, loading] = useUserDataState()

    const [isBusy, setIsBusy] = useState(false)
    const [isAadhaarVerified, setIsAadhaarVerified] = useState(false)

    const [kycFullName, setKycFullName] = useState("")
    const [kycGender, setKycGender] = useState("")
    const [kycDob, setKycDob] = useState("")
    const [kycAddress, setKycAddress] = useState("")
    const [kycPinCode, setKycPinCode] = useState("")
    const [kycUid, setKycUid] = useState("")
    const [kycPhoto, setKycPhoto] = useState("")

    const handelDigiLockerAction = _=>{
        setIsBusy(true)
        DigiLockerCreateKYCUrl(authToken).then(r => {
            if(r.data?.url){
                const anchorElement = document.createElement('a');
                anchorElement.href = r.data.url;
                anchorElement.target = '_self'; // Open in the same tab/window

                // Simulate a click event on the anchor element
                const clickEvent = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                });
                anchorElement.dispatchEvent(clickEvent);
                setIsBusy(false)
            }
        }).catch(e=>{
            setIsBusy(false)
            toast(ErrorToast("Failed", e.message))
            console.log("error on kyc", e)
        })
    }

    useEffect(_=>{
        setProgress((100 / 4))
        return _=>{
            setProgress(0)
        }
    },[])

    useEffect(_=>{
        if(userData !== null && userData !== undefined) {
            setIsAadhaarVerified(userData[firestorePaths.doc.user.aadhaar_valid] === true)
            const eAadhaarData = userData[firestorePaths.doc.user.e_aadhaar_data.path]
            if(eAadhaarData){
                if(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.name])
                    setKycFullName(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.name])
                if(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.dob])
                    setKycDob(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.dob])
                if(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.uid])
                    setKycUid(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.uid])
                if(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.gender])
                    setKycGender(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.gender])
                if(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.photo])
                    setKycPhoto(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.photo])
                if(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.pin_code])
                    setKycPinCode(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.pin_code])
                if(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.address])
                    setKycAddress(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.address])
            }

        }
    },[userData])

    useEffect(_=>{
        let ctx = gsap.context(_=>{
            const timeline = gsap.timeline({repeat: -1})
            timeline
                .to(aadhaarCardHolderRef.current, {backgroundPosition: "50% 50%", duration: 2})
                .to(aadhaarCardHolderRef.current, {backgroundPosition: "80% 20%", duration: 2})
                .to(aadhaarCardHolderRef.current, {backgroundPosition: "80% 80%", duration: 2})
                .to(aadhaarCardHolderRef.current, {backgroundPosition: "20% 80%", duration: 2})
                .to(aadhaarCardHolderRef.current, {backgroundPosition: "50% 50%", duration: 2})

            return () => {
                timeline.kill();
            }
        })
        return(_=>{
            ctx.kill()
        })
    },[])

    return(
        <div className={'w-full h-full p-4'}>
            <div className={'w-full text-left flex flex-col-reverse lg:flex-row'}>
                <div className={'w-full h-full lg:ml-24 lg:mt-0 mt-8'}>
                    <div className={'text-black/60 text-[1.2em] mb-8'}>Step 1 of 4</div>
                    <div className={'text-2xl font-semibold mb-4'}>Aadhaar KYC (DigiLocker)</div>
                    <p className={'lg:mr-12 leading-7'}>
                        Share your address-related information from DigiLocker account for KYC.<br/>
                        Your Aadhaar number/copy will be stored for future reference.
                        The Aadhaar UID should belong to you, it is a 12 digit number available at the bottom of your Aadhaar card.
                    </p>
                    <ConditionalRender
                        condition={!isAadhaarVerified}
                        alt={
                            <DataSafeRender values={[authUser, authToken]} alt={<Loading/>}>
                                <div className={'w-full h-full grid grid-cols-1 gap-16 place-content-between items-center'}>
                                    <div className={'border-[2px] border-black/50 p-4 mt-8 rounded-xl flex items-center content-center'}>
                                        <div className={'grid place-content-center p-4 rounded-lg'}>
                                            <img className={'rounded-xl'}
                                                 src={(!kycPhoto || kycPhoto === "")?avatarM:kycPhoto}
                                                 width={180}/>
                                        </div>
                                        <div className={'w-full h-full'}>
                                            <HStack>
                                                <div>Name: </div>
                                                <div>{kycFullName}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>Date of Birth: </div>
                                                <div>{kycDob}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>Gender: </div>
                                                <div>{kycGender}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>UID: </div>
                                                <div>{kycUid}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>Address: </div>
                                                <div>{kycAddress}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>Pin Code: </div>
                                                <div>{kycPinCode}</div>
                                            </HStack>
                                        </div>
                                    </div>
                                    <AnimButtonContainer className={'w-full place-content-start'}>
                                        <PrimaryButton
                                            className={'py-[16px] px-[32px] shadow-xl'}
                                            onClick={_=>{
                                                nav(`../${kycV2RoutePaths.pan_kyc}`)
                                            }}>
                                            <HStack>
                                                <div>Next step</div>
                                                <AiOutlineArrowRight/>
                                            </HStack>
                                        </PrimaryButton>
                                    </AnimButtonContainer>
                                    <AnimButtonContainer className={'mt-8 place-content-start'}>
                                        <SecondaryButton className={"py-[8px] px-[4px]"}
                                                         onClick={_=>{setIsAadhaarVerified(false)}}>
                                            <HStack>
                                                <div>Restart process</div>
                                                <BiRefresh/>
                                            </HStack>
                                        </SecondaryButton>
                                    </AnimButtonContainer>
                                </div>
                            </DataSafeRender>
                    }>
                        <DataSafeRender values={[authUser, authToken]} alt={<Loading/>}>
                            <AnimButtonContainer className={'mt-8 w-full place-content-start'}>
                                <PrimaryButton
                                    isBusy={isBusy}
                                    busyText={"Redirecting to DigiLocker"}
                                    className={'py-[16px] px-[32px] shadow-xl'} onClick={handelDigiLockerAction}>
                                    <HStack>
                                        <span className={'text-lg'}><BiShield/></span>
                                        <div>Continue to DigiLocker</div>
                                    </HStack>
                                </PrimaryButton>
                            </AnimButtonContainer>
                        </DataSafeRender>
                    </ConditionalRender>
                </div>
                <div ref={aadhaarCardHolderRef} className={'h-full w-full rounded-2xl lg:h-3/5 grid place-content-start justify-center p-4'}
                     style={{backgroundImage: `url(${gradientBG})`,
                         backgroundSize: "contain",
                         backgroundRepeat: "no-repeat",
                         backgroundPosition:"center"}}>
                    <AadhaarCard checking={true} className={'mb-16 lg:mt-16'} verified={isAadhaarVerified}/>
                </div>
            </div>
            <div className={'lg:mt-16 mt-24 lg:mx-24 text-left'}>
                Online account opening requires your mobile number to be linked to Aadhaar. You can check if your mobile number is linked to Aadhaar here. If your mobile number isn't linked to Aadhaar, please open your account offline.
                <span className={'text-primary'}> Know more</span>.
            </div>
            <div className={'mt-4 lg:mx-24 text-left'}>
                Online account opening involves using Digilocker.gov.in to obtain your identity proof if you are not already KRA verified, and digitally signing your application form with
                Aadhaar eSign using Digio (licensed ASP). We do not collect or store your Aadhaar number, and neither of these services reveal your Aadhaar number to us. If you do
                not wish to do this, please use our offline forms for account opening. Zerodha does not perform eKYC and does not collect or store Aadhaar numbers.
                <span className={'text-primary'}> Know more</span>.
            </div>
            <div className={'mt-4 lg:mx-24 text-left'}>
                Please Note: Your account name would be taken as per the name registered on the Income Tax database / as per your PAN.
                <span className={'text-primary'}> Know more</span>.
            </div>
            <div className={'mt-4 lg:mx-24 text-left'}>
                If you are looking to open a HUF, Corporate, Partnership, or an NRI account, you have to use the offline <span className={'text-primary'}>forms</span>. For help, <span className={'text-primary'}>click here</span>.
            </div>
        </div>
    )
}

export default AadhaarKYCScreen
