export const firestorePaths = {
    collections: {
        users: "users",
        deposits: "deposits",
        salary: "salary",
        referral_returns: "referral_returns",
        events:"events",
        events_special_invitations : "special_invitations"
    },
    doc:{
        user:{
            email:"email",
            first_name:"first_name",
            middle_name:"middle_name",
            last_name:"last_name",
            referral:"referral",
            referral_code:"referral_code",
            dob:"dob",
            phone:"phone",
            address:"address",
            address_proof_url: "address_proof_url",
            zip_code:"zip_code",
            kyc_status:"kyc_status",
            kyc_comment:"kyc_comment",
            pic_url:"pic_url",
            id_proof_url:"id_proof_url",
            pan_url:"pan_url",
            cheque_book_url:"cheque_book_url",
            version:"version",
            aadhaar_card_font_url:"aadhaar_card_font_url",
            aadhaar_card_back_url:"aadhaar_card_back_url",
            amount:"amount",
            bank_details: {
                path:  "bank_details",
                pan_number: "pan_number",
                bank_account_no:"bank_account_no",
                bank_account_ifsc:"bank_account_ifsc",
                bank_account_holder_name: "bank_account_holder_name",
                bank_name:"bank_name",
                bank_address:"bank_address",
                bank_account_type:"bank_account_type",
                timestamp: "timestamp",
            },
            signature_url:"signature_url",
            adminReferralAllowed: "admin_referral_allowed",
            referralAccessRequested: "referral_access_requested",
            registered_date: "registered_date",
            registration_eligible: "registration_eligible",
            registration_fees_status: "registration_fees_status",
            registration_fees_id: "registration_fees_id",
            registration_fees_receipt: "registration_fees_receipt",
            registration_fees_last_updated: "registration_fees_last_updated",
            digiLocker_data: {
                path:"digiLocker_data",
                request_id: "requestId",
                status: "status",
                timestamp: "timestamp"
            },
            e_aadhaar_data: {
                path:"e_aadhaar_data",
                name: "name",
                dob: "dob",
                gender: "gender",
                address: "address",
                pin_code: "pin_code",
                photo: "photo",
                uid: "uid",
                request_id: "request_id",
                timestamp: "timestamp",
            },
            aadhaar_valid: "e_aadhaar_valid",
            pan_valid: "pan_valid",
            pan_number: "pan_number",
            pan_data:{
                path: "pan_data",
                full_name: "full_name",
                number: "number",
                type: "type",
                individual: "individual",
                valid: "valid",
                first_name: "first_name",
                middle_name: "middle_name",
                last_name: "last_name",
                title: "title",
                pan_status_code: "pan_status_code",
                pan_status: "pan_status",
                last_updated: "last_updated",
                aadhaar_seeding_status: "aadhaar_seeding_status",
                timestamp: "timestamp",
            },
            bank_valid: "bank_valid",
            bank_verification_data:{
                path: "bank_verification_data"
            }
        },
        deposit:{
            id:"id",
            owner:"owner",
            amount:"amount",
            type:"type",
            transaction_number:"transaction_number",
            receipt_url:"receipt_url",
            date:"date",
            status:"status",
            plan_state:"plan_state",
            write_date: "write_date",
        },
        referral_return:{
            amount:"amount",
            owner:"owner",
            from:"from",
            deposit_id:"deposit_id",
            deposit_owner:"deposit_owner",
            date:"date",
            status:"status",
        },
        salary:{
            credit_line:{
                path: "credit_line",
                amount: "amount",
                date: "date",
                id: "id",
                status: "status",
                last_updated: "last_updated",
            },
            owner:"owner",
            ref_month:"ref_month",
            ref_year: "ref_year",
            start_date: "start_date",
            end_date:"end_date",
            status:"status",
            last_checked: "last_checked",
            last_credit: "last_credit",
            referral_list: "referral_list",
            write_date: "write_date",
        },
    }
}
