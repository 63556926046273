export const dashboardRoutePaths = {
    profile: "profile",
    withdraw: "withdraw",
    deposit: "deposit",
    settings: "settings",
    payment: "payment",
    referral_returns: "referral_returns",
    referral: "referral",
    salary: "salary",
    event_award_1: "1st-award-ceremony",
}
