import {Button, Divider, Input, InputGroup, InputRightElement, useToast} from "@chakra-ui/react";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import {useState} from "react";
import {mainRoutePaths} from "../../routes/mainRoutePaths";
import {useNavigate, useParams} from "react-router-dom";
import {getAuth} from "firebase/auth";
import {firebaseCreateAccountWithEmail} from "../../firebase/auth/firebaeCreateAccountWithEmail";
import {UserModel} from "../../firebase/models/UserModel";
import {updateUser} from "../../redux/reducer/authUserReducer";
import {useDispatch} from "react-redux";
import {ErrorToast, SuccessToast} from "../../utils/ToastBuilder";
import { Checkbox} from '@chakra-ui/react'
import AnimDivClick from "../../components/animated/AnimDivClick";
import FadingDivider from "../../components/divider";
import {PrimaryButton} from "../../components/buttons/PrimaryButton";
import passwordGraphics from "../../raw/img/graphics/password_graphics.png"
import gradientBG from "../../raw/img/vectors/gradient.svg";

export function RegistrationPage(props){

    const {referral} = useParams()
    const nav = useNavigate()
    const dispatch = useDispatch()
    const toast = useToast()

    const [emailText, setEmailText] = useState('')
    const [passwordText, setPasswordText] = useState('')
    const [firstNameText, setFirstNameText] = useState('')
    const [middleNameText, setMiddleNameText] = useState('')
    const [lastNameText, setLastNameText] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [referralCode, setReferralCode] = useState(referral ? referral : '')
    const [phoneText, setPhoneText] = useState('')
    const [agreeTC, setAgreeTC] = useState(false)

    const [viewPassword, setViewPassword] = useState(false)
    const [isBusy, setIsBusy] = useState(false)

    function isValid(){
        return(
            emailText !== "" &&
            passwordText !== "" &&
            firstNameText !== "" &&
            dateOfBirth !== "" &&
            phoneText !== ""
        )
    }

    const handelRegisterCallback = _=>{
        if(!isValid()){
            toast(ErrorToast("Invalid input", "Some field are missing. Please enter valid inputs"))
            return
        }
        if(!agreeTC){
            toast(ErrorToast("Terms and Conditions not checked", "Please read and agree to the Terms and Conditions"))
            return
        }
        setIsBusy(true)
        const user =  UserModel(
            emailText.toLowerCase().trim(),
            firstNameText.trim(),
            middleNameText.trim(),
            lastNameText.trim(),
            referralCode.trim(),
            dateOfBirth,
            phoneText.trim())
        firebaseCreateAccountWithEmail(emailText, passwordText, user).then(userCreds=>{
            dispatch(updateUser(userCreds))
            setIsBusy(false)
            nav('/')
        }).catch(err=>{
            setIsBusy(false)
            toast(ErrorToast('Failed to create account', err.message))
        })
    }

    return(
        <div className={'w-full h-full'}>

            <div className={'w-full grid lg:grid-cols-2 grid-cols-1 gap-4'}>
                <div className={'w-full h-fit grid justify-center'}>
                    <img src={passwordGraphics}
                         width={"500px"}
                         alt={"password_graphics"}
                    />
                </div>
                <div className={'w-full grid place-content-center pl-4 pr-4'}>
                    <div className={'w-full m-auto lg:w-2/3'}>
                        <div className={'mb-4 text-2xl font-bold w-full text-left'}>Registration</div>
                        <div>
                            <div className={'mt-4'}>
                                <Input className={'mt-4'}
                                       borderColor={'black'}
                                       _placeholder={{ opacity: 0.7, color: 'black' }}
                                       placeholder={'Email *'}
                                       type={'email'}
                                       value = {emailText}
                                       onChange = {e=>{setEmailText(e.target.value)}}
                                       variant={'outline'}/>

                                <InputGroup className={'mt-4'}>
                                    <Input placeholder={'Password *'}
                                           borderColor={'black'}
                                           _placeholder={{ opacity: 0.7, color: 'black' }}
                                           type={viewPassword ? 'text' : 'password'}
                                           value = {passwordText}
                                           onChange = {e=>{setPasswordText(e.target.value)}}
                                           variant={'outline'}/>
                                    <InputRightElement onClick={_=>{setViewPassword(!viewPassword)}}>
                                        {viewPassword ? <AiFillEyeInvisible/> : <AiFillEye/>}
                                    </InputRightElement>
                                </InputGroup>

                                <div className={'text-left mt-6'}>
                                    Personal details <span className={'text-red-600'}>(as per your official documents)</span>
                                </div>

                                <Input className={'mt-4'}
                                       borderColor={'black'}
                                       _placeholder={{ opacity: 0.7, color: 'black' }}
                                       placeholder={'First Name *'}
                                       type={'name'}
                                       value = {firstNameText}
                                       onChange = {e=>{setFirstNameText(e.target.value)}}
                                       variant={'outline'}/>
                                <div className={'grid grid-flow-col gap-4'}>
                                    <Input className={'mt-4'}
                                           borderColor={'black'}
                                           _placeholder={{ opacity: 0.7, color: 'black' }}
                                           placeholder={'Middle Name'}
                                           type={'name'}
                                           value = {middleNameText}
                                           onChange = {e=>{setMiddleNameText(e.target.value)}}
                                           variant={'outline'}/>
                                    <Input className={'mt-4'}
                                           borderColor={'black'}
                                           _placeholder={{ opacity: 0.7, color: 'black' }}
                                           placeholder={'Last Name'}
                                           type={'name'}
                                           value = {lastNameText}
                                           onChange = {e=>{setLastNameText(e.target.value)}}
                                           variant={'outline'}/>
                                </div>

                                <Input className={'mt-4'}
                                       borderColor={'black'}
                                       _placeholder={{ opacity: 0.7, color: 'black' }}
                                       placeholder={'Phone number *'}
                                       type={'tel'}
                                       value = {phoneText}
                                       onChange = {e=>{setPhoneText(e.target.value)}}
                                       variant={'outline'}/>
                                <div className={'text-left mt-4'}>
                                    Date of Birth <span className={'text-red-600'}>(as per your official documents)</span>
                                </div>

                                <div className={'grid grid-flow-col gap-4 mt-4'}>
                                    <Input placeholder={'Date of Birth *'}
                                           borderColor={'black'}
                                           _placeholder={{ opacity: 0.7, color: 'black' }}
                                           value={dateOfBirth}
                                           type={'date'}
                                           onChange={e=>{setDateOfBirth(e.target.value);}}/>
                                </div>
                            </div>

                            <div className={'text-left mt-6'}>
                                Referral (optional)
                            </div>

                            <div className={''}>
                                <Input className={'mt-4'}
                                       borderColor={'black'}
                                       _placeholder={{ opacity: 0.7, color: 'black' }}
                                       placeholder={'Referral code (optional)'}
                                       type={'text'}
                                       value = {referralCode}
                                       onChange = {e=>{setReferralCode(e.target.value)}}
                                       variant={'outline'}/>
                            </div>

                            <div className={'mt-8 p-4 rounded-xl'}>
                                <div className={'flex gap-4'}>
                                    <Checkbox size={'lg'}
                                              colorScheme={'green'}
                                              borderColor={'black'}
                                              iconColor={'white'}
                                              onChange={(e) =>  setAgreeTC(e.target.checked)}
                                    ></Checkbox>
                                    <span className={'text-[1em] text-left'}>By continuing, I confirm that <span className={'font-semibold text-red-500'}>I have checked and filled all the information as per my official documents, correctly</span>. I confirm that I have read and agreed to all the
                                <a className={'text-primary font-bold'} target={"_blank"} href={"https://tradecops.com/terms-and-conditions/"}> Terms and Conditions </a> and
                                <a className={'text-primary font-bold'} target={"_blank"} href={"https://tradecops.com/disclaimer/"}> Disclaimer</a></span>
                                </div>
                            </div>
                            <div className={'grid place-content-start mt-8'}>
                                <PrimaryButton
                                    className={'w-fit p-4'}
                                    isBusy={isBusy}
                                    busyText={"Registering"}
                                    onClick={handelRegisterCallback}>
                                    Create account
                                </PrimaryButton>
                            </div>
                            <FadingDivider className={'mt-8 mb-8'}/>
                            <div className={'grid grid-flow-col gap-4 mt-8 justify-between'}>
                                <AnimDivClick className={'hover:text-primary font-semibold cursor-pointer p-2 text-right w-fit rounded-lg'}
                                              onClick={_=>{nav(`/${mainRoutePaths.login}`)}}>
                                    Already registered ?
                                </AnimDivClick>
                                <AnimDivClick className={'hover:text-red-500 cursor-pointer font-semibold p-2 text-right w-fit rounded-lg'}
                                              onClick={_=>{nav(`../${mainRoutePaths.forgotPassword}`)}}>
                                    Forgot Password ?
                                </AnimDivClick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'mt-16 text-zinc-600 p-4'}>
                    <span>By submitting your contact details, you authorise TradeCops to call or text you for the purpose of account opening even
though you may be registered on DND.</span>
            </div>
        </div>
    )
}
