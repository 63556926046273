import {Card, CardBody, Heading, Stack, Text, Image, Divider, Avatar, Tag} from "@chakra-ui/react";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {useNavigate} from "react-router-dom";
import {DataSafeRender} from "../dataSafe/DataSafeRender";
import {format} from "date-fns";

export function ReferralCard(props){
    const data = props.data
    if (data === null || data === undefined) return <div>No data</div>
    const firstName = data[firestorePaths.doc.user.first_name] ? data[firestorePaths.doc.user.first_name] : ''
    const middleName = data[firestorePaths.doc.user.middle_name] ? data[firestorePaths.doc.user.middle_name] : ''
    const lastName = data[firestorePaths.doc.user.last_name] ? data[firestorePaths.doc.user.last_name]: ''
    const email = data[firestorePaths.doc.user.email]
    const amount = (data[firestorePaths.doc.user.amount] === null ||
        data[firestorePaths.doc.user.amount] === undefined)? 0 : data[firestorePaths.doc.user.amount]
    const registeredDate = data[firestorePaths.doc.user.registered_date]

    return (
        <Card maxW='sm' className={`bg-white`}>
            <CardBody>
                <Avatar
                    size={'2xl'}
                    src={data[firestorePaths.doc.user.pic_url]}
                    name={data[firestorePaths.doc.user.first_name]}
                />
                <div className={'mt-6 text-center w-full items-center'}>
                    <Heading size='md'>{`${firstName} ${middleName} ${lastName}`}</Heading>
                    <Tag mt={2} colorScheme={'purple'} className={'text-center'}>
                        {email}
                    </Tag>
                    <div className={'text-lg text-emerald-500 font-bold'}>
                        ₹ {amount}
                    </div>
                    <DataSafeRender values={[registeredDate]}>
                        <div className={'font-semibold mt-4 w-full p-2 text-center'}>
                            Reg on: {format(new Date(registeredDate), "dd/MM/yyyy")}
                        </div>
                    </DataSafeRender>
                </div>
            </CardBody>
            <Divider />
        </Card>
    )
}
