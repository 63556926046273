import {Center, Divider, HStack, Image, Tag, VStack} from "@chakra-ui/react";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import DepositState from "../../utils/DepositState";
import {PaymentStatusChip} from "../status/PaymentStatusChip";
import {PlanStatusChip} from "../status/PlanStatusChip";

export function DepositDetailCard(props){
    const data = props.data
    return(
        <div className={'w-full h-full pb-4'}>
            <div className={'grid grid-cols-1 gap-2'}>
                <div className={'text-3xl font-semibold mb-8'}>Deposit Details</div>
                <div className={'text-left w-full'}>
                    <HStack className={'mb-2'}>
                        <div className={'text-lg font-semibold'}>Date: </div>
                        <div className={'text-lg font-semibold'}>
                            {data[firestorePaths.doc.deposit.date].toDate().toLocaleDateString('en-US')}
                        </div>
                    </HStack>
                    <HStack className={'mb-2'}>
                        <div className={'text-lg font-semibold'}>Owner: </div>
                        <div className={'text-lg font-semibold'}>
                            {data[firestorePaths.doc.deposit.owner]}
                        </div>
                    </HStack>
                    <HStack>
                        <div className={'text-lg font-semibold'}>Verification: </div>
                        <PaymentStatusChip status={data[firestorePaths.doc.deposit.status]}/>
                    </HStack>
                    <div className={`p-1`}></div>
                   {/* <HStack>
                        <div className={'text-lg font-semibold'}>Status: </div>
                        <PlanStatusChip status={data[firestorePaths.doc.deposit.plan_state]}/>
                    </HStack>*/}
                </div>
                <Tag size={'lg'} mt={4} colorScheme={'purple'}>Transaction ID: #{data[firestorePaths.doc.deposit.transaction_number]}</Tag>
                <Tag size={'lg'} colorScheme={'gray'}>Reference ID: {data[firestorePaths.doc.deposit.id]}</Tag>
                <div className={'p-2'}><Divider /></div>
                <div className={'p-2 mb-4 bg-emerald-500 rounded-lg shadow-emerald-300 shadow-lg text-white w-fit ' +
                    'gap-4 justify-self-center align-middle grid grid-flow-col'}>
                    <span className={'text-xl font-semibold'}>Amount: </span>
                    <span className={'text-2xl font-semibold'}>₹{data[firestorePaths.doc.deposit.amount]}</span>
                </div>
                <div className={'w-full mt-2'}>
                    <div  className={'mb-2 text-xl font-semibold'}> Receipt Image </div>
                    <a href={data[firestorePaths.doc.deposit.receipt_url]} target="_blank" rel="noopener noreferrer">
                        <Image src={data[firestorePaths.doc.deposit.receipt_url]} alt={'receipt_image'}/>
                    </a>
                </div>
            </div>
        </div>
    )
}
