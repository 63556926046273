import {Button, Input, InputGroup, InputRightElement, useToast} from "@chakra-ui/react";
import {useState} from "react";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import {firebaseLoginWithEmail} from "../../firebase/auth/firebaseLoginWithEmail";
import {updateUser} from "../../redux/reducer/authUserReducer";
import {useDispatch} from "react-redux";
import {ErrorToast} from "../../utils/ToastBuilder";
import {useNavigate} from "react-router-dom";
import {mainRoutePaths} from "../../routes/mainRoutePaths";
import {PrimaryButton} from "../../components/buttons/PrimaryButton";
import AnimDivClick from "../../components/animated/AnimDivClick";
import passwordGraphics from "../../raw/img/graphics/password_graphics.png"
import gradientBG from "../../raw/img/vectors/gradient.svg";
import {TopBar} from "../../components/nav/TopBar";

export function LoginPage(props){
    const [emailText, setEmailText] = useState('')
    const [passwordText, setPasswordText] = useState('')
    const [viewPassword, setViewPassword] = useState(false)
    const [isBusy, setIsBusy] = useState(false)

    const toast = useToast()
    const nav = useNavigate()
    const dispatch = useDispatch()

    function isValid(){
        return (emailText !== "" && passwordText !== "")
    }

    const handelLoginEvent = _=>{
        if(!isValid()){
            toast(ErrorToast("Invalid Input", "Some fields are missing. Please enter valid details"))
            return
        }
        setIsBusy(true)
        firebaseLoginWithEmail(emailText.trim(), passwordText)
            .then(userCreds=>{
                dispatch(updateUser(userCreds))
                setIsBusy(false)
                nav(`/`)
            })
            .catch(err=>{
                setIsBusy(false)
                toast(ErrorToast('Can\'t login', err.code))
            })
    }

    return(
        <div className={'w-full h-full'}>
            <TopBar/>
            <div className={'w-full p-4'}>
                <div className={'grid lg:grid-cols-2 gap-4'}>
                    <div className={'test_grainy w-full grid place-content-center'}>
                        <img src={passwordGraphics}
                             width={"500px"}
                             alt={""}
                        />
                    </div>
                    <div className={'lg:w-1/2 z-[2] w-full m-auto text-left'}>
                        <div className={'mb-4 text-2xl font-bold'}>Sign in</div>
                        <div>
                            <div>
                                <Input className={'mt-2'}
                                       placeholder={'Email'}
                                       borderColor={'black'}
                                       _placeholder={{ opacity: 0.7, color: 'black' }}
                                       type={'email'}
                                       value = {emailText}
                                       onChange = {e=>{setEmailText(e.target.value)}}
                                       variant={'outline'}/>
                                <InputGroup className={'mt-4'}>
                                    <Input placeholder={'Password'}
                                           type={viewPassword ? 'text' : 'password'}
                                           borderColor={'black'}
                                           _placeholder={{ opacity: 0.7, color: 'black' }}
                                           value = {passwordText}
                                           onChange = {e=>{setPasswordText(e.target.value)}}
                                           variant={'outline'}/>
                                    <InputRightElement onClick={_=>{setViewPassword(!viewPassword)}}>
                                        {viewPassword ? <AiFillEyeInvisible/> : <AiFillEye/>}
                                    </InputRightElement>
                                </InputGroup>
                            </div>
                            <div className={'grid grid-flow-col gap-4 mt-8 justify-between'}>
                                <PrimaryButton className={'p-4'} isBusy={isBusy} onClick={handelLoginEvent}>Continue</PrimaryButton>
                                <AnimDivClick className={'hover:text-red-500 cursor-pointer p-2 text-right w-fit rounded-lg grid place-content-center font-semibold'} onClick={_=>{
                                    nav(`../${mainRoutePaths.forgotPassword}`)}
                                }>
                                    Forgot Password ?
                                </AnimDivClick>
                            </div>
                            <div className={'mt-12'}>
                                <AnimDivClick className={'font-semibold text-md w-fit border-black rounded-md hover:text-[#40B074] hover:cursor-pointer text-[1.1em]'}
                                     onClick={_=>{nav(`/${mainRoutePaths.registration}`)}}>
                                    Create a new account
                                </AnimDivClick>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'mt-16 text-zinc-600'}>
                    <span>By submitting your contact details, you authorise TradeCops to call or text you for the purpose of account opening even
though you may be registered on DND.</span>
                </div>
            </div>
        </div>
    )
}
