import {useEffect, useState} from "react";
import KYC_Status from "../../utils/KYC_Status";
import {Tag} from "@chakra-ui/react";

export function KYCStatusChip(props){
    const status = props.status
    const className= props.className
    const [kycStatus, setKYCStatus] = useState({color: "yellow", text: "Incomplete"})

    useEffect(_=>{
        switch (status){
            case KYC_Status.INCOMPLETE: setKYCStatus({color: "yellow", text: "Incomplete"}); break;
            case KYC_Status.COMPLETE: setKYCStatus({color: "green", text: "Completed"}); break;
            case KYC_Status.PENDING: setKYCStatus({color: "blue", text: "Pending"}); break;
            case KYC_Status.REJECTED: setKYCStatus({color: "red", text: "Rejected"}); break;
            default: setKYCStatus({color: "red", text: "Unknown"}); break;
        }
    },[status])

    return(
        <div className={`${className}`}>
            <Tag className={'w-fit p-2'} colorScheme={kycStatus.color}>{kycStatus.text}</Tag>
        </div>
    )
}
