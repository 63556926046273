import Credit_Status from "../../utils/Credit_Status";

export function CreditStatusChip({value}){
    let data_state = {bg: "bg-zinc-100", text: "Inactive", textColor: "text-zinc-700" }

    switch (value) {
        case Credit_Status.PAID: data_state = {bg: "bg-green-100", text: "Paid", textColor: "text-green-800" }; break;
        case Credit_Status.UPCOMING: data_state = {bg: "bg-zinc-100", text: "Upcoming", textColor: "text-zinc-700" }; break;
        case Credit_Status.DENIED: data_state= {bg: "bg-red-100", text: "Denied", textColor: "text-red-800" }; break;
        default: break;
    }

    return(
        <div className={`${data_state.textColor} ${data_state.bg} rounded-3xl w-fit h-fit pl-4 pr-4 pt-2 pb-2 font-semibold`}>
            {data_state.text}
        </div>
    )
}
