import {
    Button,
    HStack,
    Input,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UserModel} from "../../../../firebase/models/UserModel";
import {firestorePaths} from "../../../../firebase/database/firestorePaths";
import {ErrorToast} from "../../../../utils/ToastBuilder";
import {GetSalary} from "../../../../firebase/database/get/GetSalary";
import {useNavigate} from "react-router-dom";
import {setUserSalary} from "../../../../redux/reducer/userSalaryReducer";
import {SalaryDetails} from "../../../../components/salary/SalaryDetails";
import {format, getYear} from "date-fns";
import {AiFillCaretLeft, AiFillCaretRight} from "react-icons/ai";
import {SalaryStatusChip} from "../../../../components/salary/SalaryStatusChip";
import {ceil} from "mathjs";

export function Salary (props){
    const authUser = useSelector(state=>{return state.authUser})
    const userData = useSelector(state=>{return state.userData})
    const salaryStore = useSelector(state => {return state.userSalary})
    const [salaryList, setSalaryList] = useState([])
    const [userDetails, setUserDetails] = useState(UserModel())
    const [referralList, setReferralList] = useState(null)
    const [directReferralList, setDirectReferralList] = useState([])
    const [tableList, setTableList] = useState([])
    const [pageList, setPageList] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(50)

    const [selectedItem, setSelectedItem] = useState(null)

    const toast = useToast()
    const nav = useNavigate()
    const dispatch = useDispatch()

    useEffect(_=>{
        if(userDetails !== undefined) {
            if(userDetails[firestorePaths.doc.user.email] !== null){
                if(salaryStore.data.length === 0 && !salaryStore.isLoaded)
                    GetSalary(userDetails[firestorePaths.doc.user.email]).then(list => {
                        dispatch(setUserSalary(list))
                    }).catch(e => {
                        toast(ErrorToast('Error', e.message))
                    })
            }
        }
    },[userDetails])

    useEffect(_=>{
        if(tableList.length > 0){
            let start = currentPage * itemsPerPage
            let end = (currentPage * itemsPerPage) + itemsPerPage
            if(start > tableList.length){
                setCurrentPage(0)
            }
            if(end > tableList.length)
                end = tableList.length
            const list = tableList.slice(start, end)
            setPageList(list)
        } else{
            setPageList([])
        }
    },[tableList, itemsPerPage, currentPage])

    useEffect(_=>{
        if(referralList !== null){
            setDirectReferralList(referralList?.l0)
        }
    },[referralList])

    useEffect(_=>{
        if(userData !== null && userData !== undefined && userData.isLoaded) {
            setUserDetails(userData.data)
            if(userData.referralData !== null && userData.referralData !== undefined)
                setReferralList(userData.referralData)
        }
    },[userData])

    useEffect(_=>{
        if(salaryStore.isLoaded){
            if(salaryStore.data.length > 0){
                setSalaryList(salaryStore.data)
            }
        }
    }, [salaryStore])

    useEffect(_=>{
        setTableList(salaryList)
    },[salaryList])

    return (
        <div className={'w-full h-full text-left'}>
            {
                (selectedItem === null)?
                    <div className={'w-full p-2'}>

                        <div className={'text-2xl font-bold'}>Salary</div>
                        <div className={''}>All salaries will be listed here</div>
                        {/*<div className={'w-full mt-4 grid lg:grid-cols-3 grid-cols-1 md:grid-cols-2 gap-4'}>
                            <div className={'w-full p-2 border-2 rounded-xl'}>
                                <div className={'mb-4 font-semibold'}>General filters</div>
                                <VStack w={'full'}>
                                    <VStack w={'full'}>
                                        <div className={'text-left text-sm font-semibold w-full'}>
                                            Status
                                        </div>
                                        <Select placeholder='' value={statusFilter}
                                                onChange={e=>{setStatusFilter(parseInt(e.target.value))}}>
                                            <option value = {constants.CLEAR_FILTER_CODE}>Status Filter</option>
                                            <option value={Salary_Status.ONGOING}>Ongoing</option>
                                            <option value={Salary_Status.ACTIVE}>Active</option>
                                            <option value={Salary_Status.COMPLETED}>Completed</option>
                                            <option value={Salary_Status.INACTIVE}>Inactive</option>
                                            <option value={Salary_Status.CANCELLED}>Cancelled</option>
                                        </Select>
                                    </VStack>
                                </VStack>
                            </div>
                            <div className={'w-full p-2 border-2 rounded-xl'}>
                                <div className={'mb-4 font-semibold'}>Issue month filters</div>
                                <VStack w={'full'}>
                                    <VStack w={'full'}>
                                        <div className={'text-left text-sm font-semibold w-full'}>
                                            Issue month ( START )
                                        </div>
                                        <Input
                                            value={issueStartFilter}
                                            onChange={e=>{setIssueStartFilter(e.target.value)}}
                                            type={"date"}
                                            placeholder={"Start Date"}/>
                                    </VStack>
                                    <VStack w={'full'}>
                                        <div className={'text-left text-sm font-semibold w-full'}>
                                            Issue month ( END )
                                        </div>
                                        <Input
                                            value={issueEndFilter}
                                            onChange={e=>{setIssueEndFilter(e.target.value)}}
                                            className={'w-full'}
                                            type={"date"}
                                            placeholder={"End Date"}/>
                                    </VStack>
                                </VStack>
                            </div>
                            <div className={'w-full p-2 border-2 rounded-xl'}>
                                <div className={'mb-4 font-semibold'}>Start month filters</div>
                                <VStack w={'full'}>
                                    <VStack w={'full'}>
                                        <div className={'text-left text-sm font-semibold w-full'}>
                                            Start month ( START )
                                        </div>
                                        <Input
                                            value={startStartFilter}
                                            onChange={e=>{setStartStartFilter(e.target.value)}}
                                            type={"date"}
                                            placeholder={"Email"}/>
                                    </VStack>
                                    <VStack w={'full'}>
                                        <div className={'text-left text-sm font-semibold w-full'}>
                                            Start month ( END )
                                        </div>
                                        <Input
                                            value={startEndFilter}
                                            onChange={e=>{setStartEndFilter(e.target.value)}}
                                            type={"date"}
                                            placeholder={"Email"}/>
                                    </VStack>
                                </VStack>
                            </div>
                            <div className={'w-full'}>
                                <Button w={'full'} onClick={_=>{
                                    setIssueStartFilter('')
                                    setIssueEndFilter('')
                                    setStartStartFilter('')
                                    setStartEndFilter('')
                                    setStatusFilter(constants.CLEAR_FILTER_CODE)
                                }}>Clear</Button>
                            </div>
                        </div>*/}
                        <div className={'font-bold mt-8'}>
                            <div className={'grid grid-flow-row gap-4 md:grid-flow-col justify-between'}>
                                <HStack>
                                    <span className={'font-semibold'}>Page: </span>
                                    <span>{currentPage + 1}</span>
                                    <span className={'mr-8'}>of {ceil(tableList.length/itemsPerPage)} </span>
                                    <div className={'hover:bg-slate-100 hover:cursor-pointer p-2 rounded-lg'} onClick={_=>{
                                        if(currentPage > 0)
                                            setCurrentPage(currentPage - 1)
                                    }
                                    }><AiFillCaretLeft/></div>
                                    <div className={'hover:bg-slate-100 hover:cursor-pointer p-2 rounded-lg'} onClick={_=>{
                                        if(currentPage < ceil(tableList.length/itemsPerPage)-1)
                                            setCurrentPage(currentPage + 1)
                                    }}><AiFillCaretRight/></div>
                                </HStack>
                                <HStack>
                                    <span className={'font-semibold'}>Items/page: </span>
                                    <Input value={itemsPerPage}
                                           minWidth={'50px'}
                                           width={'fit-content'}
                                           ml={8}
                                           className={'text-center'}
                                           onChange={e=>{
                                               if(e.target.value !== '')
                                                   setItemsPerPage(parseInt(e.target.value))}}/>
                                </HStack>
                            </div>
                        </div>
                        <div className={'mt-4'}>
                            <TableContainer>
                                <Table size={"sm"}>
                                    <Thead>
                                        <Tr>
                                            <Th>Date</Th>
                                            <Th>Owner</Th>
                                            <Th>Issued for month</Th>
                                            <Th>Start Month</Th>
                                            <Th>Status</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            pageList.map((item, index)=>{
                                                return (
                                                    <Tr key={index} className={''}>
                                                        <Td>{format(new Date(item[firestorePaths.doc.salary.write_date]), "dd/MM/yyyy")}</Td>
                                                        <Td>{item.owner}</Td>
                                                        <Td>{`${format(new Date(item.ref_year, item.ref_month, 1), "LLL")}, ${item.ref_year}`}</Td>
                                                        <Td>{`${format(new Date(item.start_date), "LLL")}, ${getYear(new Date(item.start_date))}`}</Td>
                                                        <Td><SalaryStatusChip className={'text-sm'} value={item[firestorePaths.doc.salary.status]}/></Td>
                                                        <Td><Button  onClick={_=>{setSelectedItem(item)}}>View Details</Button></Td>
                                                    </Tr>
                                                )
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>

                        </div>
                    </div>
                    :
                    <div className={'w-full'}>
                        <SalaryDetails id={selectedItem["id"]} salaryList={salaryList} directReferralList={directReferralList} changeSelection={setSelectedItem}/>
                    </div>
            }
        </div>
    )
}
