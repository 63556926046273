import {logout} from "../../firebase/auth/callbacks";
import {BiLogOut} from "react-icons/bi";
import AnimDivClick from "../animated/AnimDivClick";
import {HStack} from "@chakra-ui/react";

function LogoutButton({className}){
    return(
        <AnimDivClick
            className={`${className} grid place-content-center font-semibold text-md w-fit border-black rounded-md hover:text-red-600`}
            onClick={logout}>
            <HStack>
                <BiLogOut/>
                <div>Logout</div>
            </HStack>
        </AnimDivClick>
    )
}

export default LogoutButton
