import {Box, Button, HStack, useToast, VStack} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import {HiBanknotes, HiOutlineClipboardDocument} from "react-icons/hi2";
import {BiWallet} from "react-icons/bi";
import {ReferralLinkCard} from "../../../components/referral/ReferralLinkCard";
import {ReferralNotAllowedCard} from "../../../components/referral/ReferralNotAllowedCard";
import {RequestReferralAccessCard} from "../../../components/referral/RequestReferralAccessCard";
import {useNavigate} from "react-router-dom";
import {getAvailableBalance} from "../../../helper/getAvailableBalance";
import {getTotalReferralBalance} from "../../../helper/getTotalReferralBalance";
import useAuthUserState from "../../../hooks/state/useAuthUserState";
import useUserDataState from "../../../hooks/state/useUserDataState";
import useDepositsState from "../../../hooks/state/useDepositsState";
import useReferralReturnsState from "../../../hooks/state/useReferralReturnsState";
import useSalaryState from "../../../hooks/state/useSalaryState";
import 'chart.js/auto';
import {format, getMonth, getYear, isSameMonth} from "date-fns";
import AnimDivClick from "../../../components/animated/AnimDivClick";
import {FaPiggyBank, FaWallet} from "react-icons/fa";
import Referral_Returns_Status from "../../../utils/Referral_Returns_Status";
import {round} from "mathjs";
import {HiTrendingUp} from "react-icons/hi";
import {IoInformationOutline} from "react-icons/io5";
import {PrimaryButton} from "../../../components/buttons/PrimaryButton";
import {AnimButtonContainer} from "../../../components/buttons/AnimButtonContainer";
import {dashboardRoutePaths} from "../../../routes/dashboard/dashboardRoutePaths";
import noise from "../../../raw/img/graphics/noise.png";
import Salary_Status from "../../../utils/Salary_Status";
import {GrMoney} from "react-icons/gr";
import {FiCheck} from "react-icons/fi";
import {BsFillCreditCard2BackFill} from "react-icons/bs";

export function Home (props){
    const [authUser, authToken, _] = useAuthUserState()
    const [userDetails, userReferralList, refreshUserReferral, isUserDetailsLoading] = useUserDataState()
    const [userDeposits, refreshDeposits, isDepositsLoading] = useDepositsState()
    const [userReferralReturns, refreshReferralReturns, isReferralReturnsLoading] = useReferralReturnsState()
    const [userSalary, refreshSalary, isSalaryLoading] = useSalaryState()

    const [isRefreshBusy, setIsRefreshBusy] = useState(false)
    const [totalDeposits, setTotalDeposits] = useState(0)
    const [referralCode, setReferralCode] = useState(0)
    const [referralBalance, setReferralBalance] = useState(0)
    const [referralBalanceWithdrawn, setReferralBalanceWithdrawn] = useState(0)
    const [totalReferralBalance, setTotalReferralBalance] = useState(0)
    const [totalReferralEarningThisMonth, setTotalReferralEarningThisMonth] = useState(0)
    const [ongoingSalaryCount, setOngoingSalaryCount] = useState(0)
    const [activeSalaryCount, setActiveSalaryCount] = useState(0)

    const [referralBody, setReferralBody] = useState(<ReferralNotAllowedCard/>)

    const [userDepositsGraphData, setUserDepositsGraphData] = useState({
        labels: [],
        datasets: [{
            label: "Deposits",
            data: []
        }],
        backgroundColor: "rgba(255, 255, 255, 0)",
        borderColor: "rgb(0,0,0)",
        color: "rgb(0,0,0)"
    })

    const dispatch = useDispatch()
    const toast = useToast()
    const nav = useNavigate()

    useEffect(_=>{
        if(userDetails && !isUserDetailsLoading){
            if(userDetails[firestorePaths.doc.user.email]){
                if(userDetails[firestorePaths.doc.user.adminReferralAllowed] === true){
                    setReferralBody(<ReferralLinkCard referralCode={userDetails[firestorePaths.doc.user.referral_code]}/>)
                }else{
                    if(userDetails[firestorePaths.doc.user.amount] >= 250000){
                        setReferralBody(
                            <RequestReferralAccessCard
                                email={userDetails[firestorePaths.doc.user.email]}
                                requestState={userDetails[firestorePaths.doc.user.referralAccessRequested]}/>)
                    }else{
                        setReferralBody(<ReferralNotAllowedCard/>)
                    }
                }
                if(userDetails[firestorePaths.doc.user.referral_code]){
                    setReferralCode(userDetails[firestorePaths.doc.user.referral_code])
                }
                if(userDetails[firestorePaths.doc.user.amount]){
                    setTotalDeposits(userDetails[firestorePaths.doc.user.amount])
                }
            }
        }
    },[isUserDetailsLoading, userDetails])

    function getDepositsByMonth(list){
        const monthlyList = []
        for (let i = 0; i < 12; i++){
            monthlyList[i] = 0
        }
        for(const item of list){
            const itemYear = getYear(new Date(item[firestorePaths.doc.deposit.date]))
            const todayYear = getYear(new Date())
            if (itemYear !== todayYear){
                monthlyList[getMonth(new Date(item[firestorePaths.doc.deposit.date]))] += item[firestorePaths.doc.deposit.amount]
            }
        }
        return monthlyList
    }

    function getReferralEarningForCurrentMonth(list){
        let earning = 0
        for(const item of list){
            if(item[firestorePaths.doc.referral_return.status] !== Referral_Returns_Status.REJECTED){
                const itemDate = new Date(item[firestorePaths.doc.referral_return.date])
                const today = new Date()
                if (isSameMonth(itemDate, today)){
                    earning += item[firestorePaths.doc.referral_return.amount]
                }
            }
        }
        return earning
    }

    function getReferralBalanceWithdrawnThisMonth(list){
        let earning = 0
        for(const item of list){
            if(item[firestorePaths.doc.referral_return.status] !== Referral_Returns_Status.REJECTED){
                const itemDate = new Date(item[firestorePaths.doc.referral_return.date])
                const today = new Date()
                if (isSameMonth(itemDate, today)){
                    if(item[firestorePaths.doc.referral_return.status] === Referral_Returns_Status.WITHDRAWN)
                        earning += item[firestorePaths.doc.referral_return.amount]
                }
            }
        }
        return earning
    }

    useEffect(_=>{
        if(userDeposits && !isDepositsLoading) {

            setUserDepositsGraphData({
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    label: "Deposit",
                    backgroundColor: "rgba(64 176 116, 0.7)",
                    borderColor: "rgba(64 176 116, 1)",
                    borderRadius: 5,
                    borderWidth: 2,
                    data: getDepositsByMonth(userDeposits)
                }],
                backgroundColor: "rgba(255, 255, 255, 0)",
                borderColor: "black"
            })
        }
    },[isDepositsLoading, userDeposits])

    useEffect(_=>{
        if(userReferralReturns !== null && userReferralReturns !== undefined && userReferralReturns.length !== 0 && !isReferralReturnsLoading){
            setReferralBalance(getAvailableBalance(userReferralReturns))
            setTotalReferralBalance(getTotalReferralBalance(userReferralReturns))
            setTotalReferralEarningThisMonth(getReferralEarningForCurrentMonth(userReferralReturns))
            setReferralBalanceWithdrawn(getReferralBalanceWithdrawnThisMonth(userReferralReturns))
        }
    },[isReferralReturnsLoading, userReferralReturns])

    useEffect(_=>{
        if(!isSalaryLoading && userSalary!== null && userSalary !== undefined){
            const ongoingSalary = userSalary.filter(salary=>(salary[firestorePaths.doc.salary.status] === Salary_Status.ONGOING ))
            const activeSalary = userSalary.filter(salary=>(salary[firestorePaths.doc.salary.status] === Salary_Status.ACTIVE ||
                salary[firestorePaths.doc.salary.status] === Salary_Status.ONGOING ))
            setOngoingSalaryCount(ongoingSalary.length)
            setActiveSalaryCount(activeSalary.length)
        }
    },[isSalaryLoading, userSalary])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: true,
                text: 'FY 2023',
            },
        },
        scales: {
            display:false,
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        }
    };

    return(
        <div className={'w-full max-w-full h-fit'}>
           {/* {
                ((userDetails[firestorePaths.doc.user.amount] >= 250000) &&
                    (userDetails[firestorePaths.doc.user.registered_date].toDate() < new Date("2023-03-16")))?
                    <div className={'md:col-span-3 bg-purple-800 h-2/5 shadow-purple-300 hover:bg-purple-900 text-white rounded-lg p-2 mb-4 shadow-lg p-4 pt-8 pb-8 w-full h-full'}>
                        <div className={'grid grid-flow-row text-center align-middle justify-center p-8 place-content-center h-full'}>
                            <div className={'w-full text-2xl mr-4 font-bold'}>1st Award Night </div>
                            <div className={'w-full text-md mr-4 font-semibold font-mono'}>18th and 19th March, 2023</div>
                            <div className={'w-full text-lg mr-4 font-semibold font-mono mt-4'}>You are invited!</div>
                            <Button colorScheme={'pink'} mt={'4'} onClick={_=>{nav(dashboardRoutePaths.event_award_1)}
                            }>View Invitation Card</Button>
                        </div>
                    </div>
                    :<br/>
            }*/}
           {/* <GradientDiv className={'w-full md:h-[35%] h-[55%] md:opacity-100 opacity-0 absolute z-0'}>
                <div className={'w-full h-full bg-gradient-to-t from-white to-transparent via-transparent absolute'}/>
                <div className={'w-full h-full relative md:bg-gradient-to-r bg-gradient-to-b from-white/30 from-1% to-transparent via-transparent to-30% via-90% '}/>
            </GradientDiv>*/}
            <div className={'grid lg:pl-8 lg:pr-8 pl-6 pr-6 w-full'}>
                <div className={'w-full grid lg:grid-cols-3 grid-cols-1 gap-8'}>
                    <AnimDivClick className={'group h-full w-full'} activateClickEffect={false}>
                        <div className={'transition ease-in-out delay-100 border-[2px] border-black rounded-md w-full p-4 h-full group-hover:bg-primary group-hover:border-primary group-hover:text-white'}>
                            <div className={'w-full'}>
                                <HStack className={'w-full'}>
                                    <Box p={4} className={'transition ease-in-out delay-150 bg-black text-white group-hover:bg-white group-hover:text-primary'} rounded={5} fontSize={28}>
                                        <HiBanknotes/>
                                    </Box>
                                    <div className={'w-full text-left text-lg'}>Deposit Amount</div>
                                </HStack>
                                <div className={'w-full text-left text-3xl font-semibold grid pt-12'}>₹ {totalDeposits.toFixed(2)}</div>
                            </div>
                            <div className={'w-full mt-12'}>
                                <AnimButtonContainer className={'w-full place-content-end'}>
                                    <PrimaryButton onClick={_=>{nav(`${dashboardRoutePaths.deposit}`)}}>
                                        Deposit Amount
                                    </PrimaryButton>
                                </AnimButtonContainer>
                            </div>
                        </div>
                    </AnimDivClick>
                    <AnimDivClick className={'group h-full w-full'} activateClickEffect={false}>
                        <div className={'transition ease-in-out delay-100 border-[2px] border-black rounded-md w-full p-4 h-full group-hover:bg-orange-400 group-hover:border-orange-400 group-hover:text-white'}>
                            <div className={'w-full'}>
                                <HStack className={'w-full'}>
                                    <Box p={4} className={'transition ease-in-out delay-150 bg-black text-white group-hover:bg-white group-hover:text-orange-400'} rounded={5} fontSize={28}>
                                        <FaWallet/>
                                    </Box>
                                    <div className={'w-full text-left text-lg'}>Wallet balance</div>
                                </HStack>
                                <div className={'w-full text-left text-3xl font-semibold grid pt-12'}>₹ {referralBalance.toFixed(2)}</div>
                            </div>
                            <div className={'w-full h-fit mt-12 text-[1.2em] text-left flex flex-nowrap gap-2 items-center'}>
                            <span className={'text-sm'}>
                                * Wallet balance is credited automatically every month to your registered bank account.
                            </span>
                            </div>
                        </div>
                    </AnimDivClick>
                    <AnimDivClick className={'group h-full w-full'} activateClickEffect={false}>
                        <div className={'transition ease-in-out delay-100 border-[2px] border-black group-hover:border-blue-600 rounded-md w-full p-4 h-full group-hover:bg-blue-600 group-hover:text-white'}>
                            <div className={'w-full h-full'}>
                                <HStack className={'w-full'}>
                                    <Box p={4} className={'transition ease-in-out delay-150 bg-black text-white group-hover:bg-white group-hover:text-blue-600'} rounded={5} fontSize={28}>
                                        <BsFillCreditCard2BackFill/>
                                    </Box>
                                    <div className={'w-full text-left text-lg'}>Ongoing Salaries</div>
                                </HStack>
                                <div className={'w-full text-left text-3xl font-semibold grid pt-12 pl-6'}>
                                    <HStack>
                                        <span>{ongoingSalaryCount}</span>
                                    <span className={'text-[0.7em] font-normal'}> ongoing</span>
                                    </HStack>
                                </div>
                                <div className={'w-full h-fit mt-12 text-[1.2em] text-left flex flex-nowrap gap-2 items-center'}>
                                    <span className={'text-2xl font-semibold group-hover:text-white'}><FiCheck/></span>

                                    <span className={'text-lg'}>{activeSalaryCount}</span> Active Salaries
                                </div>
                            </div>
                        </div>
                    </AnimDivClick>
                    <AnimDivClick className={'group h-full w-full'} activateClickEffect={false}>
                        <div className={'transition ease-in-out delay-100 border-[2px] border-black rounded-md w-full p-4 h-full group-hover:bg-black group-hover:text-white'}>
                            <div className={'w-full h-full'}>
                                <HStack className={'w-full'}>
                                    <Box p={4} className={'transition ease-in-out delay-150 bg-black text-white group-hover:bg-white group-hover:text-black'} rounded={5} fontSize={28}>
                                        <FaPiggyBank/>
                                    </Box>
                                    <div className={'w-full text-left text-lg'}>Lifetime earning</div>
                                </HStack>
                                <div className={'w-full text-left text-3xl font-semibold grid pt-12'}>₹ {totalReferralBalance}</div>
                                <div className={'w-full h-fit mt-12 text-[1.2em] text-left flex flex-nowrap gap-2 items-center'}>
                                    <span className={'text-2xl font-semibold'}><HiTrendingUp/></span>
                                    <span className={'text-lg'}>₹ {totalReferralEarningThisMonth.toFixed(2)}</span> This month
                                </div>
                            </div>
                        </div>
                    </AnimDivClick>

                    {
                        referralBody
                    }
                </div>
                {/*<DataSafeRender values={[authUser, authToken]} alt={<>Loading...</>}>
                    <Button onClick={_=>{

                    }>
                        Test Aadhaar
                    </Button>
                </DataSafeRender>*/}
            </div>
        </div>
    )
}
