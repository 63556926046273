import Lottie from 'react-lottie';
function LottiePlayer({options, h="100%", w="100%", isStopped=false, isPaused=false}){
    return(
        <Lottie options={options}
                isStopped={isStopped}
                height={h}
                width={w}
                isPaused={isPaused}/>
    )
}

export default LottiePlayer
