import AnimDivClick from "../animated/AnimDivClick";
import {Tilt} from "react-tilt";
import useDefaultTilt from "../animated/hooks";
import {HStack, Spinner} from "@chakra-ui/react";

export function PrimaryButton({className="", disabled, onClick, isBusy, busyText="Please wait", children}){
    const defaultOptions = useDefaultTilt()

    return (
        <Tilt options={defaultOptions}>
            {
                (disabled === true)
                    ?
                    <div className={`${className} rounded-md text-white p-3 pl-8 pr-8 hover:cursor-not-allowed bg-black/40`}>
                        {children}
                    </div>
                    :
                    (isBusy === true)?
                        <div>
                            <AnimDivClick className={`${className} rounded-md bg-black text-white p-3 pl-8 pr-8 hover:cursor-progress hover:bg-black/90`}>
                                <HStack><Spinner size={'sm'}/><div>{busyText}</div></HStack>
                            </AnimDivClick>
                        </div>
                        :
                        <div>
                            <AnimDivClick className={`${className} rounded-md bg-black text-white p-3 pl-8 pr-8 hover:cursor-pointer hover:bg-black/95`}
                                          onClick={onClick}>
                                {children}
                            </AnimDivClick>
                        </div>
            }
        </Tilt>
    )
}
