import {format, getYear} from "date-fns";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {Avatar, Button, CloseButton, HStack, Input, Radio, RadioGroup, Tag, useToast, VStack} from "@chakra-ui/react";
import {CreditBar} from "./CreditBar";
import {SalaryStatusChip} from "./SalaryStatusChip";
import {useEffect, useState} from "react";
import {getFullName} from "../../utils/helper";

export function SalaryDetails({id = null, salaryList = [], directReferralList = [], changeSelection}){
    const [creditLine, setCreditLine] = useState([])
    const [referralList, setReferralList] = useState([])
    const [salary, setSalary] = useState(null)
    const toast = useToast()

    useEffect(_=>{
        if(id!== null && id!==undefined && salaryList!==null && salaryList!==undefined) {
            const data = salaryList.find(it => (it["id"] === id))
            setSalary(data)
        }
    },[id, salaryList])

    useEffect(() => {
        if(salary !== null){
            if(salary[firestorePaths.doc.salary.credit_line.path] !== null){
                setCreditLine(salary[firestorePaths.doc.salary.credit_line.path])
            }
        }
    }, [salary]);

    useEffect(()=>{
        if(salary && directReferralList){
            if(directReferralList.length !== 0){
                const ref = salary[firestorePaths.doc.salary.referral_list]
                const list = []
                for(const item of ref){
                    const user = directReferralList.find(it=>(it[firestorePaths.doc.user.email] === item))
                    if(user) list.push(user)
                }
                setReferralList(list)
            }
        }
    },[salary, directReferralList])

    return(
        <div className={'w-full h-full bg-white text-left'}>
            {
                (salary === null || salary === undefined)
                    ?
                    <div className={'grid place-content-center w-full h-full'}>
                        <div className={'bg-zinc-50 p-2 rounded-lg font-semibold'}>
                            Select a salary to view details
                        </div>
                    </div>
                    :
                    <div className={'p-4'}>
                        <div className={'mb-4'}>
                            <CloseButton onClick={_=>{changeSelection(null)}} size={'lg'}/>
                        </div>
                        <div className={'grid grid-cols-2 gap-2 mb-4'}>
                            <div className={'mb-4 w-full h-full'}>
                                <HStack p={4}>
                                    <div className={'md:text-xl font-semibold'}>Owner: </div>
                                    <div className={'md:text-xl font-semibold p-2 bg-zinc-100 rounded-xl'}>{salary[firestorePaths.doc.salary.owner]}</div>
                                </HStack>
                                <HStack p={4}>
                                    <div className={'md:text-xl font-semibold'}>Status: </div>
                                    <SalaryStatusChip value={salary[firestorePaths.doc.salary.status]}/>
                                </HStack>
                                <HStack>
                                    <div className={'flex flex-col mb-4 w-full p-4 mt-4'}>
                                        <div className={'font-semibold border-b-2 p-1 w-fit pr-6'}>Write Date</div>
                                        <div className={'font-semibold md:text-xl'}>
                                            {
                                                `${format(new Date(salary[firestorePaths.doc.salary.write_date]), "dd/MM/yyyy")}`
                                            }
                                        </div>
                                    </div>
                                    <div className={'flex flex-col mb-4 w-full p-4'}>
                                        <div className={'font-semibold border-b-2 p-1 w-fit pr-6'}>Issue Month</div>
                                        <div className={'font-semibold md:text-xl'}>
                                            {
                                                `${format(new Date(salary[firestorePaths.doc.salary.ref_year],salary[firestorePaths.doc.salary.ref_month], 1), "LLL")},
                     ${getYear(new Date(salary[firestorePaths.doc.salary.ref_year], salary[firestorePaths.doc.salary.ref_month], 1))}`
                                            }
                                        </div>
                                    </div>
                                </HStack>
                                <HStack>
                                    <div className={'flex flex-col mb-4 w-full p-4 mt-4'}>
                                        <div className={'font-semibold border-b-2 p-1 w-fit pr-6'}>Start Month</div>
                                        <div className={'font-semibold md:text-xl'}>
                                            {
                                                (salary[firestorePaths.doc.salary.start_date] === null)? "N/A":
                                                    `${format(new Date(salary[firestorePaths.doc.salary.start_date]), "dd MMM, yyyy")}`
                                            }
                                        </div>
                                    </div>
                                    <div className={'flex flex-col  mb-4 w-full p-4'}>
                                        <div className={'font-semibold border-b-2 p-1 w-fit pr-6'}>End Month</div>
                                        <div className={'font-semibold md:text-xl'}>
                                            {
                                                (salary[firestorePaths.doc.salary.end_date] === null)? "N/A":
                                                    `${format(new Date(salary[firestorePaths.doc.salary.end_date]), "dd MMM, yyyy")}`
                                            }
                                        </div>
                                    </div>
                                </HStack>
                            </div>
                        </div>
                        <div className={'p-4 w-full'}>
                            <div className={'font-semibold w-full md:text-2xl text-lg mb-4'}>Referral Members</div>
                            <div className={'grid gap-4 md:grid-cols-3 grid-cols-1 w-full'}>
                                {
                                    (referralList.length === 0)?
                                        <div className={'bg-red-100 text-red-700 p-2 rounded-xl md:text-lg'}>
                                            No referral candidates to show. This is weird and should not happen. Try refreshing the website and if this still exists, contact support!
                                        </div>:
                                    referralList.map((user, index)=>{
                                        return(
                                            <Tag p={4} colorScheme={'orange'} key={index} size={'lg'} borderRadius={'full'}>
                                                <HStack>
                                                    <Avatar
                                                        src={user[firestorePaths.doc.user.pic_url]}
                                                        size='lg'
                                                        name={getFullName(user)}/>
                                                    <div>
                                                        <div className={'text-left'}>{getFullName(user)}</div>
                                                        <div className={'text-left text-sm pt-1'}>{user[firestorePaths.doc.user.email]}</div>
                                                        <div className={'pt-2 text-left'}>Amount: ₹{user[firestorePaths.doc.user.amount]}</div>
                                                    </div>
                                                </HStack>
                                            </Tag>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={'w-full h-full grid gap-4 grid-cols-1 mt-8'}>
                            <div className={'ml-4'}>
                                <div className={'font-semibold md:text-2xl text-lg'}>
                                   Credit Line
                                </div>
                            </div>
                            {
                                creditLine.map((data, index)=>{
                                    return(
                                        <CreditBar creditData={data} key={index}/>
                                    )
                                })
                            }
                        </div>
                    </div>
            }
        </div>
    )
}
