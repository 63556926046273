import { motion } from "framer-motion"
import {useEffect, useLayoutEffect, useRef} from "react";
function AnimDivClick({className, style={}, onClick=()=>{}, activateClickEffect = true, children}){
    return(
        <motion.div
            style={style}
            className={`${className} hover:cursor-pointer`}
            onClick={onClick}
            initial={{ scale: 1 }}
            whileTap={{ scale: (activateClickEffect)?0.9:1.1}}
            transition={{duration: 0.1}}
            whileHover={{ scale: 1.1 }}>
            {children}
        </motion.div>
    )
}

export default AnimDivClick
