import {Badge, Card, CardBody, CardFooter, CardHeader, Tag} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {CheckCircleIcon} from "@chakra-ui/icons";
import {kycRoutePaths} from "../../routes/kyc/kycRoutePaths";

export function KYCOptionCard(props){
    const title = props.title
    const redirect = props.redirect
    const value = props.value
    const attention = props.attention
    const nav = useNavigate()
    const isObjValue = (typeof(value) === 'object')

    function showDone(value, isObjValue){
        if(value === null || value === undefined) return false
        let result = true
        if(!isObjValue){
            result = true
        } else{
            Object.keys(value).forEach(function(key,_) {
                if(value[key] === null || value[key] === undefined)
                    result = false
            })
        }
        return result
    }

    const handelOnClick = _=>{
         nav(redirect)
    }

    return(
        <Card background={'white'} className={
            `w-full bg:white hover:bg-emerald-500 hover:text-white cursor-pointer`}
              as={'div'} onClick={handelOnClick}>
            <CardHeader>
                <div className={'text-md grid grid-flow-col justify-between hover:text-white'}>
                    <div className={'text-left text-lg'}>{title}</div>
                    {
                        (showDone(value, isObjValue))?
                            <CheckCircleIcon className={'hover: text-white text-emerald-500'} w={5} h={5}/> : <div/>
                    }
                </div>
                {
                    (attention !== null && attention !== undefined)?
                        <div className={'text-left pt-2'}>
                            <Tag p={2} size={'md'} colorScheme={'orange'}>{attention}</Tag>
                        </div> : <d/>
                }
            </CardHeader>
        </Card>
    )
}
