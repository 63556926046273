import ProgressContext from "../progressContext";
import {useState} from "react";

function ProgressProvider({children}){
    const [progress, setProgressValue] = useState(0)

    function clamp(value, min, max){
        if(min > max) Error("min should be smaller than max")
        if(value < min) value = min
        if(value > max) value = max
        return value
    }

    function incrementProgress(value){
        let newVal = clamp((progress + value), 0, 100)
        setProgressValue(newVal)
    }

    function decrementProgress(value){
        let newVal = clamp(progress - value, 0 , 100)
        setProgressValue(newVal)
    }

    function setProgress(value){
        let newVal = clamp(value, 0 , 100)
        setProgressValue(newVal)
    }

    return(
        <ProgressContext.Provider value={{
            progress,
            incrementProgress,
            decrementProgress,
            setProgress}}>
            {children}
        </ProgressContext.Provider>
    )
}

export default ProgressProvider
