import {useEffect, useState} from "react";

/**
 *
 * @param values
 * @param alt
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function DataSafeRender({values = [], alt= <></>, children}){
    const [show, setShow] = useState(false)

    useEffect(_=>{
        let len = 0
        for(const value of values){
            if(value === null || value === undefined) len += 1
        }
        if(len > 0) setShow(false)
        else setShow(true)
    },[values])

    return(
        <>
            {
                (show)? children : alt
            }
        </>
    )
}
