import {configureStore} from "@reduxjs/toolkit";
import authUserReducer from "./reducer/authUserReducer";
import userDataReducer from "./reducer/userDataReducer";
import userDepositsReducer from "./reducer/userDepositsReducer";
import userReferralReturnsReducer from "./reducer/userReferralReturnsReducer";
import salaryListReducer from "./reducer/userSalaryReducer";


export const dataStore = configureStore({
    reducer:{
        authUser: authUserReducer,
        userData: userDataReducer,
        userDeposits: userDepositsReducer,
        userSalary: salaryListReducer,
        userReferralReturns: userReferralReturnsReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export default dataStore
