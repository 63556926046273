import {Button} from "@chakra-ui/react";
import {HiOutlineClipboardDocument} from "react-icons/hi2";
import scribble from "../../raw/img/vectors/circle_connect_scribble.svg";

export function ReferralLinkCard(props){
    const referralCode = props.referralCode
    return(
        <div className={`group rounded-lg w-full h-full lg:col-span-2 col-span-1 bg-black text-white`}
             style={{backgroundImage: `url(${scribble})`,
                 backgroundRepeat: "no-repeat",
                 backgroundPosition: "110% 110%",
                 backgroundSize: "50% 100%"}}>
            <div className={'group-hover:backdrop-blur-lg align-middle h-full w-full p-6 rounded-lg transition ease-in-out delay-100'}>
                <div className={'text-left text-3xl'}>Referral Link</div>
                <div className={'mt-4 text-left text-[1.2em]'}>
                    <p>
                        <div>Refer your connects and earn exciting bonus when they invest.</div>
                        <div className={'mt-2'}>&mdash; Copy referral link and send it to your connection for registration.</div>
                        <div className={'mt-2'}>
                            &mdash; Alternatively, you can copy the referral code i.e.
                            the last alpha-numeric portion of the URL
                        </div>
                    </p>
                </div>
                <div className={'mt-8 border-2 pl-2 border-white/30 border-dashed rounded-lg h-fit w-fit'}>
                    <div className={'lg:text-md text-left text-lg text-background p-2 w-full'}>
                        <a href={`https://trade-cops-india.web.app/registration/${referralCode}`}>
                            {`https://trade-cops-india.web.app/registration/${referralCode}`}
                        </a>
                    </div>
                </div>
                <div className={'justify-self-end w-full pt-4 pb-16'}>
                    <Button
                        bgColor={'white'}
                        textColor={'black'}
                        _active={{
                            bgColor:"#357005",
                            textColor: "white"
                        }}
                        _hover={{
                            bgColor: "#4da405",
                            textColor: "white"
                        }}
                        p={6}
                        onClick={async _ => {
                            await navigator.clipboard.writeText(`https://trade-cops-india.web.app/registration/${referralCode}`)
                        }}
                        leftIcon={<HiOutlineClipboardDocument/>}>
                        Copy to clipboard
                    </Button>
                </div>
            </div>
        </div>
    )
}
