import Salary_Status from "../../utils/Salary_Status";

export function SalaryStatusChip({value, className=""}){
    let data_state = {bg: "bg-zinc-100", text: "Inactive", textColor: "text-zinc-700" }

    switch (value) {
        case Salary_Status.ACTIVE: data_state = {bg: "bg-blue-100", text: "Active", textColor: "text-blue-800" }; break;
        case Salary_Status.ONGOING: data_state = {bg: "bg-emerald-100", text: "Ongoing", textColor: "text-emerald-800" }; break;
        case Salary_Status.COMPLETED: data_state = {bg: "bg-zinc-100", text: "Completed", textColor: "text-zinc-800" }; break;
        case Salary_Status.INACTIVE: data_state = {bg: "bg-yellow-100", text: "Inactive", textColor: "text-yellow-800" }; break;
        case Salary_Status.CANCELLED: data_state= {bg: "bg-red-100", text: "Canceled", textColor: "text-red-800" }; break;
        default: break;
    }

    return(
        <div className={`${className} ${data_state.textColor} ${data_state.bg} rounded-3xl w-fit h-fit pl-4 pr-4 pt-2 pb-2 font-semibold`}>
            {data_state.text}
        </div>
    )
}
