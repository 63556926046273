import useProgress from "../../../components/progressBar/hooks/useProgress";
import useAuthUserState from "../../../hooks/state/useAuthUserState";
import useUserDataState from "../../../hooks/state/useUserDataState";
import {useEffect, useState} from "react";
import {ConditionalRender} from "../../../components/dataSafe/ConditionalRender";
import {Avatar, Checkbox, HStack, Input, Textarea, useToast, VStack} from "@chakra-ui/react";
import {AiOutlineUser} from "react-icons/ai";
import ProfileImageSelector from "../../../components/collector/ProfileImageUploader";
import wrongPic from "../../../raw/img/vectors/pic_wrong_1.svg"
import wrongPic1 from "../../../raw/img/vectors/pic_wrong_2.svg"
import wrongPic2 from "../../../raw/img/vectors/pic_wrong_3.svg"
import wrongPic3 from "../../../raw/img/vectors/pic_wrong_4.svg"
import personalInfoGraphics from "../../../raw/img/vectors/personal_info_graphics.svg"
import FadingDivider from "../../../components/divider";
import {PrimaryButton} from "../../../components/buttons/PrimaryButton";
import {BiShield} from "react-icons/bi";
import {AnimButtonContainer} from "../../../components/buttons/AnimButtonContainer";
import gradientBG from "../../../raw/img/vectors/gradient.svg";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import {IMAGE_DEFAULT} from "../../../components/collector/ImageUploader";
import TiltDiv from "../../../components/animated/TiltDiv";
import useDefaultTilt from "../../../components/animated/hooks";
import {fuzzyNameMatching, getFullNameFromParts} from "../../../utils/helper";
import {ErrorToast, InfoToast, SuccessToast} from "../../../utils/ToastBuilder";
import {ConfirmDetails} from "../../../firebase/functions/ConfirmDetails";
import {uploadKYC} from "../../../firebase/storage/uploadKYC";
import KYCCardID from "../../../utils/KYCCardID";
import {useNavigate} from "react-router-dom";
import {Loading} from "../../../components/loading/Loding";

function FillDetailsScreen(){
    const [progress,_,__,setProgress] = useProgress()
    const [authUser, authToken, ___] = useAuthUserState()
    const [userData, referralData, refreshReferralData, loading] = useUserDataState()

    const toast = useToast()
    const nav = useNavigate()
    const defaultTilt = useDefaultTilt()

    const [profilePicture, setProfilePicture] = useState(IMAGE_DEFAULT)
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [dob, setDOB] = useState(null)
    const [address, setAddress] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [confirmedDetails, setConfirmedDetails] = useState(false)

    const [isProfileComplete, setIsProfileComplete] = useState(false)
    const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true)
    const [isBusy, setIsBusy] = useState(false)

    const handleSubmit = ()=>{
        setIsBusy(true)
        if(userData){
            const eAadhaarData =  userData[firestorePaths.doc.user.e_aadhaar_data.path]
            if(eAadhaarData){
                const fullName = getFullNameFromParts(firstName, middleName, lastName)
                const matchScore = fuzzyNameMatching(fullName, eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.name])
                console.log("scode", matchScore)
                const pinCode = eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.pin_code]
                if(matchScore >= 0.8 && pinCode === zipCode){
                    if(authToken && authToken !== ""){
                        toast(InfoToast("Uploading", "uploading profile picture"))
                        uploadKYC(
                            KYCCardID.pic,
                            userData[firestorePaths.doc.user.email],
                            profilePicture.raw)
                            .then(picLink=>{
                                toast(SuccessToast("Successful", "upload complete"))
                                toast(InfoToast("Validating details", "Uploading and checking KYC details"))
                                ConfirmDetails(
                                    authToken,
                                    firstName.trim(),
                                    middleName.trim(),
                                    lastName.trim(),
                                    dob,
                                    phone.trim(),
                                    zipCode.trim(),
                                    address.trim(),
                                    picLink)
                                    .then(_=>{
                                        toast(SuccessToast("Successful", "KYC complete and the profile is updated"))
                                        setIsBusy(false)
                                        nav("/")
                                    })
                                    .catch(e=>{
                                        console.log(e)
                                        toast(ErrorToast("Failed to verify user data and update records", e))
                                        setIsBusy(false)})
                            })
                            .catch(e=>{
                                console.log(e)
                                toast(ErrorToast("Failed to upload profile picture", e.message))
                                setIsBusy(false)
                        })
                    }else{setIsBusy(false)}
                }else{
                    toast(ErrorToast("Failed", "Details are not matching with your Aadhaar Card. Please check again."))
                    setIsBusy(false)
                }
            }else{setIsBusy(false)}
        }else{setIsBusy(false)}
    }

    useEffect(_=>{
        setProgress((100 / 4) * 4)
        return _=>{
            setProgress(0)
        }
    },[])

    useEffect(_=>{
        if(userData !== null && userData !==undefined){
            const eAadhaarData = userData[firestorePaths.doc.user.e_aadhaar_data.path]
            if(eAadhaarData){
                setFirstName(userData[firestorePaths.doc.user.first_name])
                setMiddleName(userData[firestorePaths.doc.user.middle_name])
                setLastName(userData[firestorePaths.doc.user.last_name])
                setDOB(userData[firestorePaths.doc.user.dob])
                setPhone(userData[firestorePaths.doc.user.phone])
                if(userData[firestorePaths.doc.user.zip_code] !== null &&
                    userData[firestorePaths.doc.user.zip_code] !== undefined &&
                    userData[firestorePaths.doc.user.zip_code] !== "")
                    setZipCode(userData[firestorePaths.doc.user.zip_code])
                else setZipCode(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.pin_code])
                setAddress(eAadhaarData[firestorePaths.doc.user.e_aadhaar_data.address])
            }
        }
    },[userData])

    useEffect(_=>{
        setIsVerifyButtonDisabled(
            !(
                    profilePicture.raw !== null &&
                    profilePicture.raw !== IMAGE_DEFAULT.raw &&
                    firstName !== null &&
                    firstName !== "" &&
                    dob !== null &&
                    dob !== "" &&
                    phone !== null &&
                    phone !== "" &&
                    zipCode !== null &&
                    zipCode !== "" &&
                    address !== null &&
                    address !== "" &&
                    confirmedDetails
            )
        )
    },[profilePicture, firstName, middleName, lastName, dob, phone, zipCode, address, confirmedDetails])

    return(
        <div className={'w-full h-full p-4 max-w-full overflow-x-hidden'}>
            <div className={'w-full text-left flex flex-col-reverse lg:flex-row gap-4'}>
                <div className={'w-full h-full lg:ml-24 lg:mt-0 mt-8'}>
                    <div className={'text-black/60 text-[1.2em] mb-8'}>Step 4 of 4</div>
                    <div className={'text-2xl font-semibold mb-4'}>Complete Profile</div>
                    <p className={'lg:mr-12 leading-7'}>
                        Share your address-related information from DigiLocker account for KYC.<br/>
                        Your Aadhaar number/copy will be stored for future reference.
                        The Aadhaar UID should belong to you, it is a 12 digit number available at the bottom of your Aadhaar card.
                    </p>
                    <ConditionalRender
                        condition={(authToken && userData)}
                        alt={
                        <div>
                            <Loading/>
                        </div>
                        }>
                        <div className={"mt-8"}>
                            <div className={'mt-12'}>
                                <div className={'w-full font-semibold text-lg mb-4'}>Upload your picture <span className={'text-red-700'}>*</span></div>
                                <div className={'grid grid-cols-1 lg:grid-cols-2 gap-4'}>
                                    <div className={''}>
                                        <div>
                                            Please choose a profile picture that <strong> clearly shows you face</strong>.
                                            Makes sure the image is <strong>will lit</strong>,
                                            <strong>without any facial accessories like sunglasses, cap or mask</strong>.
                                        </div>
                                        <FadingDivider className={'mt-6 mb-6'}/>
                                        <div className={'mt-6 grid grid-cols-4 gap-2'}>
                                            <img width={100} src={wrongPic} alt={"wrong_pic_1"}/>
                                            <img width={100} src={wrongPic1}  alt={"wrong_pic_2"}/>
                                            <img width={100} src={wrongPic2}  alt={"wrong_pic_3"}/>
                                            <img width={100} src={wrongPic3} alt={"wrong_pic_4"}/>
                                        </div>
                                        <FadingDivider className={'mt-6 mb-6'}/>
                                    </div>
                                    <div className={'grid place-content-center'}>
                                        <ProfileImageSelector imageValueChanger={setProfilePicture}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'mt-12'}>
                                <div className={'w-full font-semibold text-lg mb-4'}>Confirm profile details <span className={'text-red-700'}>*</span></div>
                                <div>
                                    Fill in your basic details and <strong>make sure the details match with that on your Aadhaar card</strong>.
                                    Make sure you <strong>cross verify before submitting</strong> as any <strong>mistakes here can lead to denial of full profile access</strong>.
                                    <br/><br/>
                                    The fields <strong>not marked</strong> with (<span className={'text-red-700'}>*</span>) are optional and <strong>must be left blank if not applicable</strong>.
                                </div>
                                <div className={'mt-8'}>
                                    <div className={'grid grid-cols-1 lg:grid-cols-3 gap-4'}>
                                        <div>
                                            <div>First name <span className={'text-red-700'}>*</span></div>
                                            <Input
                                                className={'text-lg'}
                                                borderColor={'black'}
                                                _placeholder={{ opacity: 0.7, color: 'black' }}
                                                varient={"outline"}
                                                value={firstName}
                                                type={"text"}
                                                mt={2}
                                                onChange={e=>{setFirstName(e.target.value)}}
                                                placeholder={"First name"}/>
                                        </div>
                                        <div>
                                            <div>Middle name</div>
                                            <Input
                                                className={'text-lg'}
                                                borderColor={'black'}
                                                _placeholder={{ opacity: 0.7, color: 'black' }}
                                                varient={"outline"}
                                                value={middleName}
                                                type={"text"}
                                                mt={2}
                                                onChange={e=>{setMiddleName(e.target.value)}}
                                                placeholder={"Middle name"}/>
                                        </div>
                                        <div>
                                            <div>Last name</div>
                                            <Input
                                                className={'text-lg'}
                                                borderColor={'black'}
                                                _placeholder={{ opacity: 0.7, color: 'black' }}
                                                varient={"outline"}
                                                value={lastName}
                                                type={"text"}
                                                mt={2}
                                                onChange={e=>{setLastName(e.target.value)}}
                                                placeholder={"Last name"}/>
                                        </div>
                                    </div>
                                    <div className={'mt-8 grid grid-cols-1 lg:grid-cols-2 gap-4'}>
                                        <div>
                                            <div>Date of birth <span className={'text-red-700'}>*</span></div>
                                            <Input
                                                className={'text-lg text-left'}
                                                borderColor={'black'}
                                                _placeholder={{ opacity: 0.7, color: 'black' }}
                                                varient={"outline"}
                                                value={dob}
                                                type={"date"}
                                                mt={2}
                                                onChange={e=>{setDOB(e.target.value)}}
                                                placeholder={"Date of birth"}/>
                                        </div>
                                        <div>
                                            <div>Phone number <span className={'text-red-700'}>*</span></div>
                                            <Input
                                                className={'text-lg'}
                                                borderColor={'black'}
                                                _placeholder={{ opacity: 0.7, color: 'black' }}
                                                varient={"outline"}
                                                value={phone}
                                                type={"phone"}
                                                mt={2}
                                                onChange={e=>{setPhone(e.target.value)}}
                                                placeholder={"Phone number"}/>
                                        </div>
                                    </div>
                                    <div className={'mt-8 grid grid-cols-1 lg:grid-cols-2 gap-4'}>
                                        <div>
                                            <div>Zip Code <span className={'text-red-700'}>*</span></div>
                                            <Input
                                                className={'text-lg'}
                                                borderColor={'black'}
                                                _placeholder={{ opacity: 0.7, color: 'black' }}
                                                varient={"outline"}
                                                value={zipCode}
                                                type={"number"}
                                                mt={2}
                                                onChange={e=>{setZipCode(e.target.value)}}
                                                placeholder={"Zip/Pin/Postal code"}/>
                                        </div>
                                        <div>
                                            <div>Address <span className={'text-red-700'}>*</span></div>
                                            <Textarea
                                                className={'text-lg'}
                                                borderColor={'black'}
                                                _placeholder={{ opacity: 0.7, color: 'black' }}
                                                varient={"outline"}
                                                value={address}
                                                type={"text"}
                                                mt={2}
                                                onChange={e=>{setAddress(e.target.value)}}
                                                placeholder={"Address"}/>
                                        </div>
                                    </div>
                                    <div className={'mt-8 p-4 rounded-xl'}>
                                        <div className={'flex gap-4'}>
                                            <Checkbox size={'lg'}
                                                      colorScheme={'green'}
                                                      borderColor={'black'}
                                                      iconColor={'white'}
                                                      onChange={(e) =>  setConfirmedDetails(e.target.checked)}
                                            ></Checkbox>
                                            <span className={'text-[1em] text-left'}>
                                                I confirm that I have <strong>read and verified all the details provided above and they match the details on my Aadhaar card</strong>.
                                                I understand that if any mistakes are found, my KYC application may be rejected.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <AnimButtonContainer className={'mb-24 mt-8 w-full place-content-start'}>
                                    <PrimaryButton
                                        className={'py-[16px] px-[32px] shadow-xl'}
                                        isBusy={isBusy}
                                        disabled={isVerifyButtonDisabled}
                                        onClick={handleSubmit}>
                                        <HStack>
                                            <span className={'text-lg'}><BiShield/></span>
                                            <div>Complete profile</div>
                                        </HStack>
                                    </PrimaryButton>
                                </AnimButtonContainer>
                            </div>
                        </div>
                    </ConditionalRender>
                </div>
                <TiltDiv options={defaultTilt} className={'lg:max-w-1/2 lg:w-1/2 w-full max-w-full overflow-x-hidden p-8 h-fit'}
                     style={{backgroundImage: `url(${gradientBG})`,
                         backgroundSize: "contain",
                         backgroundRepeat: "no-repeat",
                         backgroundPosition:"center"}}>
                    <img className={'w-full opacity-80'} src={personalInfoGraphics} alt={'personal_information_graphics'}/>
                </TiltDiv>
            </div>
        </div>
    )
}

export default FillDetailsScreen
