export const kycRoutePaths = {
    address: "address",
    address_proof: "address_proof",
    pan: "pan",
    bank_details: "bank_details",
    check_book: "check-book",
    id_proof: "id-proof",
    picture: "picture",
    signature: "signature",
}
