import {useContext, useEffect, useRef} from "react";
import ProgressContext from "./progressContext";
import {isNullSafe} from "../../utils/helper";
import gsap from "gsap"
function ProgressBar({}){
    const {progress, _, __, ___} = useContext(ProgressContext)
    const ref = useRef()

    useEffect(_=>{
        gsap.to(ref.current,{width: `${progress}%`, duration:1, ease: "expo.inOut"})
    },[progress])

    return(
        <div ref={ref} className={`h-[3px] bg-primary`} style={{width: `0`}}>
        </div>
        )
}

export default ProgressBar
