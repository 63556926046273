import {Spinner} from "@chakra-ui/react";


export function Fetching(props){
    const className = props.className
    return(
        <div className={`${className}`}>
            <div className={'mb-4'}>Fetching data</div>
            <Spinner/>
        </div>
    )
}
