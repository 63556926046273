import {collection, doc, getFirestore, updateDoc} from "firebase/firestore";
import {firestorePaths} from "../firestorePaths";
import Referral_Returns_Status from "../../../utils/Referral_Returns_Status";

export function sendWithDrawlRequest(id){
    return new Promise((resolve, reject)=>{
        const database = getFirestore()
        if(id !== null && id !== undefined && id !== ""){
            const docRef = doc(database, firestorePaths.collections.referral_returns, id)
            updateDoc(docRef, {"status": Referral_Returns_Status.PENDING_WITHDRAWAL})
                .then(_=>resolve(0))
                .catch(e=>reject(e))
        }else reject(Error("Invalid referral return id"))
    })
}
