import {Button, HStack, useToast} from "@chakra-ui/react";
import {logout, sendEmailVerificationLink} from "../../firebase/auth/callbacks";
import {ErrorToast, SuccessToast} from "../../utils/ToastBuilder";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import emailGraphics from "../../raw/img/vectors/email_conf.svg";
import {PrimaryButton} from "../../components/buttons/PrimaryButton";
import {AnimButtonContainer} from "../../components/buttons/AnimButtonContainer";
import AnimDivClick from "../../components/animated/AnimDivClick";
import {BiLogOut} from "react-icons/bi";
import gradientBG from "../../raw/img/vectors/gradient.svg";
import LogoutButton from "../../components/buttons/LogoutButton";
import {TopBar} from "../../components/nav/TopBar";

export function VerifyEmail(props){
    const className = props.className

    const nav = useNavigate()

    const [isBusy, setIsBusy] = useState(false)
    const [helperText, setHelperText] = useState("Please verify your email to start using our services.")
    const [isLinkSent, setIsLinkSent] = useState(false)
    const [sendBtnText, setSendButtonText] = useState('Send verification link')

    const toast = useToast()

    const sendVerificationLinkCallback = _=>{
        setIsBusy(true)
        sendEmailVerificationLink().then(email=>{
            setIsLinkSent(true)
            setSendButtonText('Send link again')
            setHelperText("Verification link has been sent. Check the spam folder in case you cant find it in the inbox." +
                " Please login again after verification for an updated status.")
            setIsBusy(false)
            toast(SuccessToast("Verification link sent",
                `Email verification link sent on ${email}`))
        }).catch(err=>{
            setIsBusy(false)
            toast(ErrorToast("Failed to send link", err.message))
        })
    }
    return(
        <>
            <TopBar/>
            <div className={`${className} min-w-screen min-h-screen p-auto grid lg:grid-cols-2 grid-cols-1`}>
                <div className={'test_grainy w-full h-fit grid place-content-start content-center justify-center items-center'}
                     style={{backgroundImage: `url(${gradientBG})`,
                         backgroundSize: "contain",
                         backgroundRepeat: "no-repeat",
                         backgroundPosition:"center"}}>
                    <img src={emailGraphics}
                         width={"700px"}
                         alt={""}
                    />
                </div>
                <div className={'min-h-screen p-4 w-full text-left'}
                     style={{backgroundImage: `url(${gradientBG})`,
                         backgroundSize: "contain",
                         backgroundRepeat: "no-repeat",
                         backgroundPosition:"center"}}>
                    <div className={'text-2xl'}>Verify Email</div>
                    <div className={'m-auto mt-4 text-md'}>{helperText}</div>
                    <AnimButtonContainer className={'w-fit mt-8'}>
                        <PrimaryButton isBusy={isBusy} busyText={"Sending"}
                                       onClick={sendVerificationLinkCallback}
                                       className={'mt-8 w-fit'}>{sendBtnText}</PrimaryButton>
                    </AnimButtonContainer>
                    <AnimButtonContainer className={'mt-4 w-fit'}>
                        {
                            (isLinkSent)?
                                <>
                                    <div className={'mt-12 mb-4'}>
                                        If you have completed the verification process then please click the button below and login to gain access to your account.
                                    </div>
                                    <AnimDivClick className={'mt-4 hover:text-primary w-fit font-semibold text-[1.2em] border-b-2 border-black hover:border-primary pb-2'} onClick={_=>{
                                        toast(SuccessToast('Login again', 'Please login again to update your status'))
                                        logout()
                                            .then(_=>{toast(SuccessToast('Signed out',
                                                'Sign in again to verify your account.'))})
                                            .catch(e=>{toast(SuccessToast('Failed sign out',
                                                e.message))})
                                    }}>
                                        I have verified my account
                                    </AnimDivClick>
                                </> : null
                        }
                    </AnimButtonContainer>
                </div>
            </div>
        </>
    )
}
