export function SuccessToast(title, msg){
    return({
        title: title,
        description: msg,
        status: 'success',
        duration: 10000,
        isClosable: true
    })
}

export function ErrorToast(title, msg){
    return({
        title: title,
        description: msg,
        status: 'error',
        duration: 10000,
        isClosable: true
    })
}

export function WarningToast(title, msg){
    return({
        title: title,
        description: msg,
        status: 'warning',
        duration: 10000,
        isClosable: true
    })
}

export function InfoToast(title, msg) {
    return ({
        title: title,
        description: msg,
        status: 'info',
        duration: 10000,
        isClosable: true
    })
}
