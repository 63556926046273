import {differenceInDays} from "date-fns";
import {firestorePaths} from "../../firebase/database/firestorePaths";

export function DaysToGo({dateISO}){
        const days = differenceInDays(new Date(dateISO), new Date())
        let textColor = "bg-emerald-100 text-emerald-800"
        if(days < 0){
            textColor = "text-red-600 bg-red-100"
        }
        if(days < 7 && days > 0){
            textColor = "text-orange-500 bg-orange-100"
        }
        return(
            <div>
                <div className={`${textColor} p-2 rounded-lg text-center font-semibold text-md p-1 w-full`}>
                    {
                        `${days} days to go`
                    }
                </div>
            </div>
        )
}
