import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {dataStore} from "./redux/datastore";
import {Provider} from "react-redux";
import {ChakraProvider} from "@chakra-ui/react";
import {UnderMaintenance} from "./UnderMaintainance";
import ChakraBrandTheme from "./theme";
import {TestPage} from "./TestPage";
import ProgressProvider from "./components/progressBar/ProgressProvider";

setTimeout(_=>{
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <ChakraProvider theme={ChakraBrandTheme}>
                <Provider store={dataStore}>
                    <BrowserRouter>
                        <ProgressProvider>
                            <App/>
                        </ProgressProvider>
                    </BrowserRouter>
                </Provider>
            </ChakraProvider>
        </React.StrictMode>
    );

},3000)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//<UnderMaintenance message={"The website is under maintenance for salary features and minor fixes. "} time={"4AM 12/05/2023 IST"}/>
