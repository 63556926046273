import {Route, Routes} from "react-router-dom";
import {kycV2Routes} from "../../routes/kyc_v2/kycV2Routes";
import {TopBar} from "../../components/nav/TopBar";

function KYC_V2(){
    return(
        <>
            <TopBar/>
            <Routes>
                {
                    kycV2Routes.map((route, index)=>
                        (<Route key={index} exact path={route.route} element={route.component}/> )
                    )
                }
            </Routes>
        </>
    )
}

export default KYC_V2
