import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {FirebaseStorage} from "../index";

/**
 * Uploads an image file to storage and returns access link
 * @param file
 * @param folderPath
 * @param fileName
 * @returns {Promise<String>}
 */
export function uploadImage(file, folderPath, fileName){
    return new Promise((resolve, reject)=>{
        if(
            file == null ||
            folderPath === null || folderPath === undefined ||
            fileName === null || fileName===undefined
        ) {reject("Missing values to upload images")}
        const uploadRef = ref(FirebaseStorage, `${folderPath}/${fileName}.jpg`)
        uploadBytes(uploadRef, file).then((_) => {
            getDownloadURL(uploadRef).then(link=>{
                resolve(link)
            }).catch(e=>{
                reject(e)
            })
        }).catch(e=>{
            reject(e)
        })
    })
}
