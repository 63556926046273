import {firestorePaths} from "../firebase/database/firestorePaths";

export const getDifferenceDays = function(writeDate, lastUpdated) {
    let date = lastUpdated;
    if (date === null || date === undefined) date = writeDate;
    const docDate = date.toDate();
    const today = Date.now();
    const diffTime = Math.abs(today - docDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export function getFullName(user){
    let name = ''
    if(user[firestorePaths.doc.user.first_name] !== null &&
        user[firestorePaths.doc.user.first_name] !== undefined &&
        user[firestorePaths.doc.user.first_name] !== '')
        name += user[firestorePaths.doc.user.first_name]
    if(user[firestorePaths.doc.user.middle_name] !== null &&
        user[firestorePaths.doc.user.middle_name] !== undefined &&
        user[firestorePaths.doc.user.middle_name] !== '')
        name += ` ${user[firestorePaths.doc.user.middle_name]}`
    if(user[firestorePaths.doc.user.last_name] !== null &&
        user[firestorePaths.doc.user.last_name] !== undefined &&
        user[firestorePaths.doc.user.last_name] !== '')
        name += ` ${user[firestorePaths.doc.user.last_name]}`
    return name
}

export function getFullNameFromParts(first, middle, last){
    let name = ''
    if(first !== null &&
        first !== undefined &&
        first !== '')
        name += first
    if(middle !== null &&
        middle !== undefined &&
        middle !== '')
        name += ` ${middle}`
    if(last !== null &&
        last !== undefined &&
        last !== '')
        name += ` ${last}`
    return name
}

export function isNullSafe(value) {
    return (value !== null && value !== undefined)
}

export function fuzzyNameMatching(name, query) {
    const cleanedName = name.trim().toLowerCase();
    const cleanedQuery = query.trim().toLowerCase();

    const nameWords = cleanedName.split(' ');
    const queryWords = cleanedQuery.split(' ');

    let totalScore = 0;
    let maxPossibleScore = queryWords.length;

    for (const queryWord of queryWords) {
        let bestScore = 0;

        for (const nameWord of nameWords) {
            const similarity = calculateSimilarity(nameWord, queryWord);

            if (similarity > bestScore) {
                bestScore = similarity;
            }
        }

        totalScore += bestScore;
    }

    return totalScore / maxPossibleScore;
}

function calculateSimilarity(word1, word2) {
    // Implement your similarity metric calculation here
    // Return a value between 0 and 1 representing the similarity score
    // The higher the score, the more similar the words are
    // Example implementation using Levenshtein distance:
    const m = word1.length;
    const n = word2.length;
    const dp = Array.from({ length: m + 1 }, () => Array(n + 1));

    for (let i = 0; i <= m; i++) {
        dp[i][0] = i;
    }

    for (let j = 0; j <= n; j++) {
        dp[0][j] = j;
    }

    for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= n; j++) {
            if (word1[i - 1] === word2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1];
            } else {
                dp[i][j] = Math.min(
                    dp[i - 1][j - 1],
                    dp[i][j - 1],
                    dp[i - 1][j]
                ) + 1;
            }
        }
    }

    return 1 - dp[m][n] / Math.max(m, n);
}
