import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyABOzrY1IEljiwPtNI0VHEwQZ97HlieT3s",
    authDomain: "trade-cops-india.firebaseapp.com",
    projectId: "trade-cops-india",
    storageBucket: "trade-cops-india.appspot.com",
    messagingSenderId: "64731984596",
    appId: "1:64731984596:web:ab47dc97b3d10ea04f8141",
    measurementId: "G-MM8BTMXBLT"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
// Initialize services
const FirebaseDB = getFirestore()
const FirebaseAuth = getAuth()
const FirebaseStorage = getStorage()



export {FirebaseAuth, FirebaseStorage, FirebaseDB}
