import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import {Avatar, Box, HStack, VStack} from "@chakra-ui/react";
import {Loading} from "../../../components/loading/Loding";
import {Image} from "@chakra-ui/react";
import {KYCStatusChip} from "../../../components/kyc/KYCStatusChip";
import {format} from "date-fns";
import {HiOutlineMail, HiOutlinePhone} from "react-icons/hi";
import {SlLocationPin} from "react-icons/sl";
import {CiCalendarDate} from "react-icons/ci";
import {BsBank2, BsCalendar2Date, BsFillPersonFill} from "react-icons/bs";
import {GrStatusGood} from "react-icons/gr";
import {BiFingerprint} from "react-icons/bi";
import {ConditionalRender} from "../../../components/dataSafe/ConditionalRender";

export function Profile(props){
    const userData = useSelector(state=>{return state.userData})
    const [userDetails, setUserDetails] = useState(null)

    function getFullName(user){
        let name = ''
        if(user[firestorePaths.doc.user.first_name] !== null &&
            user[firestorePaths.doc.user.first_name] !== undefined &&
            user[firestorePaths.doc.user.first_name] !== '')
            name += user[firestorePaths.doc.user.first_name]
        if(user[firestorePaths.doc.user.middle_name] !== null &&
            user[firestorePaths.doc.user.middle_name] !== undefined &&
            user[firestorePaths.doc.user.middle_name] !== '')
            name += ` ${user[firestorePaths.doc.user.middle_name]}`
        if(user[firestorePaths.doc.user.last_name] !== null &&
            user[firestorePaths.doc.user.last_name] !== undefined &&
            user[firestorePaths.doc.user.last_name] !== '')
            name += ` ${user[firestorePaths.doc.user.last_name]}`
        return name
    }

    useEffect(_=>{
        if(userData !== null && userData !== undefined){
            if(userData.isLoaded){
                if(userData.data !== null && userData.data !== undefined){
                    setUserDetails(userData.data)
                }
            }
        }
    },[userData])

    useEffect(_=>{
        if(userDetails !== null && userDetails !== undefined){
        }
    },[userDetails])

    return(
        (userDetails === null || userDetails === undefined )?
            <Loading/>:
        <div className={'w-full h-full lg:p-8 p-2'}>
            <div className={'flex flex-col lg:flex-row items-center gap-4'}>
                <Avatar
                    className={'shadow-lg'}
                    size={'2xl'}
                    borderRadius={'xl'}
                    src={userDetails[firestorePaths.doc.user.pic_url]}
                    name={userDetails[firestorePaths.doc.user.pic_url]}/>
                <div className={''}>
                    <div className={'w-full mt-4 text-2xl lg:text-left text-center font-semibold'}>{getFullName(userDetails)}</div>
                    <div className={'w-full text-lg lg:text-left text-center text-black/60'}>{userDetails[firestorePaths.doc.user.email]}</div>
                </div>

            </div>
            <div className={'grid lg:grid-cols-2 grid-cols-1 w-full gap-4 min-w-full mt-8'}>
                <div className={'w-full mt-8 text-[1.2em]'}>
                    <div className={'flex gap-2 items-center'}>
                        <div className={'min-w-[50px] min-h-[50px] grid place-content-center h-fit w-fit p-4 rounded-xl bg-black text-white'}>
                            <BsFillPersonFill className={'text-xl'}/>
                        </div>
                        <div className={'text-xl'}>
                            Personal Details
                        </div>
                    </div>
                    <div className={'grid grid-flow-col gap-8 w-fit mt-6 items-center'}>
                        <div className={'flex gap-1 items-center w-fit text-black/60'}>
                            <HiOutlineMail className={'text-xl'}/>
                            <div className={''}>Email: </div>
                        </div>
                        <div className={'w-fit'}>{userDetails[firestorePaths.doc.user.email]}</div>
                    </div>
                    <div className={'grid grid-flow-col gap-4 w-fit mt-6 items-center'}>
                        <div className={'flex gap-1 items-center w-fit text-black/60'}>
                            <HiOutlinePhone className={'text-xl'}/>
                            <div className={''}>Phone: </div>
                        </div>
                        <div className={'w-fit'}>{userDetails[firestorePaths.doc.user.phone]}</div>
                    </div>
                    <div className={'grid grid-flow-col gap-4 w-fit mt-6 items-center'}>
                        <div className={'flex gap-1 items-center w-fit text-black/60'}>
                            <SlLocationPin className={'text-xl'}/>
                            <div className={''}>Address: </div>
                        </div>
                        <div className={'w-fit text-left flex flex-wrap'}>{userDetails[firestorePaths.doc.user.address]}</div>
                    </div>
                    <div className={'grid grid-flow-col gap-4 w-fit mt-6 mb-4 items-center'}>
                        <div className={'flex gap-1 items-center w-fit text-black/60'}>
                            <BsCalendar2Date className={'text-xl'}/>
                            <div className={''}>Date of birth: </div>
                        </div>
                        <div className={'w-fit'}>{format(new Date(userDetails[firestorePaths.doc.user.dob]), "dd-MM-yyyy")}</div>
                    </div>
                    <div className={'grid grid-flow-col gap-4 w-fit mt-6 mb-4 items-center'}>
                        <div className={'flex gap-1 items-center w-fit text-black/60'}>
                            <BiFingerprint className={'text-[1.2em]'}/>
                            <div className={''}>KYC Status: </div>
                        </div>
                        <KYCStatusChip status={userDetails[firestorePaths.doc.user.kyc_status]}/>
                    </div>
                </div>
                <div className={'w-full min-w-full text-black text-[1.2em] mt-8 text-left'}>
                    <div className={'flex gap-2 items-center'}>
                        <div className={'min-w-[50px] min-h-[50px] grid place-content-center h-fit w-fit p-4 rounded-xl bg-black text-white'}>
                            <BsBank2 className={'text-xl'}/>
                        </div>
                        <div className={'text-xl'}>
                            Bank details
                        </div>
                    </div>
                    <div className={'flex items-center gap-4 w-fit mb-4 mt-6'}>
                        <div className={'w-fit text-black/60'}>PAN number: </div>
                        <div className={'w-fit'}>
                            {
                                (userDetails[firestorePaths.doc.user.version] === 2)?
                                    userDetails[firestorePaths.doc.user.pan_number]:
                                    userDetails[firestorePaths.doc.user.bank_details.path][firestorePaths.doc.user.bank_details.pan_number]
                            }
                        </div>
                    </div>
                    <div className={'grid grid-flow-col gap-4 w-fit mt-6'}>
                        <div className={'w-fit text-black/60'}>Account holder: </div>
                        <div className={'w-fit'}>
                            {userDetails[firestorePaths.doc.user.bank_details.path][firestorePaths.doc.user.bank_details.bank_account_holder_name]}
                        </div>
                    </div>
                    <div className={'grid grid-flow-col gap-4 w-fit mt-6'}>
                        <div className={'w-fit text-black/60'}>Account no: </div>
                        <div className={'w-fit'}>
                            {userDetails[firestorePaths.doc.user.bank_details.path][firestorePaths.doc.user.bank_details.bank_account_no]}
                        </div>
                    </div>
                    <div className={'grid grid-flow-col gap-4 w-fit mt-6'}>
                        <div className={'w-fit text-black/60'}>IFSC: </div>
                        <div className={'w-fit'}>
                            {userDetails[firestorePaths.doc.user.bank_details.path][firestorePaths.doc.user.bank_details.bank_account_ifsc]}
                        </div>
                    </div>
                    <div className={'grid grid-flow-col gap-4 w-fit mt-6'}>
                        <div className={'w-fit text-black/60'}>Account type: </div>
                        <div className={'w-fit'}>
                            {userDetails[firestorePaths.doc.user.bank_details.path][firestorePaths.doc.user.bank_details.bank_account_type]}
                        </div>
                    </div>
                    <div className={'grid grid-flow-col gap-4 w-fit mt-6'}>
                        <div className={'w-fit text-black/60'}>Bank name: </div>
                        <div className={'w-fit'}>
                            {userDetails[firestorePaths.doc.user.bank_details.path][firestorePaths.doc.user.bank_details.bank_name]}
                        </div>
                    </div>
                    <ConditionalRender condition={userDetails[firestorePaths.doc.user.version] !== 2}>
                        <div className={'grid grid-flow-col gap-4 w-fit mt-6'}>
                            <div className={'w-fit text-black/60'}>Bank address: </div>
                            <div className={'w-fit'}>
                                {userDetails[firestorePaths.doc.user.bank_details.path][firestorePaths.doc.user.bank_details.bank_address]}
                            </div>
                        </div>
                    </ConditionalRender>
                </div>
            </div>
            <div className={'flex gap-2 items-center mt-12'}>
                <div className={'min-w-[50px] min-h-[50px] grid place-content-center h-fit w-fit p-4 rounded-xl bg-black text-white'}>
                    <BiFingerprint className={'text-xl'}/>
                </div>
                <div className={'text-xl'}>
                    KYC Details
                </div>
            </div>
            <div className={`mt-4 text-left ${(userDetails[firestorePaths.doc.user.version] === 2)?"text-black": "text-red-600"}`}>
                {
                    (userDetails[firestorePaths.doc.user.version] === 2)?
                        "You are using an updated version of KYC system. All your e-KYC details we have collected will be listed here. \n" +
                        "To make any corrections or adjustments, create a support ticket or contact us directly."
                        :
                        "You are using a legacy version of our KYC system. We have updated the way we onboard new users with complete digital KYC. " +
                        "This section can look different if you are a new user, on-boarded through the new KYC system. " +
                        "You dont have to bother about changing anything. In case any change is needed, you will be notified on here or on your email/phone."
                }
            </div>
            <div className={'grid lg:grid-cols-3 grid-cols-1 w-full gap-4 mt-4'}>
                <div className={'w-full mt-4 max-w-full grid place-content-center border-[2px] p-4 rounded-lg border-black/60 border-dashed'}>
                    <div className={'min-w-full text-left text-lg mb-2'}>ID Proof</div>
                    <a href={userDetails[firestorePaths.doc.user.id_proof_url]} target={"_blank"} rel={"alternate"}>
                        <Image className={'max-h-[400px] object-contain'} m={'auto'} src={userDetails[firestorePaths.doc.user.id_proof_url] } alt={"id_proof_image"}/>
                    </a>
                </div>
                <div className={'border-[2px] p-2 rounded-lg border-black/60 w-full mt-4 border-dashed'}>
                    <div className={'w-full text-left text-lg mb-2'}>Address Proof</div>
                    <a href={userDetails[firestorePaths.doc.user.address_proof_url]} target={"_blank"} rel={"alternate"}>
                        <Image className={'max-h-[400px] object-contain'} m={'auto'} src={userDetails[firestorePaths.doc.user.address_proof_url] } alt={"address_proof_image"}/>
                    </a>
                </div>
                <div className={'border-[2px] p-2 rounded-lg border-black/60 w-full mt-4 border-dashed'}>
                    <div className={'w-full text-left text-lg mb-2'}>PAN card</div>
                    <a href={userDetails[firestorePaths.doc.user.pan_url]} target={"_blank"} rel={"alternate"}>
                        <Image className={'max-h-[400px] object-contain'} m={'auto'} src={userDetails[firestorePaths.doc.user.pan_url] } alt={"pan_image"}/>
                    </a>
                </div>
            </div>
            <div className={'grid lg:grid-cols-3 grid-cols-1 w-full gap-4'}>
                <div className={'border-[2px] p-2 rounded-lg border-black/60 w-full mt-4 border-dashed'}>
                    <div className={'w-full text-left text-lg mb-2'}>Signature</div>
                    <a href={userDetails[firestorePaths.doc.user.signature_url]} target={"_blank"} rel={"alternate"}>
                        <Image className={'max-h-[400px] object-contain'} m={'auto'} src={userDetails[firestorePaths.doc.user.signature_url] } alt={"signature_image"}/>
                    </a>
                </div>
                <div className={'border-[2px] p-2 rounded-lg border-black/60 w-full mt-4 border-dashed'}>
                    <div className={'w-full text-left text-lg mb-2'}>Cheque book</div>
                    <a href={userDetails[firestorePaths.doc.user.cheque_book_url]} target={"_blank"} rel={"alternate"}>
                        <Image className={'max-h-[400px] object-contain'} m={'auto'} src={userDetails[firestorePaths.doc.user.cheque_book_url] } alt={"cheque_book_image"}/>
                    </a>
                </div>
            </div>
        </div>
    )
}
