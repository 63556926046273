import { getStorage, ref, uploadBytes, getDownloadURL, connectStorageEmulator} from "firebase/storage";
import {storageFilePath} from "./storageFilePath";
import {updateKYCDb} from "../database/update/KycUpdates";
import KYCCardID from "../../utils/KYCCardID";
import {firestorePaths} from "../database/firestorePaths";

/**
 *
 * @param kyc_id
 * @param email
 * @param file
 * @returns {Promise<unknown>}
 */
export function uploadKYC(kyc_id, email, file){
    return new Promise((resolve, reject)=>{
        if(email === null || email === undefined || file === null || file === undefined ) reject(Error("Insufficient data"))
        let folder;
        let field;
        switch (kyc_id){
            case KYCCardID.id_proof: {
                folder = storageFilePath.idProof
                field = firestorePaths.doc.user.id_proof_url
                break
            }
            case KYCCardID.address_proof: {
                folder = storageFilePath.addressProof
                field = firestorePaths.doc.user.address_proof_url
                break
            }
            case KYCCardID.pic: {
                folder = storageFilePath.photo
                field = firestorePaths.doc.user.pic_url
                break
            }
            case KYCCardID.pan: {
                folder = storageFilePath.pan
                field = firestorePaths.doc.user.pan_url
                break
            }
            case KYCCardID.cheque_book:{
                folder = storageFilePath.chequeBook
                field = firestorePaths.doc.user.cheque_book_url
                break
            }
            case KYCCardID.signature:{
                folder = storageFilePath.signature
                field = firestorePaths.doc.user.signature_url
                break
            }
            default:{
                folder = null
                break
            }
        }
        const storage = getStorage()
        //connectStorageEmulator(storage, '127.0.0.1',9199)
        if(folder === null || folder === undefined) reject(Error("Undefined folder"))
        const uploadRef = ref(storage, `${folder}/${email}/image.jpg`)
        uploadBytes(uploadRef, file).then((_) => {
            getDownloadURL(uploadRef).then(link=>{
                updateKYCDb(field, email, link).then(_=>{
                    resolve(link)
                }).catch(e=>{
                    reject(e)
                })
            }).catch(e=>{
                reject(e)
            })
        }).catch(e=>{
            reject(e)
        })
    })
}
