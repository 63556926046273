import {getAuthHeaderConfig} from "./HeaderConfig";
import axios from "axios";
import {Api, getUrl} from "../../api/Url";

export function ConfirmDetails(
    accessToken,
    firstName,
    middleName,
    lastName,
    dob,
    phone,
    zipCode,
    address,
    picURL){
    return new Promise((resolve, reject)=>{
        const config = getAuthHeaderConfig(accessToken)
        axios.post(getUrl(Api.confirmDetails), {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            dob: dob,
            phone: phone,
            zip_code: zipCode,
            address: address,
            pic_url: picURL
        },config).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err.response.data)
        })
    })
}
