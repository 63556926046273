import axios from "axios";
import {Api, getUrl} from "../../api/Url";
import {getAuthHeaderConfig} from "./HeaderConfig";

export function createUserRecord(user, accessToken){
    return new Promise((resolve, reject)=>{
        const config = getAuthHeaderConfig(accessToken)
        axios.post(getUrl(Api.registerUser), user, config).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err.response.data)
        })
    })
}
