import {getAuthHeaderConfig} from "./HeaderConfig";
import axios from "axios";
import {Api, getUrl} from "../../api/Url";

const PanVerify = (accessToken, uid)=>{
    return new Promise((resolve, reject)=>{
        const config = getAuthHeaderConfig(accessToken)
        axios.post(getUrl(Api.panKYC), {
            pan_number: uid
        },config).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err.response.data)
        })
    })
}

export default PanVerify
