import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import useUserDataState from "./useUserDataState";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {setDepositList} from "../../redux/reducer/userDepositsReducer";
import {GetSalary} from "../../firebase/database/get/GetSalary";
import {setUserSalary} from "../../redux/reducer/userSalaryReducer";

function useSalaryState(){
    const salaryList = useSelector(state=>{return state.userSalary})
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [userDetails, , , userDetailsLoading] = useUserDataState()

    const dispatch = useDispatch()

    const refresh = ()=> {
        if(userDetails && !userDetailsLoading){
            if(
                userDetails[firestorePaths.doc.user.email] !== null &&
                userDetails[firestorePaths.doc.user.email] !== undefined &&
                userDetails[firestorePaths.doc.user.email] !== ""
            )
                GetSalary(userDetails[firestorePaths.doc.user.email])
                    .then(salaryList =>{
                        dispatch(setUserSalary(salaryList))
                    })
                    .catch(e=>{
                        console.log(e)
                        /* toast(ErrorToast("Error", e.message))*/
                    })
        }
    }

    useEffect(_=>{
        if(salaryList.isLoaded){
            if(salaryList.data){
                setData(salaryList.data)
            }else{
                setData(null)
            }
            setLoading(false)
        }else{
            refresh()
            setLoading(true)
        }
    },[salaryList, userDetails])

    return([data, refresh, loading])
}

export default useSalaryState
