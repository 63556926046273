import {createSlice} from "@reduxjs/toolkit";

const userSalarySlicer = createSlice({
    name: 'userSalary',
    initialState:{
        isLoaded: false,
        data: [],
    },
    reducers:{
        resetSalaryState: (state)=>{
            state.isLoaded = false
            state.data = []
        },
        salaryListLoaded: (state)=>{
            state.isLoaded = true
        },
        setUserSalary: (state, action)=>{
            state.data = action.payload
            state.isLoaded = true
        },
    }
})

export const {resetSalaryState, salaryListLoaded, setUserSalary} = userSalarySlicer.actions
export default userSalarySlicer.reducer
