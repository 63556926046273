import {Button, useToast} from "@chakra-ui/react";
import {useState} from "react";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {AddReferralAccessRequest} from "../../firebase/database/add/AddReferralAccessRequest";
import {ErrorToast, SuccessToast} from "../../utils/ToastBuilder";

export function RequestReferralAccessCard(props){
    const email = props.email
    const requestState = props.requestState
    const [isBusy, setIsBusy] = useState(false)
    const toast = useToast()

    const onRequestEvent = ()=>{
        if(email !== null && email !==undefined){
            setIsBusy(true)
            AddReferralAccessRequest(email).then(_=>{
                toast(SuccessToast("Success", "Request sent"))
                setIsBusy(false)
            }).catch(e=>{
                toast(ErrorToast('Failed', e.message))
                setIsBusy(false)
            })
        }
    }
    return(
        <div className={`rounded-lg w-full h-full lg:col-span-2`}>
            <div className={'grid grid-flow-row align-middle h-full'}>
                <div className={'text-left text-xl font-semibold'}>Referral Link</div>
                <div className={'border-2 pl-2 border-gray-500 border-dashed rounded-lg h-fit w-full'}>
                    <div className={'text-left text-md text-gray-500 p-2 w-full'}>
                        You are eligible for referral scheme.
                    </div>
                    <div className={'p-4'}>
                        <Button colorScheme={'blue'} isLoading={isBusy} disabled={requestState} onClick={onRequestEvent}>
                            {(requestState)?"Request Pending": "Request Access"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
