import {getCurrentDate} from "../../../utils/Window";
import {Button, Divider, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast} from "@chakra-ui/react";
import upiqr from "upiqr";
import {useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {useSelector} from "react-redux";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import RegistrationFeesStatus from "../../../utils/RegistrationFeesStatus";
import {sendRegistrationFees} from "../../../firebase/database/update/RegistrationFees";
import {ErrorToast, SuccessToast} from "../../../utils/ToastBuilder";
import logo from "../../../raw/logo/logo512.png"
import {CiLogout} from "react-icons/ci";
import {logout} from "../../../firebase/auth/callbacks";
import {IMAGE_DEFAULT} from "../../../components/collector/ImageUploader";
import ImageUploader from "../../../components/collector/ImageUploader/ImageUploader";
import useUserDataState from "../../../hooks/state/useUserDataState";
import {Loading} from "../../../components/loading/Loding";
import {UnderMaintenance} from "../../../UnderMaintainance";
import {DataSafeRender} from "../../../components/dataSafe/DataSafeRender";
import {storageFilePath} from "../../../firebase/storage/storageFilePath";
import gradientBG from "../../../raw/img/vectors/gradient.svg";
import LogoutButton from "../../../components/buttons/LogoutButton";
import AnimDivClick from "../../../components/animated/AnimDivClick";
import {TopBar} from "../../../components/nav/TopBar";

export function RegistrationFees(props){

    const [qrData, setQRData] = useState(null)
    const [qrIntent, setQRIntent] = useState(null)
    //const userData = useSelector(state=>{return state.userData})
    const [userDetails, , ,userDataLoading] = useUserDataState()
    //const [userDetails, setUserDetails] = useState({})
    const [tempRefID, setTempRefID] = useState(`${Date.now()}`)
    const [refID, setRefID] = useState("")
    const [receiptImageURL, setReceiptImageURL] = useState(null)
    const [submitDisable, setSubmitDisable] = useState(true)
    const [statusText, setStatusText] = useState("loading ...")

    const [amount, setAmount] = useState(1000)
    const [gstRate, setGSTRate] = useState(18)

    const ref = useRef()
    const trigRef = useRef()
    const toast = useToast()

    const getName= (first, middle, last)=>{
        let name = ''
        if(first !== null && first !== undefined && first !== ''){
            name = `${name} ${first}`
        }
        if(middle !== null && middle !== undefined && middle !== ''){
            name = `${name} ${middle}`
        }
        if(last !== null && last !== undefined && last !== ''){
            name = `${name} ${last}`
        }
        return name
    }

    /*useEffect(_=>{
        if(userData !== null && userData !== undefined && !userDataLoading)
            setUserDetails(userData)
    },[userData])*/

    useEffect(_=>{
        if(userDetails!== null && userDetails !== undefined){
            switch (userDetails[firestorePaths.doc.user.registration_fees_status]) {
                case RegistrationFeesStatus.INCOMPLETE: setStatusText("Not paid"); break;
                case RegistrationFeesStatus.COMPLETE: setStatusText("Paid"); break;
                case RegistrationFeesStatus.PENDING: setStatusText("Pending approval"); break;
                case RegistrationFeesStatus.REJECTED: setStatusText("Payment Rejected"); break;
                default: setStatusText("Not Paid"); break;
            }
            if((userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.INCOMPLETE) ||
                (userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.REJECTED) ||
                (userDetails[firestorePaths.doc.user.registration_fees_status] === null) ||
                (userDetails[firestorePaths.doc.user.registration_fees_status] === undefined)) {
                setTempRefID(Date.now())
                setSubmitDisable(false)
            }
            else {
                setRefID(userDetails[firestorePaths.doc.user.registration_fees_id])
                setTempRefID(userDetails[firestorePaths.doc.user.registration_fees_id])
                setSubmitDisable(true)
            }
        }
    },[userDetails])

    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });

    useEffect(_=>{
        upiqr({
            payeeVPA: "tradecopsprivatelimited@ybl",
            payeeName: "TradeCops India",
            amount: amount + (amount * (gstRate / 100)),
            transactionNote: tempRefID,
            transactionRefUrl: tempRefID,
            transactionId: tempRefID,
            transactionRef: tempRefID
        })
            .then((upi) => {
                setQRData(upi.qr)    // data:image/png;base64,eR0lGODP...
                setQRIntent(upi.intent);  // upi://pay?pa=john@upi&pn=JOHN DOE
            })
            .catch(err => {
                console.log(err);
            });
    },[tempRefID])

    return(
        <div className={'min-w-screen min-h-screen'}>
            <TopBar/>
            {
                (userDataLoading)?
                    <Loading/>:
                    <DataSafeRender alt={<div>Error: User details is null or undefined. Contact developer.</div>} values={[userDetails]}>
                        <div className={"w-full grid lg:grid-cols-2 grid-cols-1"}>
                            {
                                (userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.COMPLETE ||
                                    userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.PENDING)?
                                    <div className={'w-full p-4 text-left'}>
                                        <div ref={ref}
                                             className={'text-sm mt-4 font-mono border-2 border-black border-dashed py-2 px-4 mx-2 rounded-lg'}>
                                            <div className={'flex place-content-center'}>
                                                <img className={'w-[150px]'} src={logo} alt={"logo"}/>
                                            </div>
                                            <div className={'text-lg font-semibold text-center'}>TradeCops India</div>
                                            <div className={'text-md text-center'}>Suite No B-10 H15 H Block Sector 63 Noida Uttar Pradesh 201301</div>
                                            <div className={'text-md text-center mb-4'}>Contact: 01203118877, support@tradecops.com</div>
                                            <div className={'text-md text-center mb-4'}>GST No: 09AAJCT2501K1Z9</div>
                                            <div className={'mt-4 font-mono pt-2 px-4 grid grid-cols-2 rounded-lg'}>
                                                <div>
                                                    <div className={'text-left'}>Bill to: {getName(
                                                        userDetails[firestorePaths.doc.user.first_name],
                                                        userDetails[firestorePaths.doc.user.middle_name],
                                                        userDetails[firestorePaths.doc.user.last_name]
                                                    )}</div>
                                                    <div className={'text-left'}>Ref: {refID}</div>
                                                </div>
                                                <div className={'border-l-2 pl-4'}>
                                                    <div className={'text-left'}>Date: {getCurrentDate()}</div>
                                                </div>
                                            </div>
                                            <Divider mt={4}/>
                                            <div className={''}>
                                                <TableContainer>
                                                    <Table varient={'striped'}>
                                                        <Thead>
                                                            <Tr>
                                                                <Th>Description</Th>
                                                                <Th>Quantity</Th>
                                                                <Th>Amount</Th>
                                                                <Th>IGST</Th>
                                                                <Th>IGST Amount</Th>
                                                                <Th>Total</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr>
                                                                <Td> Registration Frees </Td>
                                                                <Td> _ </Td>
                                                                <Td> ₹{amount}  </Td>
                                                                <Td> {gstRate}%  </Td>
                                                                <Td> ₹{amount * (gstRate / 100)} </Td>
                                                                <Td> ₹{amount + (amount * (gstRate / 100))} </Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                                <Divider my={4}/>
                                                <div className={'grid grid-cols-2'}>
                                                    <div className={'text-lg font-semibold'}>Payment Status :</div>
                                                    <div className={'text-lg font-semibold text-red-700'}>{statusText}</div>
                                                </div>
                                                <Divider my={4}/>
                                                <div className={'pt-16 text-[12px] w-full text-center'}>
                                                    This is a computer generated invoice.
                                                </div>
                                            </div>
                                        </div>
                                        <Button colorScheme={'green'} bgColor={'black'} ml={2}  ref={trigRef} onClick={handlePrint} mt={4} w={32}>Print</Button>
                                    </div>
                                    :
                                    <div className={'p-4 w-full'}>
                                        <div className={'text-center'}>
                                            <div className={'text-2xl font-semibold'}>Scan the QR</div>
                                            <div className={'mt-2'}>Scan this QR to pay using UPI.</div>
                                            <div className={'mt-2 rounded-lg p-4 text-center'}>
                                                <img src={qrData}
                                                     width={300}
                                                     className={"m-auto mt-4"}
                                                     alt={"qr_code"}/>
                                                <div className={'mt-4'}>
                                                    <div className={'text-xs'}>{tempRefID}</div>
                                                    <div className={'mt-8'}>Paying to:<code className={'ml-2'}>TRADECOPS INDIA</code></div>
                                                    <div className={'mb-8'}>Amount:<code className={'ml-2'}>₹{amount + (amount * (gstRate / 100))}</code></div>
                                                    <AnimDivClick className={'w-fit m-auto'}>
                                                        <a className={'text-sm justify-center text-center px-4 py-4 bg-black text-white rounded-md hover:bg-black z-10 shadow-lg font-semibold'}
                                                           href={qrIntent}
                                                           target={"_blank"}>
                                                            Open in payment app
                                                        </a>
                                                    </AnimDivClick>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                            <div className={'grid justify-center justify-items-center w-full'} style={{backgroundImage: `url(${gradientBG})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition:"center"}}>
                                <div className={'text-left p-4'}>
                                    <div className={'text-2xl font-semibold'}>Registration Fees</div>
                                    <div className={'mt-4'}>
                                        <div className={'mt-2'}>1. Please pay the registration fees to start your account.</div>
                                        <div className={'mt-2'}>2. Once you are done with the payment, click submit and we will confirm the payment within 24 hours.</div>
                                    </div>
                                    <div className={'mt-8'}>
                                        <div className={'text-2xl font-semibold'}>Transaction Status</div>
                                        <HStack mt={2}>
                                            <div className={'font-bold text-[1.1em]'}>Status: </div>
                                            <div className={'p-2 mt-4 grid font-bold text-[1.1em]'}>
                                                {statusText}
                                            </div>
                                        </HStack>
                                        <DataSafeRender values={[userDetails["registration_fees_comment"]]}>
                                            <HStack mt={1}>
                                                <div className={'font-bold text-[1.1em]'}>Admin comment: </div>
                                                <div className={'p-2 mt-4 grid font-bold text-[1.1em]'}>
                                                    " {userDetails["registration_fees_comment"]} "
                                                </div>
                                            </HStack>
                                        </DataSafeRender>
                                    </div>
                                    {
                                        (
                                            userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.COMPLETE ||
                                            userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.PENDING)?
                                            <DataSafeRender values={userDetails[firestorePaths.doc.user.registration_fees_receipt]} alt={<></>}>
                                                <div className={'font-semibold py-8 text-lg'}>Receipt Image</div>
                                                <img width={400} src={userDetails[firestorePaths.doc.user.registration_fees_receipt]} alt={"receipt_img"}/>
                                            </DataSafeRender>
                                            :
                                            <ImageUploader successLinkValueChanger={setReceiptImageURL}
                                                           folder={`${storageFilePath.receipt}/${userDetails[firestorePaths.doc.user.email]}`}
                                                           file={"reg_fee_receipt"}
                                                           postSuccess={(link)=>{
                                                               if(link!==null && link !== undefined && link !== ""){
                                                                   sendRegistrationFees(userDetails[firestorePaths.doc.user.email], tempRefID, link).then(r =>{
                                                                       setRefID(tempRefID)
                                                                       toast(SuccessToast('Success', 'Updated your transaction details. Please wait and we will confirm your transaction within 24hrs.'))
                                                                   }).catch(e=>{
                                                                       console.log(e)
                                                                       toast(ErrorToast('Failed', e.message))
                                                                   })
                                                               }else{
                                                                   toast(ErrorToast("Invalid file reference", link))
                                                               }
                                                           }}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </DataSafeRender>
            }

        </div>
    )
}
