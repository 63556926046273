import {Spinner} from "@chakra-ui/react";

export function Loading(props){
    const className = props.className ? props.className : ''
    return(
        <div className={`${className} w-fit h-fit text-xs`}>
            <Spinner/>
        </div>
    )
}
