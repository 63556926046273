/**
 * @description This is a list of all the routes in the dashboard
 * @todo Add new routes/pages here
 * @type {{component, route: string}[]}
 */
import {dashboardRoutePaths} from "./dashboardRoutePaths";
import {Home} from "../../pages/dashboard/pages/Home";
import {Deposit} from "../../pages/dashboard/pages/Deposit";
import {Payment} from "../../pages/dashboard/pages/Payment";
import {Referral} from "../../pages/dashboard/pages/referral/Referral";
import {DepositDetails} from "../../pages/dashboard/pages/DepositDetails";
import {ReferralReturns} from "../../pages/dashboard/pages/ReferralReturns";
import {Profile} from "../../pages/dashboard/pages/Profile";
import {AwardCeremony1} from "../../pages/dashboard/pages/event/AwardCeremony1";
import {RegistrationFees} from "../../pages/dashboard/pages/RegistrationFees";
import {Salary} from "../../pages/dashboard/pages/salary/Salary";

export const dashboardRoutes = [
    getRoutesObject('', <Home/>),
    getRoutesObject(dashboardRoutePaths.profile, <Profile/>),
    getRoutesObject(dashboardRoutePaths.deposit, <Deposit/>),
    getRoutesObject(`${dashboardRoutePaths.deposit}/:id`, <DepositDetails/>),
    getRoutesObject(`${dashboardRoutePaths.payment}/:amount`, <Payment/>),
    getRoutesObject(`${dashboardRoutePaths.referral}/*`, <Referral/>),
    getRoutesObject(`${dashboardRoutePaths.referral_returns}/*`, <ReferralReturns/>),
    getRoutesObject(`${dashboardRoutePaths.salary}/*`, <Salary/>),
    getRoutesObject(`${dashboardRoutePaths.event_award_1}/*`, <AwardCeremony1/>),
]

/**
 * Generates route objects
 * @param route
 * @param element
 * @returns {{component, route: string}}
 */
export function getRoutesObject(route, element){
    return {route: `/${route}`, component: element}
}
