import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'

export function firebaseLoginWithEmail(email, password){
    const auth = getAuth()
    return new Promise((resolve, reject)=>{
        signInWithEmailAndPassword(auth, email, password).then(userCreds=>{
            resolve(userCreds.user)
        }).catch(err=>{
            reject(err)
        })
    })
}
