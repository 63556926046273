
import {kycRoutes} from "../../routes/kyc/kycRoutes";
import {Route, Routes, useNavigate} from "react-router-dom";

export function KYC(props){
    return(
        <div>
            <Routes>
                {kycRoutes.map((route, index)=>{
                    return <Route key={index} exact path={route.route} element={route.component}/>
                })}
            </Routes>
        </div>
    )
}
