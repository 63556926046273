import {createSlice} from "@reduxjs/toolkit";

const userDepositsReducer = createSlice({
    name: 'userDeposits',
    initialState:{
        isLoaded: false,
        data: [],
    },
    reducers:{
        resetDepositsList: (state)=>{
            state.isLoaded = false
            state.data = []
        },
        pushDeposit: (state, action)=>{
            state.data.push(action.payload)
            state.isLoaded = true
        },
        setDepositList: (state, action)=>{
            state.data = action.payload
            state.isLoaded = true
        },
        depositListLoaded: (state)=>{
            state.isLoaded = true
        }
    }
})

export const  {resetDepositsList, pushDeposit, setDepositList, depositListLoaded} = userDepositsReducer.actions
export default userDepositsReducer.reducer
