import {extendTheme} from "@chakra-ui/react";

const theme = extendTheme({
    colors:{
        blackButton:{
            50: "rgba(0,0,0,0.56)",
            100: "rgba(0,0,0,0.87)",
            500: "#000000",
        }
    }
})

export default theme
