import {Button, HStack, Input, Select, Textarea, useToast, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {UserModel} from "../../../firebase/models/UserModel";
import {updateBankDetails} from "../../../firebase/database/update/KycUpdates";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import BankAccountTypes from "../../../utils/BankAccountTypes";
import {ErrorToast, SuccessToast} from "../../../utils/ToastBuilder";
import {useNavigate} from "react-router-dom";
import KYC_Status from "../../../utils/KYC_Status";

export function KYCBankCollector(props){
    const userData = useSelector(state=>{return state.userData})

    const [userDetails, setUserDetails] = useState(UserModel())
    const [panNumber, setPanNumber] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [ifscCode, setIfscCode] = useState('')
    const [accountHolder, setAccountHolder] = useState('')
    const [accountType, setAccountType] = useState(BankAccountTypes.current)
    const [bankName, setBankName] = useState('')
    const [bankAddress, setBankAddress] = useState('')
    const [isBusy, setIsBusy] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [email, setEmail] = useState(null)

    const toast = useToast()
    const nav = useNavigate()


    useEffect(_=>{
        if(accountHolder !== '')
            if(accountNumber !== '')
                if(bankName !== '')
                    if(bankAddress !== '')
                        if(ifscCode !== '')
                            if(accountType !== '')
                                setDisableSubmit(false)
                            else setDisableSubmit(true)
                        else setDisableSubmit(true)
                    else setDisableSubmit(true)
                else setDisableSubmit(true)
            else setDisableSubmit(true)
        else setDisableSubmit(true)

    },[accountHolder, accountNumber, accountType, bankAddress, bankName, ifscCode])

    function getBankObject (pan_number, accountHolder, accountNumber, accountType, ifscCode, bankName, bankAddress){
        return {
            [firestorePaths.doc.user.bank_details.pan_number]: pan_number,
            [firestorePaths.doc.user.bank_details.bank_account_holder_name]: accountHolder,
            [firestorePaths.doc.user.bank_details.bank_account_no]: accountNumber,
            [firestorePaths.doc.user.bank_details.bank_account_ifsc]: ifscCode,
            [firestorePaths.doc.user.bank_details.bank_account_type]: accountType,
            [firestorePaths.doc.user.bank_details.bank_name]: bankName,
            [firestorePaths.doc.user.bank_details.bank_address]: bankAddress,
        }
    }

    const onSubmit = _=>{
        setIsBusy(true)
        updateBankDetails(email, getBankObject(panNumber.trim(), accountHolder.trim(), accountNumber.trim(), accountType,
            ifscCode.trim(), bankName.trim(), bankAddress.trim())).then(_=>{
                setIsBusy(false)
                toast(SuccessToast('Successfully updated details'))
            nav(-1)
        }).catch(e=>{
            setIsBusy(false)
            toast(ErrorToast('Failed to update', e.message))
        })
    }

    useEffect(_=>{
        if(userData !== null && userData !== undefined && userData.isLoaded)
            setUserDetails(userData.data)
    },[userData])

    useEffect(_=>{
        if(userDetails !== undefined){
            const bankDetails = userDetails[firestorePaths.doc.user.bank_details.path]

            if(userDetails[firestorePaths.doc.user.email] !== null &&
                userDetails[firestorePaths.doc.user.email] !== undefined)
                setEmail(userDetails[firestorePaths.doc.user.email])
            if(bankDetails[firestorePaths.doc.user.bank_details.pan_number] !== null &&
                bankDetails[firestorePaths.doc.user.bank_details.pan_number] !== undefined)
                setPanNumber(bankDetails[firestorePaths.doc.user.bank_details.bank_account_holder_name])
            if(bankDetails[firestorePaths.doc.user.bank_details.bank_account_holder_name] !== null &&
                bankDetails[firestorePaths.doc.user.bank_details.bank_account_holder_name] !== undefined)
                setAccountHolder(bankDetails[firestorePaths.doc.user.bank_details.bank_account_holder_name])
            if(bankDetails[firestorePaths.doc.user.bank_details.bank_account_no] !== null &&
                bankDetails[firestorePaths.doc.user.bank_details.bank_account_no] !== undefined)
                setAccountNumber(bankDetails[firestorePaths.doc.user.bank_details.bank_account_no])
            if(bankDetails[firestorePaths.doc.user.bank_details.bank_account_ifsc] !== null &&
                bankDetails[firestorePaths.doc.user.bank_details.bank_account_ifsc] !== undefined)
                setIfscCode(bankDetails[firestorePaths.doc.user.bank_details.bank_account_ifsc])
            if(bankDetails[firestorePaths.doc.user.bank_details.bank_account_type] !== null &&
                bankDetails[firestorePaths.doc.user.bank_details.bank_account_type] !== undefined)
                setAccountType(bankDetails[firestorePaths.doc.user.bank_details.bank_account_type])
            if(bankDetails[firestorePaths.doc.user.bank_details.bank_name] !== null &&
                bankDetails[firestorePaths.doc.user.bank_details.bank_name] !== undefined)
                setBankName(bankDetails[firestorePaths.doc.user.bank_details.bank_name])
            if(bankDetails[firestorePaths.doc.user.bank_details.bank_address] !== null &&
                bankDetails[firestorePaths.doc.user.bank_details.bank_address] !== undefined)
                setBankAddress(bankDetails[firestorePaths.doc.user.bank_details.bank_address])

            // TODO Fix the button not disable bug!!!
            if(userDetails[firestorePaths.doc.user.kyc_status] !== KYC_Status.COMPLETE &&
                userDetails[firestorePaths.doc.user.kyc_status] !== KYC_Status.PENDING)
                setDisableSubmit(false)
            else setDisableSubmit(true)
        }
    },[userDetails])

    return (
        <div>
            <div className={'min-w-screen min-h-screen grid place-content-center items-center align-middle bg-slate-50 md:p-16'}>
                <div className={'min-w-full md:min-h-fit md:max-w-2/3 md:w-2/3 md:min-w-2/3 bg-white p-8 rounded-lg shadow-2xl'}>
                    <div className={'text-3xl text-left font-bold'}>
                        Provide your bank details
                    </div>
                    <div className={'mt-2 mb-16 text-left mr-16 p-2 bg-slate-50 rounded-lg font-semibold'}>
                        Please provide us with correct details of your active bank account
                    </div>
                    <VStack>
                        <div className={'text-left w-full pl-2 font-semibold'}>PAN Number</div>
                        <Input className={'mb-4'}
                               placeholder={'PAN Number'}
                               type={'text'}
                               value = {panNumber}
                               onChange = {e=>{setPanNumber(e.target.value.toUpperCase())}}
                               variant={'outline'}/>
                        <div className={'text-left w-full pl-2 font-semibold'}>Account Type</div>
                        <Select className={''} placeholder='Select account type'
                                value={accountType} onChange={e=>{setAccountType(e.target.value)}}>
                            <option value={BankAccountTypes.savings}>Savings</option>
                            <option value={BankAccountTypes.current}>Current</option>
                        </Select>
                        <div className={'pt-4 grid md:grid-cols-2 grid-cols-1 gap-4 w-full'}>
                            <div>
                                <div className={'text-left w-full p-2 font-semibold'}>Account holder name</div>
                                <Input className={'mb-2'}
                                       placeholder={'Account holder ( Full name )'}
                                       type={'text'}
                                       value = {accountHolder}
                                       onChange = {e=>{setAccountHolder(e.target.value.toUpperCase())}}
                                       variant={'outline'}/>
                            </div>
                            <div>
                                <div className={'text-left w-full p-2 font-semibold'}>Account Number</div>
                                <Input className={'mb-2'}
                                       placeholder={'Account number'}
                                       type={'text'}
                                       value = {accountNumber}
                                       onChange = {e=>{setAccountNumber(e.target.value.toUpperCase())}}
                                       variant={'outline'}/>
                            </div>
                            <div>
                                <div className={'text-left w-full p-2 font-semibold'}>Account IFSC</div>
                                <Input className={'mb-2'}
                                       placeholder={'IFSC Code'}
                                       type={'text'}
                                       value = {ifscCode}
                                       onChange = {e=>{setIfscCode(e.target.value.toUpperCase())}}
                                       variant={'outline'}/>
                            </div>
                            <div>
                                <div className={'text-left w-full p-2 font-semibold'}>Bank Name</div>
                                <Input className={'mb-2'}
                                       placeholder={'Bank name'}
                                       type={'text'}
                                       value = {bankName}
                                       onChange = {e=>{setBankName(e.target.value.toUpperCase())}}
                                       variant={'outline'}/>
                            </div>
                            <div className={'md:col-span-2'}>
                                <div className={'text-left w-full p-2 font-semibold'}>Bank Address</div>
                                <Textarea className={'mb-2'}
                                          placeholder={'Bank address'}
                                          type={'text'}
                                          value = {bankAddress}
                                          onChange = {e=>{setBankAddress(e.target.value.toUpperCase())}}
                                          variant={'outline'}/>
                            </div>
                        </div>
                    </VStack>
                    <Button className={'mt-4'} disabled={disableSubmit} isLoading={isBusy}
                            colorScheme={'green'} onClick={onSubmit}>Submit</Button>
                    <div className={'mt-4'}>
                        <Button className={'w-full'} onClick={_=>{nav(-1)}}>Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
