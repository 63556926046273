const KYCCardID = {
    id_proof: 0,
    pan: 1,
    address: 2,
    cheque_book: 3,
    pic: 4,
    signature: 5,
    address_proof: 6,
    registration_fee_receipt: 7
}

export default KYCCardID
