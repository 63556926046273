import {Avatar, Button, HStack, VStack} from "@chakra-ui/react";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {CiLogout} from "react-icons/ci";
import {logout} from "../../firebase/auth/callbacks";
import LogoutButton from "../buttons/LogoutButton";
import {AlternateLogoutButton} from "../buttons/AlternateLogoutButton";

export function NavProfileSection(props){
    const user = props.user
    return(
        <div className={'mt-4 mb-4 rounded-lg p-2'}>
            <HStack>
                <Avatar size={'lg'} name={user[firestorePaths.doc.user.first_name]} background={'gray'}
                        src={user[firestorePaths.doc.user.pic_url]}/>
                <VStack className={'ml-2 mr-4'}>
                    <div className={'text-left w-full font-semibold text-md text-ellipsis'}>
                        Hey, {user[firestorePaths.doc.user.first_name]}
                    </div>
                    <div className={'text-left text-xs w-full text-black/70'}>
                        {user[firestorePaths.doc.user.email]}
                    </div>
                </VStack>
            </HStack>
        </div>
    )
}
