import {addDoc, collection, doc, getFirestore, updateDoc} from "firebase/firestore";
import {firestorePaths} from "../firestorePaths";

export function AddReferralAccessRequest(email){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        if(email !== null && email !== undefined){
            const docRef = doc(db,firestorePaths.collections.users,email)
            updateDoc(docRef, {[firestorePaths.doc.user.referralAccessRequested]: true})
                .then(_=>resolve(0))
                .catch(e=>reject(e))
        }else reject(Error('Email or data is null/undefined'))
    })
}
