import {useEffect, useState} from "react";
import useUserDataState from "./useUserDataState";
import {useDispatch, useSelector} from "react-redux";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {GetReferralReturns} from "../../firebase/database/get/GetReferralReturns";
import {setReferralReturnsList} from "../../redux/reducer/userReferralReturnsReducer";

function useReferralReturnsState(){
    const referralReturns = useSelector(state=>{return state.userReferralReturns})
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [userDetails, , , userDetailsLoading] = useUserDataState()

    const dispatch = useDispatch()

    const refresh = ()=> {
        if(userDetails && !userDetailsLoading){
            if(
                userDetails[firestorePaths.doc.user.email] !== null &&
                userDetails[firestorePaths.doc.user.email] !== undefined &&
                userDetails[firestorePaths.doc.user.email] !== ""
            )
                GetReferralReturns(userDetails[firestorePaths.doc.user.email])
                    .then(referralReturnsList =>{
                        dispatch(setReferralReturnsList(referralReturnsList))
                    })
                    .catch(e=>{
                        console.log(e)
                        /* toast(ErrorToast("Error", e.message))*/
                    })
        }
    }

    useEffect(_=>{
        if(referralReturns.isLoaded){
            if(referralReturns.data){
                setData(referralReturns.data)
            }else{
                setData(null)
            }
            setLoading(false)
        }else{
            refresh()
            setLoading(true)
        }
    },[referralReturns, userDetails])

    return([data, refresh, loading])
}

export default useReferralReturnsState
