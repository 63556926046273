import {query, getFirestore, collection, where, getDocs, getDoc, doc} from "firebase/firestore";
import {firestorePaths} from "../firestorePaths";

export function GetDeposits(email){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        const depositQuery = query(collection(db, firestorePaths.collections.deposits),
            where(firestorePaths.doc.deposit.owner, "==", email))
        getDocs(depositQuery).then(snapshot=>{
            const resultList = []
            snapshot.forEach(doc=>{
                if(doc.exists()) {
                    const data = doc.data()
                    data[firestorePaths.doc.deposit.date] = data[firestorePaths.doc.deposit.date]?.toDate()?.toISOString()
                    resultList.push(data)
                }
            })
            resultList.sort(
                (a,b)=>
                    b[firestorePaths.doc.deposit.write_date].toDate() -
                    a[firestorePaths.doc.deposit.write_date].toDate())
            resolve(resultList)
        }).catch(e=>reject(e))
    })
}

export function GetDepositsById(id){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        const docRef = doc(db, firestorePaths.collections.deposits, id)
        getDoc(docRef).then(docSnap=>{
            if(docSnap.exists()){
                resolve(docSnap.data())
            } else {
                reject(Error("Transaction not found"))
            }
        }).catch(e=>{
            reject(e)
        })
    })
}
