import {doc, getFirestore, Timestamp, updateDoc} from "firebase/firestore";
import {firestorePaths} from "../firestorePaths";
import RegistrationFeesStatus from "../../../utils/RegistrationFeesStatus";

/**
 *
 * @param email
 * @param id
 * @param url
 * @returns {Promise<boolean>}
 */
export function sendRegistrationFees(email, id, url){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        if(id !== null && id !== undefined && url !== undefined && url !== null && email !== undefined && email !== null){
            const docRef = doc(db,firestorePaths.collections.users,email)
            updateDoc(docRef, {
                [firestorePaths.doc.user.registration_fees_id]: id,
                [firestorePaths.doc.user.registration_fees_receipt]: url,
                [firestorePaths.doc.user.registration_fees_status]: RegistrationFeesStatus.PENDING,
                [firestorePaths.doc.user.registration_fees_last_updated]: Timestamp.now()
            })
                .then(_=>resolve(0))
                .catch(e=>reject(e))
        }
    })
}
