import useProgress from "../../../components/progressBar/hooks/useProgress";
import {useEffect, useState} from "react";
import useUserDataState from "../../../hooks/state/useUserDataState";
import {getFullName} from "../../../utils/helper";
import {Input, VStack, HStack, Select, useToast} from "@chakra-ui/react";
import AadhaarCard from "../../../components/cards/aadhaarCard";
import {PrimaryButton} from "../../../components/buttons/PrimaryButton";
import {BiRefresh, BiShield} from "react-icons/bi";
import {AnimButtonContainer} from "../../../components/buttons/AnimButtonContainer";
import BankAccountTypes from "../../../utils/BankAccountTypes";
import {ConditionalRender} from "../../../components/dataSafe/ConditionalRender";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import {Loading} from "../../../components/loading/Loding";
import avatarM from "../../../raw/img/vectors/min_avatar.svg";
import {kycV2RoutePaths} from "../../../routes/kyc_v2/kycV2RoutePaths";
import {AiOutlineArrowRight} from "react-icons/ai";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import {DataSafeRender} from "../../../components/dataSafe/DataSafeRender";
import useAuthUserState from "../../../hooks/state/useAuthUserState";
import {useNavigate} from "react-router-dom";
import bankVerify from "../../../firebase/functions/bankVerify";
import {ErrorToast, SuccessToast} from "../../../utils/ToastBuilder";
import BankCard from "../../../components/cards/bankCard";
import gradientBG from "../../../raw/img/vectors/gradient.svg";

export function BankAccountKYCScreen({}){
    const [progress,_,__,setProgress] = useProgress()
    const [authUser, authToken, ___] = useAuthUserState()
    const [userData, referralData, refreshReferralData, loading] = useUserDataState()

    const nav = useNavigate()
    const toast = useToast()

    const [isBankVerified, setIsBankVerified] = useState(false)
    const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true)
    const [isBusy, setIsBusy] = useState(false)

    const [accountNumber, setAccountNumber] = useState("")
    const [confirmAccountNumber, setConfirmAccountNumber] = useState("")
    const [ifsc, setIFSC] = useState("")
    const [bankName, setBankName] = useState("")
    const [holderName, setHolderName] = useState("")
    const [accountType, setAccountType] = useState(BankAccountTypes.savings)

    const [dbAccountNumber, setDBAccountNumber] = useState("")
    const [dbIFSC, setDBIFSC] = useState("")
    const [dbBankName, setDBBankName] = useState("")
    const [dbHolderName, setDBHolderName] = useState("")
    const [dbAccountType, setDBAccountType] = useState("")

    const handelVerificationAction = _=>{
        setIsBusy(true)
        bankVerify(
            authToken,
            holderName.trim(),
            accountNumber.trim(),
            confirmAccountNumber.trim(),
            ifsc.trim(),
            accountType.trim(),
            bankName.trim())
            .then((result)=>{
                setIsBusy(false)
                if(result.verified === true){
                    toast(SuccessToast("Successful", "Bank account verified."))
                }else{
                    console.error("verification error", result)
                    if(result.result.reason.toLowerCase() === "success")
                        toast(ErrorToast("Failed", `Mismatch in name, please try entering the name associated with your account`))
                    else toast(ErrorToast("Failed", `${result.result.reason}`))
                }
        }).catch(e=>{
            setIsBusy(false)
            console.error("verification error", e)
            toast(ErrorToast("Failed", e.message))
        })
    }

    useEffect(_=>{
        setProgress((100 / 4) * 3)
        return _=>{
            setProgress(0)
        }
    },[])

    useEffect(_=>{
        if(userData !== null && userData !== undefined){
            setHolderName(getFullName(userData))
            setIsBankVerified(userData[firestorePaths.doc.user.bank_valid] === true)
            const bankDetails = userData[firestorePaths.doc.user.bank_details.path]
            if(bankDetails){
                if(bankDetails[firestorePaths.doc.user.bank_details.bank_account_no]){
                    setDBAccountNumber(bankDetails[firestorePaths.doc.user.bank_details.bank_account_no])}
                if(bankDetails[firestorePaths.doc.user.bank_details.bank_name]){
                    setDBBankName(bankDetails[firestorePaths.doc.user.bank_details.bank_name])}
                if(bankDetails[firestorePaths.doc.user.bank_details.bank_account_ifsc]){
                    setDBIFSC(bankDetails[firestorePaths.doc.user.bank_details.bank_account_ifsc])}
                if(bankDetails[firestorePaths.doc.user.bank_details.bank_account_type]){
                    setDBAccountType(bankDetails[firestorePaths.doc.user.bank_details.bank_account_type])}
                if(bankDetails[firestorePaths.doc.user.bank_details.bank_account_holder_name]){
                    setDBHolderName(bankDetails[firestorePaths.doc.user.bank_details.bank_account_holder_name])}
            }
        }
    },[userData])

    useEffect(_=>{
        setIsVerifyButtonDisabled(
            !(
                accountNumber === confirmAccountNumber &&
                accountNumber.length >= 5 &&
                ifsc.length >= 5 &&
                bankName.length > 0 &&
                holderName.length > 0 &&
                (
                    accountType === BankAccountTypes.savings ||
                    accountType === BankAccountTypes.current
                )
            )
        )
    },[accountNumber, confirmAccountNumber, ifsc, bankName, holderName, accountType])

    return(
        <div className={'w-full h-full p-4'}>
            <div className={'w-full text-left flex flex-col-reverse lg:flex-row'}>
                <div className={'w-full h-full lg:ml-24 lg:mt-0 mt-8'}>
                    <div className={'text-black/60 text-[1.2em] mb-8'}>Step 3 of 4</div>
                    <div className={'text-2xl font-semibold mb-4'}>Bank Account Verification</div>
                    <p className={'lg:mr-12 leading-7'}>
                        Share your address-related information from DigiLocker account for KYC.<br/>
                        Your Aadhaar number/copy will be stored for future reference.
                        The Aadhaar UID should belong to you, it is a 12 digit number available at the bottom of your Aadhaar card.
                    </p>
                    <ConditionalRender
                        condition={!isBankVerified}
                        alt={
                            <DataSafeRender values={[authUser, authToken]} alt={<Loading/>}>
                                <div className={'w-full h-full grid grid-cols-1 gap-16 place-content-between items-center'}>
                                    <div className={'border-[2px] border-black/50 p-4 mt-8 rounded-xl flex items-center content-center'}>
                                        <div className={'grid place-content-center p-4 rounded-lg'}>
                                            <img className={'rounded-xl'}
                                                 src={avatarM}
                                                 width={180} alt={"avatar"}/>
                                        </div>
                                        <div className={'w-full h-full'}>
                                            <HStack>
                                                <div>Acc holder name: </div>
                                                <div>{dbHolderName}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>Acc type: </div>
                                                <div>{dbAccountType}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>Acc number: </div>
                                                <div>{dbAccountNumber}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>IFSC code: </div>
                                                <div>{dbIFSC}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>Bank Name: </div>
                                                <div>{dbBankName}</div>
                                            </HStack>
                                        </div>
                                    </div>
                                    <AnimButtonContainer className={'w-full place-content-start'}>
                                        <PrimaryButton
                                            className={'py-[16px] px-[32px] shadow-xl'}
                                            onClick={_=>{
                                                nav(`../${kycV2RoutePaths.fill_details}`)
                                            }}>
                                            <HStack>
                                                <div>Next step</div>
                                                <AiOutlineArrowRight/>
                                            </HStack>
                                        </PrimaryButton>
                                    </AnimButtonContainer>
                                    <AnimButtonContainer className={'mt-8 place-content-start'}>
                                        <SecondaryButton className={"py-[8px] px-[4px]"}
                                                         onClick={_=>{setIsBankVerified(false)}}>
                                            <HStack>
                                                <div>Restart process</div>
                                                <BiRefresh/>
                                            </HStack>
                                        </SecondaryButton>
                                    </AnimButtonContainer>
                                </div>
                            </DataSafeRender>
                        }>
                        <div className={'mt-8 text-left lg:w-2/3'}>
                            <VStack mt={4}>
                                <div className={'w-full font-semibold'}>Account Holder Name <span className={'text-red-700'}>*</span></div>
                                <Input
                                    className={'text-lg'}
                                    borderColor={'black'}
                                    _placeholder={{ opacity: 0.7, color: 'black' }}
                                    varient={"outline"}
                                    value={holderName}
                                    type={"text"}
                                    mt={2}
                                    onChange={e=>{setHolderName(e.target.value)}}
                                    placeholder={"Holder name"}/>
                            </VStack>
                            <VStack mt={4}>
                                <div className={'w-full font-semibold'}>Account number <span className={'text-red-700'}>*</span></div>
                                <Input
                                    className={'text-lg'}
                                    borderColor={'black'}
                                    _placeholder={{ opacity: 0.7, color: 'black' }}
                                    varient={"outline"}
                                    value={accountNumber}
                                    type={"number"}
                                    mt={2}
                                    onChange={e=>{setAccountNumber(e.target.value.toUpperCase())}}
                                    placeholder={"Account number"}/>
                            </VStack>
                            <VStack mt={4}>
                                <div className={'w-full font-semibold'}>Confirm account number <span className={'text-red-700'}>*</span></div>
                                <Input
                                    className={'text-lg'}
                                    borderColor={'black'}
                                    _placeholder={{ opacity: 0.7, color: 'black' }}
                                    varient={"outline"}
                                    value={confirmAccountNumber}
                                    type={"number"}
                                    mt={2}
                                    onChange={e=>{setConfirmAccountNumber(e.target.value.toUpperCase())}}
                                    placeholder={"Confirm account number"}/>
                            </VStack>
                            <VStack mt={4}>
                                <div className={'w-full font-semibold'}>Bank Name <span className={'text-red-700'}>*</span></div>
                                <Input
                                    className={'text-lg'}
                                    borderColor={'black'}
                                    _placeholder={{ opacity: 0.7, color: 'black' }}
                                    varient={"outline"}
                                    value={bankName}
                                    type={"text"}
                                    mt={2}
                                    onChange={e=>{setBankName(e.target.value.toUpperCase())}}
                                    placeholder={"Bank Name"}/>
                            </VStack>
                            <VStack mt={4}>
                                <div className={'w-full font-semibold'}>Bank IFSC Code <span className={'text-red-700'}>*</span></div>
                                <Input
                                    className={'text-lg'}
                                    borderColor={'black'}
                                    _placeholder={{ opacity: 0.7, color: 'black' }}
                                    varient={"outline"}
                                    value={ifsc}
                                    type={"text"}
                                    mt={2}
                                    onChange={e=>{setIFSC(e.target.value.toUpperCase())}}
                                    placeholder={"Bank IFSC Code"}/>
                            </VStack>
                            <VStack mt={4}>
                                <div className={'w-full font-semibold'}>Account Type <span className={'text-red-700'}>*</span></div>
                                <Select className={''}
                                        borderColor={'black'}
                                        _placeholder={{ opacity: 0.7, color: 'black' }}
                                        placeholder='Select account type'
                                        value={accountType} onChange={e=>{setAccountType(e.target.value)}}>
                                    <option value={BankAccountTypes.savings}>Savings</option>
                                    <option value={BankAccountTypes.current}>Current</option>
                                </Select>
                            </VStack>
                        </div>
                        <AnimButtonContainer className={'mt-8 w-full place-content-start'}>
                            <PrimaryButton
                                className={'py-[16px] px-[32px] shadow-xl'}
                                isBusy={isBusy}
                                disabled={isVerifyButtonDisabled}
                                onClick={handelVerificationAction}>
                                <HStack>
                                    <span className={'text-lg'}><BiShield/></span>
                                    <div>Verify bank account</div>
                                </HStack>
                            </PrimaryButton>
                        </AnimButtonContainer>
                    </ConditionalRender>
                </div>
                <div className={'h-full w-full rounded-2xl lg:h-3/5 grid place-content-start justify-center p-4'}
                     style={{backgroundImage: `url(${gradientBG})`,
                         backgroundSize: "contain",
                         backgroundRepeat: "no-repeat",
                         backgroundPosition:"center"}}>
                    <BankCard
                        className={'mb-16 lg:mt-16'}
                        checking={isBusy}
                        verified={isBankVerified}
                        accountNumber={accountNumber}
                        bankName={bankName}
                        confirmAccountNumber={confirmAccountNumber}
                        name={holderName}
                        ifsc={ifsc}/>
                </div>
            </div>
        </div>
    )
}
