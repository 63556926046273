import {getAuthHeaderConfig} from "./HeaderConfig";
import axios from "axios";
import {Api, getUrl} from "../../api/Url";

/**
 *
 * @param accessToken
 * @param name
 * @param accountNumber
 * @param confirmAccountNumber
 * @param ifsc
 * @param accountType
 * @param bankName
 * @returns {Promise<unknown>}
 * @constructor
 */
const bankVerify = (accessToken,
                    name,
                    accountNumber,
                    confirmAccountNumber,
                    ifsc,
                    accountType,
                    bankName)=>{
    return new Promise((resolve, reject)=>{
        const config = getAuthHeaderConfig(accessToken)
        axios.post(getUrl(Api.bankVerify), {
            b_name: name,
            account_number: accountNumber,
            confirm_account_number: confirmAccountNumber,
            ifsc: ifsc,
            account_type: accountType,
            bank_name: bankName
        },config).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err.response.data)
        })
    })
}

export default bankVerify
