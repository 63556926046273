import {BiLock} from "react-icons/bi";
import KYCStepCard from "../../../components/kyc_v2";
import scribble from "../../../raw/img/vectors/scribble1.svg";
import scribble2 from "../../../raw/img/vectors/scribble2.svg";
import scribble3 from "../../../raw/img/vectors/scribble3.svg";
import gradientBG from "../../../raw/img/vectors/gradient.svg";
import LottiePlayer from "../../../components/animated/LottiePlayer";
import verifyLock from "../../../raw/anim/verifyLock.json";
import {AnimButtonContainer} from "../../../components/buttons/AnimButtonContainer";
import {PrimaryButton} from "../../../components/buttons/PrimaryButton";
import {useNavigate} from "react-router-dom";
import {kycV2RoutePaths} from "../../../routes/kyc_v2/kycV2RoutePaths";

export function KYCHome({}){
    const nav = useNavigate()

    return(
        <div className={'max-w-full h-full p-4'}>
            <div className={'w-full h-full'}
                 style={{backgroundImage: `url(${gradientBG})`,
                     backgroundSize: "contain",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition:"left"}}>
                <div className={'w-full text-left flex flex-col-reverse lg:flex-row'}>
                    <div className={"lg:ml-24 lg:mt-0 mt-8"}>
                        <div className={'text-black/60 text-[1.2em] mb-8'}> </div>
                        <div className={'text-2xl font-semibold mb-4'}>Complete Digital KYC</div>
                        <p className={'lg:mr-12 leading-7'}>
                            Onboarding has never been this easy. <br/>
                            To ensure a secure and complaint environment, we require some information from you.<br/>
                            KYC helps us verify the identity of our users. KYC is a regulatory requirement to prevent fraud and ensure a safe environment.
                        </p>
                        <AnimButtonContainer className={"w-fit py-4"}>
                            <PrimaryButton className={"p-5"} onClick={_=>{
                                nav(kycV2RoutePaths.aadhaar_kyc)
                            }}>
                                Continue with Digital KYC
                            </PrimaryButton>
                        </AnimButtonContainer>
                    </div>
                    <div className={'w-fit h-full'} style={{backgroundImage: `url(${gradientBG})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition:"left"}}>
                        <div  style={{opacity:0.8}}  className={'w-fit h-full grid place-content-center p-8'}>
                            <LottiePlayer options={{loop: true, autoPlay: true, animationData: verifyLock}} />
                        </div>
                    </div>
                </div>
                <div className={"lg:px-24 mt-8 w-full text-left"}>
                    <div className={'text-[1.2em] mt-16 lg:mt-4 font-semibold'}>
                        Information we collect &mdash;
                    </div>
                    <div className={'grid lg:grid-cols-3 grid-cols-1 gap-6 mt-8'}>
                        <KYCStepCard count={1} title={"Aadhaar(Digilocker)"} details={"Digitally verify Aadhaar using DigiLocker"} scribble={scribble}/>
                        <KYCStepCard count={2} title={"PAN Details"} details={"Automatic PAN verification"} scribble={scribble2}/>
                        <KYCStepCard count={3} title={"Bank Account"} details={"Back account verification"} scribble={scribble3}/>
                    </div>
                    <p className={'mt-16'}>
                        The information we store and handle strictly adhere to our
                        <a className={'text-red-700 font-semibold'} href={"https://tradecops.com/terms-and-conditions/"} target={"_blank"}> Privacy Policy</a>.
                        For more information about the data we collect visit our landing page or email us with your query.
                    </p>
                </div>
            </div>
        </div>
    )
}
