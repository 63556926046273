import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {KYCVerified} from "../../../components/kyc/KYCVerified";
import {CompleteKYC} from "../../../components/kyc/CompleteKYC";
import GetCurrentUserReferralTree from "../../../firebase/functions/GetCurrentUserReferralTree";
import {getAuth} from "firebase/auth";

export function KYCHome(props){
    const userData = useSelector(state=> {return state.userData})
    const authUser = useSelector(state=>{return state.authUser})
    const [kycVerified, setKYCVerified] = useState(false)
    useEffect(_=>{
        if(userData.data !== null)
            setKYCVerified(userData.data.kyc_verified)
    },[userData])

    return(
        <div>
            {(kycVerified)?<KYCVerified/>: <CompleteKYC/>}
        </div>
    )
}
