import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
import {firestorePaths} from "../firestorePaths";

export function GetReferralReturns(email){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        const depositQuery = query(collection(db, firestorePaths.collections.referral_returns),
            where(firestorePaths.doc.referral_return.owner, "==", email))
        getDocs(depositQuery).then(snapshot=>{
            const resultList = []
            snapshot.forEach(doc=>{
                if(doc.exists()){
                    let data = doc.data()
                    data.id = doc.id
                    data[firestorePaths.doc.referral_return.date] = data[firestorePaths.doc.referral_return.date].toDate?.().toISOString?.()
                    resultList.push(data)
                }
            })
            resultList.sort(
                (a,b)=>
                    new Date(b[firestorePaths.doc.referral_return.date]) -
                    new Date(a[firestorePaths.doc.referral_return.date]))
            resolve(resultList)
        }).catch(e=>reject(e))
    })
}
