import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import useAuthUserState from "./useAuthUserState";
import GetCurrentUserReferralTree from "../../firebase/functions/GetCurrentUserReferralTree";
import {updateReferralData} from "../../redux/reducer/userDataReducer";

/**
 * Returns current user [ data, referral data, refreshReferral function, loading flag ]
 * @returns {[Object, Object, ()=>{}, boolean]}
 */
function useUserDataState(){
    const userData = useSelector(state=>{return state.userData})
    const [, authToken, _] = useAuthUserState()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [referralData, setReferralData] = useState(null)

    const dispatch = useDispatch()

    const refreshReferral = ()=>{
        if(authToken){
            GetCurrentUserReferralTree(authToken).then(result=>{
                dispatch(updateReferralData(result.data))
            })
        }
    }

    useEffect(_=>{
        if(userData.isLoaded){
            if(userData.data){
                setData(userData.data)
                if(userData.referralData){
                    setReferralData(userData.referralData)
                }else{
                    if(userData.referralData === null){
                        refreshReferral()
                    }
                }
            }else{
                setData(null)
                setReferralData(null)
            }
            setLoading(false)
        }else{
            setData(null)
            setReferralData(null)
            setLoading(true)
        }
    },[userData])


    return([data, referralData, refreshReferral, loading])
}

export default useUserDataState
