import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {firestorePaths} from "../../../../firebase/database/firestorePaths";
import {Avatar, Box, Button, Image} from "@chakra-ui/react";
import QRCode from "react-qr-code";
import ReactToPrint, {useReactToPrint} from 'react-to-print';

export function AwardCeremony1(props){
    const userData = useSelector(state=>{return state.userData})
    const [userDetails, setUserDetails] = useState({})

    const [profilePic, setProfilePic] = useState("")
    const [userName, setUserName] = useState("")
    const [userEmail, setUserEmail] = useState("unknown")
    const ref = useRef()
    const trigRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });

    useEffect(_=>{
        if(userData !== null && userData !== undefined && userData.isLoaded)
            setUserDetails(userData.data)
    },[userData])

    useEffect(_=>{
        if(userDetails!== null && userDetails !== undefined){
            setProfilePic(userDetails[firestorePaths.doc.user.pic_url])
            const first = (
                userDetails[firestorePaths.doc.user.first_name] !== null &&
                userDetails[firestorePaths.doc.user.first_name] !== undefined)? userDetails[firestorePaths.doc.user.first_name]:""
            const middle = (
                userDetails[firestorePaths.doc.user.middle_name] !== null &&
                userDetails[firestorePaths.doc.user.middle_name] !== undefined)? userDetails[firestorePaths.doc.user.middle_name]:""
            const last = (
                userDetails[firestorePaths.doc.user.last_name] !== null &&
                userDetails[firestorePaths.doc.user.last_name] !== undefined)? userDetails[firestorePaths.doc.user.last_name]:""
            setUserName(`${first} ${middle} ${last}`)
            setUserEmail(userDetails[firestorePaths.doc.user.email])
        }
    },[userDetails])


    return(
        <div className={'w-full grid place-content-center static'}>
            <div className={'w-full h-2.5'}>
                <Button ref={trigRef} size={'lg'} colorScheme={'green'} onClick={handlePrint}>Print Invitation</Button>
            </div>
                <div ref={ref} className={'grid grid-flow-row min-w-[800px] max-w-[800px] bg-purple-100 text-center align-middle justify-center shadow-xl p-8 rounded-xl'}>
                    <div className={'text-6xl font-bold curly_font mt-4 text-purple-900'}>Invitation</div>
                    <div className={'text-4xl font-semibold rounded-md text-black curly_font mt-4'}> 1st Award Night </div>
                    <div className={'mt-2 text-center text-lg font-semibold font-mono'}>18th and 19th March, 2023</div>
                    <div className={'mt-4'}>
                        <Avatar
                            className={'shadow-lg'}
                            size={'xl'}
                            borderRadius={'xl'}
                            src={profilePic}
                            name={userName}/>
                    </div>
                    <div className={'mt-4 text-center text-2xl font-semibold'}>{userName}</div>
                    <div className={'text-3xl font-bold curly_font mt-8 text-purple-900'}>Events</div>
                    <div className={'grid grid-flow-col w-2/3 justify-self-center mt-2'}>
                        <div>
                            <div className={'mt-2 text-left text-lg font-semibold'}>18th March</div>
                            <div className={'mt-2 text-left text-md font-mono'}>
                                Social charity Cricket tournament<br/>(3pm to 6pm)
                            </div>
                        </div>
                        <div>
                            <div className={'mt-2 text-right text-lg font-semibold'}>19th March</div>
                            <div className={'mt-2 text-right text-md font-mono'}>
                                1st Award function<br/>(2pm to 8pm)
                            </div>
                        </div>
                    </div>
                    <div className={'text-3xl font-bold curly_font mt-4 text-purple-900'}>Venue</div>
                    <div className={'mt-2 text-center text-lg font-semibold'}>VOW RESORTS & SPA - CORBETT</div>
                    <div className={'mt-2 text-center text-lg'}>
                        Jim Corbett National Park, Belparao, Ramnagar, Uttarakhand 244715
                    </div>
                    <div className={'w-full text-3xl font-bold curly_font mt-8 text-purple-900'}>Member ID-Code</div>
                    <div className={'w-[800px] mt-4 grid place-content-center'}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={(userEmail !== null && userEmail !== undefined)? userEmail: "unknown"}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    <div className={'w-full mt-4 text-center text-lg font-semibold'}>{userEmail}</div>
                    <div className={'w-full mt-4 text-center text-md'}>{"* Please carry the card with you. This QR is mandatory to gain access to the event."}</div>
                </div>
        </div>
    )
}
