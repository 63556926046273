import {Center, CloseButton, Image, Input, useToast} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {ErrorToast} from "../../../utils/ToastBuilder";

function ImageSelector({imageValueChanger = null}){

    const toast = useToast()

    const [image, setImage] = useState(IMAGE_DEFAULT)

    const validateFileSize = (file)=>{
        const MAX_FILE_SIZE_KB = 2048
        const FILE_SIZE = file.size / 1024
        return (FILE_SIZE <= MAX_FILE_SIZE_KB)

    }
    const handleChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    }

    useEffect(_=>{
        if(image.raw !== "" && image.preview !== ""){
            if(validateFileSize(image.raw)) {
                if(imageValueChanger !== null)
                    imageValueChanger?.(image)
            }
            else{
                toast(ErrorToast("Media error", "File too big! Select another image."))
                if(imageValueChanger !== null)
                    imageValueChanger?.(IMAGE_DEFAULT)
            }
        }else{
            if(imageValueChanger !== null)
                imageValueChanger?.(IMAGE_DEFAULT)
        }
    },[image])

    return(
        <>
            {
                image.preview !== ""?
                    <div className={'grid w-full min-w-2/3 text-center'}>
                        <CloseButton className={'place-self-end'} size={'lg'}
                                     onClick={_=>{setImage({ preview: "", raw: "" })}}/>
                        <Center className={'w-full grid place-content-center p-4'}>
                            <Image w={'350px'} h={'240px'} className={'w-full md:w-1/2'} borderRadius={'2xl'}
                                   objectFit='contain'
                                   src={image.preview} alt={'preview_document'}/>
                        </Center>
                    </div>
                    :
                    <div>
                        <label htmlFor={'img-chooser'}>
                            <div className={'grid place-content-center text-black/60 border-black/60 border-dashed border-2 ' +
                                'p-4 pt-16 pb-16 rounded-2xl cursor-pointer hover:border-black hover:text-black'}>
                                <i className="fi fi-rr-picture text-[4.5rem] text-black/30"></i>
                                <div className={'text-center m-2 text-lg'}>
                                    Click to upload image ( .jpg )<br/>[ max size: 2 mb ]
                                </div>
                            </div>
                        </label>
                        <Input id={'img-chooser'} type={'file'} accept={'.jpg'}
                               style={{ display: "none" }} onChange={handleChange}/>
                    </div>
            }
        </>
    )
}

export default ImageSelector
export const IMAGE_DEFAULT = { preview: "", raw: "" }
