import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {GetDeposits} from "../../firebase/database/get/GetDeposits";
import useUserDataState from "./useUserDataState";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {useToast} from "@chakra-ui/react";
import {ErrorToast} from "../../utils/ToastBuilder";
import {setDepositList} from "../../redux/reducer/userDepositsReducer";

/**
 * Returns current user deposits list
 * @returns {[Object, ()=>{}, boolean]}
 */
function useDepositsState(){
    const depositsList = useSelector(state=>{return state.userDeposits})
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [userDetails, , , userDetailsLoading] = useUserDataState()

    const dispatch = useDispatch()
    const toast = useToast()

    const refresh = ()=> {
        if(userDetails && !userDetailsLoading){
            if(
                userDetails[firestorePaths.doc.user.email] !== null &&
                userDetails[firestorePaths.doc.user.email] !== undefined &&
                userDetails[firestorePaths.doc.user.email] !== ""
            )
                GetDeposits(userDetails[firestorePaths.doc.user.email])
                    .then(deposits =>{
                        dispatch(setDepositList(deposits))
                    })
                    .catch(e=>{
                        console.log(e)
                       /* toast(ErrorToast("Error", e.message))*/
                    })
        }
    }

    useEffect(_=>{
        if(depositsList.isLoaded){
            if(depositsList.data){
                setData(depositsList.data)
            }else{
                setData(null)
            }
            setLoading(false)
        }else{
            refresh()
            setLoading(true)
        }
    },[depositsList, userDetails])

    return([data, refresh, loading])
}

export default useDepositsState
