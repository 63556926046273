import {TextLogo} from "../brand/TextLogo";
import useAuthUserState from "../../hooks/state/useAuthUserState";
import {DataSafeRender} from "../dataSafe/DataSafeRender";
import LogoutButton from "../buttons/LogoutButton";
import ProgressBar from "../progressBar";
import {
    Avatar,
    HStack,
    Popover,
    PopoverArrow, PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger, Portal, VStack
} from "@chakra-ui/react";
import {BiLogOutCircle} from "react-icons/bi";
import {HiOutlineUserCircle} from "react-icons/hi";
import useUserDataState from "../../hooks/state/useUserDataState";
import {useEffect, useState} from "react";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {getFullName} from "../../utils/helper";
import {logout} from "../../firebase/auth/callbacks";
import {MdSupport} from "react-icons/md";
import {AlternateLogoutButton} from "../buttons/AlternateLogoutButton";
import {ConditionalRender} from "../dataSafe/ConditionalRender";


export function TopBar({showLogo= true}){
    const [authUser,_, __] = useAuthUserState()
    const [userData, referralData, refreshReferral, loading] = useUserDataState()

    const [profilePicture, setProfilePicture] = useState("")
    const [userName, setUserName] = useState("")
    const [userEmail, setUserEmail] = useState("")

    useEffect(_=>{
        if(!loading && userData){
            if(userData[firestorePaths.doc.user.pic_url] && userData[firestorePaths.doc.user.pic_url] !== "")
                setProfilePicture(userData[firestorePaths.doc.user.pic_url])
            else setProfilePicture("")
            setUserName(getFullName(userData))
        }
    },[userData, loading])

    useEffect(_=>{
        if(authUser !== null && authUser !== undefined){
            setUserEmail(authUser.email)
        }
    },[authUser])

    return(
        <div className={'min-w-full w-full'}>
            <div className={'min-w-full w-full flex place-content-between p-4'}>
                <ConditionalRender condition={showLogo} alt={<div className={'w-full'}/>}>
                    <TextLogo className={'lg:text-2xl text-xl'}/>
                </ConditionalRender>
                <DataSafeRender alt={<></>} values={[authUser]}>
                    <div style={{zIndex: "100", margin: "0 0"}}>
                        <div className={'flex flex-row gap-4 w-fit items-center'}>
                            <Popover size={'lg'} placement={"top-end"}>
                                <PopoverTrigger>
                                    <div className={'w-fit h-fit hover:bg-black/10 hover:cursor-pointer rounded-lg p-1'}>
                                        <MdSupport fontSize={'2.8em'} color={"#555555"}/>
                                    </div>
                                </PopoverTrigger>
                                <PopoverContent color={"black"}
                                                bg={"rgba(255,255,255,0.8)"}
                                                borderRadius={"12px"}
                                                className={"backdrop-blur-xl"}
                                                borderWidth={"0"}
                                                _hover={{
                                                    borderWidth: "0px",
                                                }}>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                        <div className={'flex gap-4 items-center mt-4'}>
                                            Support
                                        </div>
                                        <div className={'grid grid-cols-1 gap-4 mt-6 mb-2'}>
                                            Under development
                                        </div>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                            <Popover size={'lg'} placement={"top-end"}>
                                <PopoverTrigger>
                                    <div className={'w-fit h-fit hover:bg-black/10 hover:cursor-pointer rounded-lg p-1'}>
                                        <Avatar size={'sm'} src={profilePicture} title={userName} color={"gray"}/>
                                    </div>
                                </PopoverTrigger>
                                <PopoverContent color={"black"}
                                                bg={"rgba(255,255,255,0.8)"}
                                                borderRadius={"12px"}
                                                className={"backdrop-blur-xl"}
                                                borderWidth={"0"}
                                                _hover={{
                                                    borderWidth: "0px",
                                                }}>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                        <div className={'flex gap-4 items-center mt-4'}>
                                            <Avatar size={'lg'} src={profilePicture} title={userName} color={"gray"}/>
                                            <div className={'text-left w-[200px]'}>
                                                <div className={'font-semibold'}>{userName}</div>
                                                <div className={'text-xs text-black/60'}>{userEmail}</div>
                                            </div>
                                        </div>
                                        <div className={'grid grid-cols-1 gap-4 mt-6 mb-2'}>
                                            <AlternateLogoutButton className={'py-3'}/>
                                        </div>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </div>
                    </div>
                </DataSafeRender>
            </div>
            <ProgressBar/>
        </div>
    )
}
