import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
function useAuthUserState() {
    const currentAuthUser = useSelector(state =>{return state.authUser})
    const [authUser, setAuthUser] = useState(null)
    const [authToken, setAuthToken] = useState(null)

    useEffect(_=>{
       if(currentAuthUser.isLoaded){
           if(currentAuthUser.data){
               setAuthUser(currentAuthUser.data)
               setAuthToken(currentAuthUser.tokenID)
           } else {
               setAuthUser(null)
               setAuthToken(null)
           }
       } else {
           setAuthUser(null)
           setAuthToken(null)
       }
    },[currentAuthUser])

    return(
        [
            authUser,
            authToken,
            currentAuthUser,
        ]
    )
}

export default useAuthUserState
