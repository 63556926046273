import KYC_V2 from "./pages/kyc_v2";
import AadhaarKYCScreen from "./pages/kyc_v2/screens/AadhaarKYCScreen";
import grainy from "./raw/img/vectors/grainy.svg";
import {useContext} from "react";
import ProgressContext from "./components/progressBar/progressContext";
import ProgressBar from "./components/progressBar";
import {Button} from "@chakra-ui/react";
import {TextLogo} from "./components/brand/TextLogo";
export function TestPage(){
    const {progress,
        incrementProgress,
        decrementProgress,
        setProgress} = useContext(ProgressContext)

    return(
        <div className={'max-w-screen h-screen'} style={{
            backgroundImage: `url(${grainy})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition:"center"}}>
            <TextLogo className={'text-2xl p-4'}/>
            <AadhaarKYCScreen/>
        </div>
    )
}
