import {getAuthHeaderConfig} from "./HeaderConfig";
import axios from "axios";
import {Api, getUrl} from "../../api/Url";

const getCurrentUserReferralTree = (accessToken)=>{
    return new Promise((resolve, reject)=>{
        const config = getAuthHeaderConfig(accessToken)
        axios.post(getUrl(Api.getReferralTree), {},config).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err.response.data)
        })
    })
}

export default getCurrentUserReferralTree
