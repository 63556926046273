import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {storageFilePath} from "./storageFilePath";

export function uploadReceipt(email, tid, file){
    return(new Promise((resolve, reject)=>{
        if(email === null || email === undefined || file === null || file === undefined ) reject(Error("Insufficient data"))
        const storage = getStorage()
        const locationRef = ref(storage,`${storageFilePath.receipt}/${email}/${tid}_${Math.floor(Math.random() * 10e12)}.jpg`)
        if(file === null || file === undefined) reject(Error('File missing or undefined'))
        uploadBytes(locationRef, file).then(r=>{
            getDownloadURL(locationRef).then(link=>{
                resolve(link)
            }).catch(e=>{
                reject(e)
            })
        }).catch(e=>{
                reject(e)
        })
    }))
}
