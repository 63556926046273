export function refreshPage() {
    window.location.reload(false);
}

export function getCurrentDate(separator='-'){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}
export function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}
