import {kycRoutePaths} from "./kycRoutePaths"
import {KYCHome} from "../../pages/kyc/pages/KYCHome";
import {KYCDataCollector} from "../../pages/kyc/pages/KYCDataCollector";
import KYCCardID from "../../utils/KYCCardID";
import {KYCAddressCollector} from "../../pages/kyc/pages/KYCAddressCollector";
import {KYCBankCollector} from "../../pages/kyc/pages/KYCBankCollector";
export const kycRoutes = [
    getRoutesObject('/', <KYCHome/>),
    getRoutesObject(kycRoutePaths.address, <KYCAddressCollector/>),
    getRoutesObject(kycRoutePaths.address_proof, <KYCDataCollector id={KYCCardID.address_proof}/>),
    getRoutesObject(kycRoutePaths.pan, <KYCDataCollector id={KYCCardID.pan}/>),
    getRoutesObject(kycRoutePaths.id_proof, <KYCDataCollector id={KYCCardID.id_proof}/>),
    getRoutesObject(kycRoutePaths.picture, <KYCDataCollector id={KYCCardID.pic}/>),
    getRoutesObject(kycRoutePaths.bank_details, <KYCBankCollector/>),
    getRoutesObject(kycRoutePaths.check_book, <KYCDataCollector id={KYCCardID.cheque_book}/>),
    getRoutesObject(kycRoutePaths.signature, <KYCDataCollector id={KYCCardID.signature}/>),
]

/**
 * Generates route objects
 * @param route
 * @param element
 * @returns {{component, route: string}}
 */
export function getRoutesObject(route, element){
    return {route: `/${route}`, component: element}
}
