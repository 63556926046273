import {createSlice} from "@reduxjs/toolkit";
import {UserModel} from "../../firebase/models/UserModel";

const userDataSlicer = createSlice({
    name: 'userData',
    initialState: {
        isLoaded: false,
        data: null, //UserModel()
        referralData: null
    },
    reducers:{
        resetUserDataState: (state)=>{
            state.isLoaded = false
            state.data = null
            state.referralData = null
        },
        userDataLoaded: (state)=>{
            state.isLoaded = true
        },
        updateUserData: (state, action)=>{
            state.data = action.payload
            state.isLoaded = true
        },
        updateReferralData: (state, action)=>{
            state.referralData = action.payload
            state.isLoaded = true
        }
    },

})

export const {resetUserDataState, userDataLoaded, updateUserData, updateReferralData} = userDataSlicer.actions
export default userDataSlicer.reducer
