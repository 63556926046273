import {Center, useToast} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {GetDepositsById} from "../../../firebase/database/get/GetDeposits";
import {ErrorToast} from "../../../utils/ToastBuilder";
import {Loading} from "../../../components/loading/Loding";
import {DepositDetailCard} from "../../../components/deposit/DepositDetailCard";

export function DepositDetails(props){
    const {id} = useParams()
    const [data, setData] = useState(null)
    const [isError, setIsError] = useState(false)
    const [body ,setBody] = useState(<Loading/>)
    const toast = useToast()

    useEffect(_=>{
        if(data === null || data === undefined){
            GetDepositsById(id).then(docData=>{
                setData(docData)
            }).catch(e=>{
                setIsError(true)
                toast(ErrorToast('Error', e.message))
            })
        }
    },[id])

    useEffect(_=>{
        if(data !== null && data !== undefined){
            setBody(<DepositDetailCard data={data}/>)
        } else setBody(<Loading/>)
    },[data])

    useEffect(_=>{
        if(isError) setBody(<div>Something went wrong!</div>)
    },[isError])

    return(
        <div className={`lg:w-1/2 md:w-2/3 w-full m-auto justify-items-center grid`}>
                <div className={'bg-white h-fit p-4 border-2 rounded-lg m-8'}>
                    {body}
                </div>
        </div>
    )
}
