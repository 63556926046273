import Payment_Status from "../../utils/Payment_Status";
import DepositState from "../../utils/DepositState";
import {serverTimestamp } from "firebase/firestore";

export function DepositModel(id=null,
                             owner=null,
                             amount= 0,
                             type= null,
                             transaction_number = null,
                             receipt_url = null,
                             date= Date.now(),
                             status= Payment_Status.PENDING,
                             plan_state= DepositState.inactive, write_date = serverTimestamp()){
    return {
        id:id,
        owner:owner,
        amount:amount,
        type:type,
        transaction_number:transaction_number,
        receipt_url:receipt_url,
        date:date,
        status:status,
        plan_state:plan_state,
        write_date: write_date,
    }
}
