import {firestorePaths} from "../firebase/database/firestorePaths";
import {round} from "mathjs";

/**
 *
 * @param list
 * @returns {number}
 */
export const getTotalReferralBalance = (list) => {
    let balance = 0
    list.forEach(element=>{
        balance += parseFloat(element[firestorePaths.doc.referral_return.amount])
    })
    return parseFloat(balance.toFixed(2))
}
