import {useContext} from "react";
import ProgressContext from "../progressContext";

function useProgress(){
    const {progress,
        incrementProgress,
        decrementProgress,
        setProgress} = useContext(ProgressContext)

    return (
        [progress, incrementProgress, decrementProgress, setProgress]
    )
}

export default useProgress
