import {useEffect, useState} from "react";
import KYC_Status from "../../utils/KYC_Status";
import {Tag} from "@chakra-ui/react";
import Payment_Status from "../../utils/Payment_Status";

export function PaymentStatusChip(props){
    const status = props.status
    const className= props.className
    const [paymentStatus, setPaymentStatus] = useState({color: "yellow", text: "Incomplete"})

    useEffect(_=>{
        switch (status){
            case Payment_Status.COMPLETE: setPaymentStatus({color: "green", text: "Verified"}); break;
            case Payment_Status.PENDING: setPaymentStatus({color: "yellow", text: "Pending"}); break;
            case Payment_Status.REJECTED: setPaymentStatus({color: "red", text: "Rejected"}); break;
            default: setPaymentStatus({color: "red", text: "Unknown"}); break;
        }
    },[status])

    return(
        <div className={`${className}`}>
            <Tag className={''} variant={'solid'} colorScheme={paymentStatus.color}>{paymentStatus.text}</Tag>
        </div>
    )
}
