import {mainRoutePaths} from "./mainRoutePaths";
import {LoginPage} from "../pages/auth/LoginPage";
import {LoadingPage} from "../pages/ui/LoadingPage";
import {Dashboard} from "../pages/dashboard/Dashboard";
import {RegistrationPage} from "../pages/auth/RegistrationPage";
import {VerifyEmail} from "../pages/auth/VerifyEmail";
import {KYC} from "../pages/kyc/KYC";
import {ForgotPassword} from "../pages/auth/ForgotPassword";
import {RegistrationFees} from "../pages/dashboard/pages/RegistrationFees";
import KYC_V2 from "../pages/kyc_v2";
import {TestPage} from "../TestPage";

/**
 * @description This is a list of all the routes in the main menu
 * @todo Add new routes/pages here
 * @type {{component, route: string}[]}
 */
export const mainRoutes = [
    getRoutesObject('', <LoadingPage/>),
    getRoutesObject(mainRoutePaths.login, <LoginPage/>),
    getRoutesObject(`${mainRoutePaths.registration}`, <RegistrationPage/>),
    getRoutesObject(`${mainRoutePaths.registrationReferral}`, <RegistrationPage/>),
    getRoutesObject(`${mainRoutePaths.dashboard}/*`, <Dashboard/>),
    getRoutesObject(`${mainRoutePaths.verifyEmail}`, <VerifyEmail/>),
    getRoutesObject(`${mainRoutePaths.kyc}/*`, <KYC/>),
    getRoutesObject(`${mainRoutePaths.kyc_v2}/*`, <KYC_V2/>),
    getRoutesObject(`${mainRoutePaths.registration_fees}/*`, <RegistrationFees/>),
    getRoutesObject(`${mainRoutePaths.forgotPassword}/*`, <ForgotPassword/>),
    getRoutesObject(`${mainRoutePaths.test}/*`, <TestPage/>),
]

/**
 * Generates route objects
 * @param route
 * @param element
 * @returns {{component, route: string}}
 */
export function getRoutesObject(route, element){
    return {route: `/${route}`, component: element}
}
