import './App.css';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {mainRoutes} from "./routes/mainRoutes";
import {mainRoutePaths} from "./routes/mainRoutePaths";
import {useEffect, useState} from "react";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {useDispatch, useSelector} from "react-redux";
import {updateTokenID, updateUser} from "./redux/reducer/authUserReducer";
import {getFirestore, doc, onSnapshot} from "firebase/firestore";
import {firestorePaths} from "./firebase/database/firestorePaths";
import {updateReferralData, updateUserData} from "./redux/reducer/userDataReducer";
import {UserModel} from "./firebase/models/UserModel";
import GetCurrentUserReferralTree from "./firebase/functions/GetCurrentUserReferralTree";
import {TextLogo} from "./components/brand/TextLogo";
import Footer from "./components/footer";
import grainy from "./raw/img/vectors/grainy.svg"
import {TopBar} from "./components/nav/TopBar";

function App() {
    const nav = useNavigate()
    const auth = getAuth()
    const dispatch = useDispatch()
    const currentUserAuthState = useSelector(state => {return state.authUser})
    const userDetails = useSelector(state => {return state.userData})
    const database = getFirestore();
    const location = useLocation()

    const [isUserDataSubscribed, setIsUserDataSubscribed] = useState(false)

    onAuthStateChanged(auth, user=>{
        if(user){
            if(!currentUserAuthState.isLoaded || currentUserAuthState.data === null) {
                dispatch(updateUser(user))
                user.getIdToken().then(tk=>{
                    dispatch(updateTokenID(tk))
                })
                if(!isUserDataSubscribed) {
                    onSnapshot(doc(database, firestorePaths.collections.users, user.email), (doc) => {
                        if(doc.data())
                        dispatch(updateUserData(doc.data()))
                        else dispatch(updateUserData(UserModel()))
                    })
                    setIsUserDataSubscribed(true)
                }
            }
        }else{
            if(!currentUserAuthState.isLoaded){
                dispatch(updateUser(null))
                dispatch(updateTokenID(null))
                dispatch(updateUserData(null))
                if(location.pathname.split('/')[1] !== mainRoutePaths.registration)
                    nav('/')
            }
        }
    })

    useEffect( _ => {
        if (currentUserAuthState.isLoaded) {
            if (currentUserAuthState.data === null) {
                if (location.pathname.split('/')[1] !== mainRoutePaths.registration) {
                    nav(mainRoutePaths.login)
                }
            }
        } else {
            if (location.pathname.split('/')[1] !== mainRoutePaths.registration)
                nav('/')
        }
    },[currentUserAuthState])

    useEffect(_=>{
        if(currentUserAuthState.isLoaded){
            if(currentUserAuthState.tokenID){
                if(userDetails){
                    if(userDetails.referralData === null){
                        GetCurrentUserReferralTree(currentUserAuthState.tokenID)
                            .then(result=> {
                                dispatch(updateReferralData(result.data))
                            })
                            .catch(e=>console.error(e))
                    }
                }
            }
        }
    }, [currentUserAuthState])

    return (
    <div className="App" style={{maxWidth: "100vw", }}>
        <div className={'text-xs min-h-screen'}>
            <Routes>
                {
                    mainRoutes.map((route, index) =>{
                        return <Route key={index} exact path={route.route} element={route.component}/>
                    })
                }
            </Routes>
        </div>
        <Footer/>
    </div>
  );
}

export default App;
