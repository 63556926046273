import {logout} from "../../firebase/auth/callbacks";
import {BiLogOutCircle} from "react-icons/bi";

export function AlternateLogoutButton({className}){
    return(
        <div className={`${className} flex gap-2 justify-center content-center align-middle items-center hover:bg-red-500 hover:text-white text-red-600 hover:cursor-pointer text-center w-full p-2 rounded-lg`}
             onClick={logout}>
            <BiLogOutCircle/>
            Logout
        </div>
    )
}