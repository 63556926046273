import {getAuth, createUserWithEmailAndPassword, updateProfile} from "firebase/auth";
import {createUserRecord} from "../functions/CreateUserRecord";
import {ErrorToast} from "../../utils/ToastBuilder";

export function firebaseCreateAccountWithEmail(email, password, user){
    const auth = getAuth()
    return new Promise((resolve, reject)=>{
        createUserWithEmailAndPassword(auth, email, password).then(userCreds=>{
            updateProfile(userCreds.user,
                {displayName: `${user.first_name} ${user.middle_name} ${user.last_name}`})
                .then(_=>{
                    userCreds.user.getIdToken().then(token=>{
                        createUserRecord(user,token).then(_=>{
                            resolve(userCreds.user)
                        }).catch(err => reject(err))
                    }).catch(err=> reject(err))
                }).catch(err => reject(err))
        }).catch(err => reject(err))
    })
}
