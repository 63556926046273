export const Api = {
    baseUrl: 'https://us-central1-trade-cops-india.cloudfunctions.net/api',
    registerUser: '/register-user',
    getReferralTree: '/get-referral-tree',
    digilockerCreateUrl: '/digilocker/create-url',
    panKYC: '/panKYC',
    bankVerify: '/bank-verification',
    confirmDetails: '/confirm-details'
}

/**
 * https://us-central1-trade-cops-india.cloudfunctions.net/api
 * http://127.0.0.1:5001/trade-cops-india/us-central1/api
 */

/**
 *
 * @param path
 * @returns {string}
 */
export function getUrl(path){
    return `${Api.baseUrl}${path}`
}
