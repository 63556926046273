 import {useToast} from "@chakra-ui/react";
import {NavBar} from "../../components/nav/NavBar";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {dashboardRoutes} from "../../routes/dashboard/dashboardRoutes";
import MediaQuery from "react-responsive";
import NavDrawer from "../../components/nav/NavDrawer";
import {TopBar} from "../../components/nav/TopBar";
export function Dashboard(props){
    const authUser = useSelector(state => {return state.authUser})
    const userData = useSelector(state=>{return state.userData})
    const [userDetails, setUserDetails] = useState(null)
    const toast = useToast()
    const nav = useNavigate()

    useEffect(_=>{
        if(authUser.data === null){
            nav('/')
        }else{
            if(userData.isLoaded && userData.data !== null){
                setUserDetails(userData.data)
            }
        }
        },[authUser, userData, nav, toast])

    return(
        <div className={'max-w-full w-full h-fit'}>
            <MediaQuery minWidth={769}>
                <div className={'max-w-full w-full flex items-stretch'}>
                    <NavBar userDetails={userDetails} user={authUser} className={`flex-1`}/>
                    <div className={'max-w-full w-full'}>
                        <TopBar showLogo={false}/>
                        <Routes>
                            {dashboardRoutes.map((route,index)=>{
                                return <Route key={index} exact path={route.route} element={route.component}/>
                            })}
                        </Routes>
                        {/* */}
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={769}>
                <div className={'max-w-full w-full h-fit'}>
                    <NavDrawer userDetails={userDetails} user={authUser}/>
                    <div className={'max-w-full w-full min-h-screen p-2'}>
                        <Routes>
                            {dashboardRoutes.map((route,index)=>{
                                return <Route key={index} exact path={route.route} element={route.component}/>
                            })}
                        </Routes>
                        {/* */}
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}
