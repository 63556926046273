import {Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast, VStack} from "@chakra-ui/react";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import {PaymentStatusChip} from "../../../components/status/PaymentStatusChip";
import {dashboardRoutePaths} from "../../../routes/dashboard/dashboardRoutePaths";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {GetReferralReturns} from "../../../firebase/database/get/GetReferralReturns";
import {setReferralReturnsList} from "../../../redux/reducer/userReferralReturnsReducer";
import {ErrorToast, SuccessToast} from "../../../utils/ToastBuilder";
import {calculateNewValue} from "@testing-library/user-event/dist/utils";
import Payment_Status from "../../../utils/Payment_Status";
import Referral_Returns_Status from "../../../utils/Referral_Returns_Status";
import {ReferralReturnStatusChip} from "../../../components/status/ReferralReturnStatusChip";
import {IoIosRefresh} from "react-icons/io";
import {sendWithDrawlRequest} from "../../../firebase/database/update/UpdateReferralReturns";
import {useNavigate} from "react-router-dom";
import {round} from "mathjs";
import {getTotalReferralBalance} from "../../../helper/getTotalReferralBalance";
import {getAvailableBalance} from "../../../helper/getAvailableBalance";
import {format} from "date-fns";

export function ReferralReturns(props){
    const userData = useSelector(state => {return state.userData})
    const [userDetails, setUserDetails] = useState(null)
    const userReferralReturns = useSelector(state => {return state.userReferralReturns})

    const [tableList, setTableList] = useState([])
    const [availableBalance, setAvailableBalance] = useState(0)
    const [totalBalance, setTotalBalance] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()
    const toast = useToast()
    const nav = useNavigate()

    const refreshData = ()=>{
        if(userDetails !== null && userDetails !== undefined){
            if(userReferralReturns !== null && userReferralReturns !== undefined){
                setIsLoading(true)
                    GetReferralReturns(userDetails[firestorePaths.doc.user.email]).then(list=>{
                        dispatch(setReferralReturnsList(list))
                        setIsLoading(false)
                    }).catch(e =>{
                        toast(ErrorToast('Error', e.message))
                        setIsLoading(false)
                    })
            }
        }
    }

    useEffect(_=>{
        if(userData !== undefined && userData !== null){
            if(userData.data !== undefined && userData.data !== null){
                setUserDetails(userData.data)
            }
        }
    },[userData])

    useEffect(_=>{
        if(userDetails !== null && userDetails !== undefined){
            if(userReferralReturns !== null && userReferralReturns !== undefined){
                if(userReferralReturns.data.length === 0){
                    setIsLoading(true)
                    GetReferralReturns(userDetails[firestorePaths.doc.user.email]).then(list=>{
                        dispatch(setReferralReturnsList(list))
                        setIsLoading(false)
                    }).catch(e =>{
                        toast(ErrorToast('Error', e.message))
                        setIsLoading(false)
                    })
                }
            }
        }
    },[userDetails])

    useEffect(_=>{
        if(userReferralReturns !== null && userReferralReturns !== undefined){
            if(userReferralReturns.data !== null && userReferralReturns.data !== undefined){
                setAvailableBalance(getAvailableBalance(userReferralReturns.data))
                setTotalBalance(getTotalReferralBalance(userReferralReturns.data))
                setTableList(userReferralReturns.data)
            }
        }
    },[userReferralReturns])

    return (
        <div className={'w-full h-full'}>
            <div className={'w-full grid md:grid-flow-col grid-flow-row justify-between content-center mb-8'}>
                <div className={'w-full text-left p-4'}>
                    <div className={'md:text-3xl text-2xl font-semibold w-full'}>Referral Returns</div>
                    <div className={'text-lg mt-2'}>Get all the history of your referral returns here</div>
                </div>
                <div className={'h-full w-full grid place-content-center p-4'}>
                    <Button leftIcon={<IoIosRefresh/>} onClick={refreshData} isLoading={isLoading}>Refresh data</Button>
                </div>
            </div>
            <div className={'w-full flex md:flex-row flex-col mb-8'}>
                <div className={'md:w-fit w-full text-left p-4'}>
                    <div className={'w-full bg-orange-400 text-white shadow-orange-400 shadow-xl p-4 rounded-xl'}>
                        <div className={'text-lg'}>Available balance</div>
                        <div className={'text-4xl font-bold'}>₹{availableBalance}</div>
                    </div>
                </div>
                <div className={'md:w-fit w-full text-left p-4'}>
                    <div className={'w-full bg-red-400 text-white shadow-red-400 shadow-xl p-4 rounded-xl'}>
                        <div className={'text-lg'}>Total returns</div>
                        <div className={'text-4xl font-bold'}>₹{totalBalance}</div>
                    </div>
                </div>
            </div>
            <div className={'mt-4'}>
                <TableContainer>
                    <Table  size={'sm'}>
                        <Thead>
                            <Tr>
                                <Th>Date</Th>
                                <Th>Amount</Th>
                                <Th>From</Th>
                                <Th>Deposit Owner</Th>
                                <Th>Deposit ID</Th>
                                <Th>Status</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                tableList.map((data, index)=>{
                                    return (<Tr key={index} className={''}>
                                            <Td>{format(new Date(data[firestorePaths.doc.referral_return.date]), "dd/MM/yyyy")}</Td>
                                            <Td className={'font-bold'}>₹ {data[firestorePaths.doc.referral_return.amount]}</Td>
                                            <Td>{data[firestorePaths.doc.referral_return.from]}</Td>
                                            <Td>{data[firestorePaths.doc.referral_return.deposit_owner]}</Td>
                                            <Td><Button
                                                className={'w-full'}
                                                onClick={_=>{
                                                    nav(`../${dashboardRoutePaths.deposit}/${data[firestorePaths.doc.referral_return.deposit_id]}`)
                                                }}
                                                size={'sm'}>
                                                {data[firestorePaths.doc.referral_return.deposit_id]}
                                            </Button></Td>
                                            <Td><ReferralReturnStatusChip status={data[firestorePaths.doc.referral_return.status]}/></Td>
                                            <Td size={'sm'}>
                                                <VStack>
                                                    {/*<Button
                                                                className={'w-full'}
                                                                size={'sm'}
                                                                isDisabled={
                                                                    (data[firestorePaths.doc.deposit.status] !== Payment_Status.COMPLETE)}
                                                                colorScheme={'green'}>
                                                                Show Earnings
                                                            </Button>*/}
                                                    <Button
                                                        className={'w-fit'}
                                                        colorScheme={'red'}
                                                        disabled={(data[firestorePaths.doc.referral_return.status]!== Referral_Returns_Status.IN_WALLET)}
                                                        onClick={_=>{
                                                            sendWithDrawlRequest(data.id).then(_=>{
                                                                toast(SuccessToast("Successful", "Successfully requested for withdrawal"))
                                                                refreshData()
                                                            }).catch(e=>{
                                                                toast(ErrorToast("Failed", e.message))
                                                            })
                                                        }}
                                                        size={'sm'}>
                                                        Request withdrawal
                                                    </Button>
                                                </VStack>
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
