import {KYCHome} from "../../pages/kyc_v2/screens/KYCHome";
import {kycV2RoutePaths} from "./kycV2RoutePaths";
import AadhaarKYCScreen from "../../pages/kyc_v2/screens/AadhaarKYCScreen";
import PANKYCScreen from "../../pages/kyc_v2/screens/PANKYCScreen";
import {BankAccountKYCScreen} from "../../pages/kyc_v2/screens/BankAccountKYCScreen";
import FillDetailsScreen from "../../pages/kyc_v2/screens/FillDetailsScreen";

export const kycV2Routes = [
    getRoutesObject("/", <KYCHome/>),
    getRoutesObject(kycV2RoutePaths.aadhaar_kyc, <AadhaarKYCScreen/>),
    getRoutesObject(kycV2RoutePaths.pan_kyc, <PANKYCScreen/>),
    getRoutesObject(kycV2RoutePaths.bank_account, <BankAccountKYCScreen/>),
    getRoutesObject(kycV2RoutePaths.fill_details, <FillDetailsScreen/>),
]

export function getRoutesObject(route, element){
    return {route: `/${route}`, component: element}
}
