import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
import {firestorePaths} from "../firestorePaths";
import {compareDesc} from "date-fns";

export function GetSalary(email){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        const depositQuery = query(collection(db, firestorePaths.collections.salary),
            where(firestorePaths.doc.referral_return.owner, "==", email))
        getDocs(depositQuery).then(snapshot=>{
            const resultList = []
            snapshot.forEach(doc=>{
                if(doc.exists()){
                        const data = {
                            ...doc.data(),
                            [firestorePaths.doc.salary.write_date]: doc.data()[firestorePaths.doc.salary.write_date]?.toDate()?.toISOString(),
                            [firestorePaths.doc.salary.start_date]: doc.data()[firestorePaths.doc.salary.start_date]?.toDate()?.toISOString(),
                            [firestorePaths.doc.salary.end_date]: doc.data()[firestorePaths.doc.salary.end_date]?.toDate()?.toISOString()
                        }
                        if(data[firestorePaths.doc.salary.credit_line.path]){
                            data[firestorePaths.doc.salary.credit_line.path] = data[firestorePaths.doc.salary.credit_line.path].map(it=>(
                                {
                                    ...it,
                                    [firestorePaths.doc.salary.credit_line.date]: it[firestorePaths.doc.salary.credit_line.date]?.toDate()?.toISOString(),
                                    [firestorePaths.doc.salary.credit_line.last_updated]: it[firestorePaths.doc.salary.credit_line.last_updated]?.toDate()?.toISOString(),
                                }
                            ))
                        }
                    resultList.push(data)
                }
            })
            resultList.sort((a, b) => compareDesc(
                new Date(a[firestorePaths.doc.deposit.write_date]),
                new Date(b[firestorePaths.doc.deposit.write_date])))
            resolve(resultList)
        }).catch(e=>reject(e))
    })
}
