import {AspectRatio, Box, Button, Center, CloseButton, Image, Input, useToast} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import KYCCardID from "../../../utils/KYCCardID";
import {MdCloudUpload} from "react-icons/md";
import {CloseIcon} from "@chakra-ui/icons";
import {ErrorToast, SuccessToast} from "../../../utils/ToastBuilder";
import {useSelector} from "react-redux";
import {uploadKYC} from "../../../firebase/storage/uploadKYC";
import {UserModel} from "../../../firebase/models/UserModel";
import {useNavigate} from "react-router-dom";
import {mainRoutePaths} from "../../../routes/mainRoutePaths";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import KYC_Status from "../../../utils/KYC_Status";
import {getAuth} from "firebase/auth";

export function KYCDataCollector(props){
    const collectorID = props.id

    const userData = useSelector(state=>{return state.userData})

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [uploadEnable, setUploadEnable] = useState(false)
    const [userDetails, setUserDetails] = useState(UserModel())
    const [collectorPropertyKey, setCollectorPropertyKey] = useState(null)
    const [isBusy, setIsBusy] = useState(false)

    const toast = useToast()
    const nav = useNavigate()

    const handleChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    }

    const handleUpload = _=>{
        setIsBusy(true)
        if(image.raw !== null && image.raw !== undefined && image.raw !== ""){
            if(userDetails.email !== null){
                uploadKYC(collectorID, userDetails.email, image.raw).then(_=>{
                    setIsBusy(false)
                    toast(SuccessToast('Upload Successful', 'The filed has been uploaded to the servers'))
                    nav(`/${mainRoutePaths.kyc}`)
                }).catch(e=>{
                    setIsBusy(false)
                    toast(ErrorToast('Failed to upload', e.message))
                })
            }
        }
    }

    const validateFileSize = (file)=>{
        const MAX_FILE_SIZE_KB = 2048
        const FILE_SIZE = file.size / 1024
        return (FILE_SIZE <= MAX_FILE_SIZE_KB)

    }

    useEffect(_=>{
        setUserDetails(userData.data)
    },[userData])

    useEffect(_=>{
        if(collectorPropertyKey !== null &&
            userDetails[collectorPropertyKey] !== null &&
            userDetails[collectorPropertyKey] !== undefined) {
            setImage({...image, preview: userDetails[collectorPropertyKey]})
        }
    },[collectorPropertyKey])


    useEffect(_=>{
        if(image.raw !== null && image.raw !== undefined && image.raw !== ""){
            if(!validateFileSize(image.raw)){
                toast(ErrorToast('File too big', 'File size should not be more than 2mb'))
            }
            setUploadEnable(
                validateFileSize(image.raw) &&
                userDetails[firestorePaths.doc.user.email] !== null &&
                userDetails[firestorePaths.doc.user.kyc_status] !== KYC_Status.PENDING &&
                userDetails[firestorePaths.doc.user.kyc_status] !== KYC_Status.COMPLETE)
        }else setUploadEnable(false)
    }, [image, userDetails])

    useEffect(_=>{
        switch (collectorID){
            case KYCCardID.id_proof:{
                setTitle("Upload ID Proof")
                setCollectorPropertyKey(firestorePaths.doc.user.id_proof_url)
                setDescription("Please provide a clear image of your Aadhar card / Passport / Voter ID / Driving License")
                break
            }
            case KYCCardID.address_proof:{
                setTitle("Upload Address Proof")
                setCollectorPropertyKey(firestorePaths.doc.user.address_proof_url)
                setDescription("Please provide a clear image of your Address proof document")
                break
            }
            case KYCCardID.pan:{
                setTitle("Upload PAN Card")
                setCollectorPropertyKey(firestorePaths.doc.user.pan_url)
                setDescription("Please provide a clear image of your PAN Card")
                break
            }
            case KYCCardID.pic:{
                setTitle("Upload your photo")
                setCollectorPropertyKey(firestorePaths.doc.user.pic_url)
                setDescription("Please provide a clear and recent photograph")
                break
            }
            case KYCCardID.cheque_book:{
                setTitle("Upload bank cheque book")
                setCollectorPropertyKey(firestorePaths.doc.user.cheque_book_url)
                setDescription("Please provide a clear picture of your bank cheque book")
                break
            }
            case KYCCardID.signature:{
                setTitle("Upload signature")
                setCollectorPropertyKey(firestorePaths.doc.user.signature_url)
                setDescription("Please provide a clear picture of your signature")
                break
            }
            default: {
                setTitle("Error")
                setDescription("Unknown error")
                break
            }
        }
    }, [collectorID])

    return(
        <div className={'min-w-screen min-h-screen grid place-content-center items-center align-middle bg-slate-50 md:p-16'}>
            <div className={'min-w-full md:min-h-fit md:max-w-2/3 md:w-2/3 md:min-w-2/3 bg-white p-8 rounded-lg shadow-2xl'}>
                <div className={'text-3xl text-left font-bold'}>
                    {title}
                </div>
                <div className={'mt-2 mb-16 text-left mr-16 p-2 bg-slate-50 rounded-lg font-semibold'}>
                    {description}
                </div>
                {
                    image.preview !== ""?
                        <div className={'grid w-full min-w-2/3'}>
                            <CloseButton className={'place-self-end'} size={'lg'}
                                         onClick={_=>{setImage({ preview: "", raw: "" })}}/>
                            <Center className={'grid place-content-center p-4'}>
                                    <Image w={'350px'} h={'240px'} className={'w-full md:w-1/2'} borderRadius={'2xl'}
                                           objectFit='contain'
                                           src={image.preview} alt={'preview_document'}/>
                            </Center>
                        </div>
                        :
                        <div>
                            <label htmlFor={'img-chooser'}>
                                <div className={'grid place-content-center text-slate-500 border-slate-300 border-dashed border-4 ' +
                                    'p-4 pt-16 pb-16 rounded-2xl cursor-pointer hover:border-emerald-300 hover:text-emerald-500'}>
                                    <i className="fi fi-rr-picture text-[4.5rem] text-slate-300"></i>
                                    <div className={'text-center m-2 text-lg'}>Click to upload image ( .jpg )<br/>[ max size: 2mb ] </div>
                                </div>
                        </label>
                            <Input id={'img-chooser'} type={'file'} accept={'.jpg'}
                                   style={{ display: "none" }} onChange={handleChange}/>
                        </div>
                }
                <div className={'m-4 mt-8'}>
                    <Button  disabled={!uploadEnable}
                             colorScheme={'green'}
                             isLoading={isBusy}
                             leftIcon={<MdCloudUpload/>}
                             onClick={handleUpload}>Upload</Button>
                </div>
                <div className={'mt-4'}>
                    <Button className={'w-full'} onClick={_=>{nav(-1)}}>Cancel</Button>
                </div>
            </div>
        </div>
    )
}
