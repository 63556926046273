import {Badge, Button, Divider, HStack, Stack, Tag, useToast, VStack} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import KYC_Status from "../../utils/KYC_Status";
import {KYCOptionCard} from "./KYCOptionCard";
import {logout} from "../../firebase/auth/callbacks";
import {kycRoutePaths} from "../../routes/kyc/kycRoutePaths";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {ErrorToast, SuccessToast} from "../../utils/ToastBuilder";
import {submitKYC} from "../../firebase/database/update/KycUpdates";
import {useNavigate} from "react-router-dom";
import {KYCStatusChip} from "./KYCStatusChip";

export function CompleteKYC(props){
    const userData = useSelector(state=>{return state.userData})
    const [userDetails, setUserDetails] = useState(userData.data)
    const [isReadyToSubmit ,setIsReadyToSubmit] = useState(false)
    const [email, setEmail] = useState('')
    const [isBusy, setIsBusy] = useState(false)
    const [kycState, setKycState] = useState(KYC_Status.INCOMPLETE)

    const toast = useToast()

    const handleSubmit = _=>{
        if(isReadyToSubmit){
            if(email !== '') {
                setIsBusy(true)
                submitKYC(email).then(_=>{
                    setIsBusy(false)
                    toast(SuccessToast('Submitted for review', 'Your KYC application reached us.'))
                }).catch(e=>{
                    setIsBusy(false)
                    toast(ErrorToast('Failed to submit', e.message))
                })
            }else{
                toast(ErrorToast('User missing', "Can't determine user identifier"))
            }
        }else{
            toast(ErrorToast('Missing information', 'Please complete all the steps before submitting'))
        }
    }

    useEffect(_=>{
        setUserDetails(userData.data)
    },[userData])

    useEffect(_=>{
        if(userDetails !== null){
            setEmail(userDetails[firestorePaths.doc.user.email])
            setKycState(userDetails[firestorePaths.doc.user.kyc_status])
        }
    },[userDetails])

    useEffect(_=>{
        if(userDetails[firestorePaths.doc.user.id_proof_url] !== null &&
            userDetails[firestorePaths.doc.user.id_proof_url] !== undefined )
            if(userDetails[firestorePaths.doc.user.address_proof_url] !== null &&
                userDetails[firestorePaths.doc.user.address_proof_url] !== undefined )
                if(userDetails[firestorePaths.doc.user.address] !== null &&
                    userDetails[firestorePaths.doc.user.address] !== undefined )
                    if(userDetails[firestorePaths.doc.user.pan_url] !== null &&
                        userDetails[firestorePaths.doc.user.pan_url] !== undefined )
                        if(userDetails[firestorePaths.doc.user.signature_url] !== null &&
                            userDetails[firestorePaths.doc.user.signature_url] !== undefined )
                            if(userDetails[firestorePaths.doc.user.pic_url] !== null &&
                                userDetails[firestorePaths.doc.user.pic_url] !== undefined )
                                if(userDetails[firestorePaths.doc.user.cheque_book_url] !== null &&
                                    userDetails[firestorePaths.doc.user.cheque_book_url] !== undefined )
                                    if(kycState !== KYC_Status.PENDING && kycState !== KYC_Status.COMPLETE)
                                        setIsReadyToSubmit(true)
                                    else
                                        setIsReadyToSubmit(false)
                                else
                                    setIsReadyToSubmit(false)
                            else
                                setIsReadyToSubmit(false)
                        else
                            setIsReadyToSubmit(false)
                    else
                        setIsReadyToSubmit(false)
                else
                    setIsReadyToSubmit(false)
            else
                setIsReadyToSubmit(false)
        else
            setIsReadyToSubmit(false)
    }, [kycState, userDetails])

    try{
        return(
            <div className={'grid place-content-center bg-zinc-50 p-auto min-w-screen min-h-screen'}>
                <div className={'m-4 w-full text-center p-auto'}>
                    <i className={'fi fi-rr-mode-portrait text-4xl'}/>
                    <div className={'text-2xl mb-2'}>Complete KYC</div>
                    <div className={'mb-4'}>You need to complete the KYC process before you can start investment</div>
                    <div className={'grid gap-4 bg-white rounded-xl shadow-lg p-4'}>
                        <div className={'text-center'}>
                            <div className={'font-semibold text-lg'}>Current Status</div>
                            <KYCStatusChip status={kycState}/>
                        </div>
                        <Divider/>
                        <div className={'text-center'}>
                            <div className={'text-lg font-semibold'}>Administrator's Remark</div>
                            {
                                (userDetails[firestorePaths.doc.user.kyc_comment] === null ||
                                    userDetails[firestorePaths.doc.user.kyc_comment] === undefined ||
                                    userDetails[firestorePaths.doc.user.kyc_comment] === '')?
                                    <div>No remark</div>
                                    :

                                    <Tag size={'lg'} colorScheme={'purple'}>
                                        <span className={'ml-4 font-mono font-bold'}>{userDetails[firestorePaths.doc.user.kyc_comment]}</span>
                                    </Tag>
                            }
                        </div>
                    </div>
                    <div className={'mt-8 text-lg font-bold'}>
                        Required Details
                    </div>
                    <div className={'min-w-full mt-2'}>
                        <VStack className={'min-w-full gap-2'}>
                            <KYCOptionCard title={'ID Proof'}
                                           value={userDetails.id_proof_url}
                                           redirect={kycRoutePaths.id_proof}/>
                            <KYCOptionCard title={'Address Proof'}
                                           value={userDetails.address_proof_url}
                                           attention=
                                               {<div className={'hover:font-bold'}>
                                                   Due to our new policy, you will have to upload<br/> an address proof document.
                                                   Please upload<br/> the document, if not done already and <br/>submit KYC for review.
                                               </div>}
                                           redirect={kycRoutePaths.address_proof}/>
                            <KYCOptionCard title={'Address'}
                                           value={{
                                               [firestorePaths.doc.user.address]: userDetails.address,
                                               [firestorePaths.doc.user.zip_code]: userDetails.zip_code}}
                                           attention=
                                               {<div className={'hover:font-bold'}>
                                                   Please update your ZIP/Postal Code and <br/>submit KYC again, if not done already.
                                               </div>}
                                           redirect={kycRoutePaths.address}/>
                            <KYCOptionCard title={'PAN Card'}
                                           value={userDetails.pan_url}
                                           redirect={kycRoutePaths.pan}/>
                            <KYCOptionCard title={'Bank Details'}
                                           attention=
                                               {<div className={'hover:font-bold'}>
                                                   Please update your PAN information and <br/>submit KYC again, if not done already.
                                                </div>}
                                           value={userDetails.bank_details}
                                           redirect={kycRoutePaths.bank_details}/>
                            <KYCOptionCard title={'Bank Account (Cheque Book)'}
                                           value={userDetails.cheque_book_url}
                                           redirect={kycRoutePaths.check_book}/>
                            <KYCOptionCard title={'Photo'}
                                           value={userDetails.pic_url}
                                           redirect={kycRoutePaths.picture}/>
                            <KYCOptionCard title={'Signature'}
                                           value={userDetails.signature_url}
                                           redirect={kycRoutePaths.signature}/>
                        </VStack>
                    </div>
                    <Button className={'m-8'} colorScheme={"green"} onClick={handleSubmit} isLoading={isBusy}
                            disabled={!isReadyToSubmit}>Submit for Review</Button><br/>
                    <Button className={'m-4'} onClick={logout}>Logout</Button>
                </div>
            </div>
        )
    }catch (e) {
        return(<div>
            <Button>Refresh</Button>
        </div>)
    }
}
