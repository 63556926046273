import ImageSelector, {IMAGE_DEFAULT} from "./ImageSelector";
import {useEffect, useState} from "react";
import {Button, HStack, useToast} from "@chakra-ui/react";
import {ErrorToast} from "../../../utils/ToastBuilder";
import {uploadImage} from "../../../firebase/storage/uploadImage";
import {BiUpload} from "react-icons/bi";
import {AnimButtonContainer} from "../../buttons/AnimButtonContainer";
import {PrimaryButton} from "../../buttons/PrimaryButton";

function ImageUploader({
                           className = "",
                           title="Upload Image",
                           description="Select an image you want to upload.",
                           folder="",
                           file="",
                           randomFileName= false,
                           postSuccess=(link)=>{alert(`Upload successful ( url: ${link} )`)},
                           successLinkValueChanger = (_)=>{}
}){
    const [image, setImage] = useState(IMAGE_DEFAULT)
    const [isBusy, setIsBusy] = useState(false)
    const [isValidInput, setIsValidInput] = useState(false)

    const toast = useToast()

    function isInputValid(image, folder, file, randomFileName){
       let result = true
       if(image.raw === "" || image.preview === ""){
           toast(ErrorToast("Invalid image file", "Please select a valid file to upload"))
           result = false
       }
       if(folder === "") {
           toast(ErrorToast("Invalid folder", "The folder value in empty. Contact developer"))
           result = false
       }
       if(!randomFileName){
           if(file === ""){
               toast(ErrorToast("Invalid file name", "The file_name value in empty. Contact developer"))
               result = false
           }
       }
       return result
   }

    function isInputValidNoToast(image, folder, file, randomFileName){
        let result = true
        if(image.raw === "" || image.preview === "") result = false
        if(folder === "") result = false
        if(!randomFileName){if(file === "") result = false}
        return result
    }


    const uploadEvent = ()=>{
         setIsBusy(true)
         if(isInputValid(image, folder, file, randomFileName)){
             let fileName = file
             if(randomFileName) fileName = `${new Date().getTime()}`
             uploadImage(image.raw, folder, fileName)
                 .then(link =>{
                     setIsBusy(false)
                     successLinkValueChanger(link)
                     postSuccess(link)
                 })
                 .catch(e=>{
                     setIsBusy(false)
                     toast(ErrorToast("Upload error", e.message))
                 })
         }
     }

     useEffect(_=>{
         if(isInputValidNoToast(image, folder, file, randomFileName))
             setIsValidInput(true)
         else setIsValidInput(false)
     },[image])

    return(
        <div className={`${className} text-center w-full`}>
            <div className={'text-lg font-semibold w-full text-left pt-8'}>{title}</div>
            <div className={'font-semibold w-full text-left pb-4'}>{description}</div>
            <ImageSelector imageValueChanger={setImage}/>
            <AnimButtonContainer className={'mt-8 w-fit'}>
                <PrimaryButton
                    className={'w-full'}
                    onClick={uploadEvent}
                    busyText={"Uploading image"}
                    isBusy={isBusy}
                    disabled={!isValidInput}>
                    <HStack>
                        <BiUpload/>
                        <div className={'ml-2'}>Upload</div>
                    </HStack>
                </PrimaryButton>
            </AnimButtonContainer>
        </div>
    )
}

export default ImageUploader
