import {firestorePaths} from "../firebase/database/firestorePaths";
import Referral_Returns_Status from "../utils/Referral_Returns_Status";
import {round} from "mathjs";

/**
 *
 * @param list
 * @returns {number}
 */
export const getAvailableBalance = (list) => {
    let balance = 0
    list.filter(r=>(r[firestorePaths.doc.referral_return.status] === Referral_Returns_Status.IN_WALLET))
        .forEach(element=>{
            balance += parseFloat(element[firestorePaths.doc.referral_return.amount])
        })
    return parseFloat(balance.toFixed(2))
}
