import {differenceInDays, format, getYear} from "date-fns";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import {Button, HStack, Select, VStack} from "@chakra-ui/react";
import {SalaryStatusChip} from "./SalaryStatusChip";
import {CreditStatusChip} from "./CreditStatusChip";
import Salary_Status from "../../utils/Salary_Status";
import Credit_Status from "../../utils/Credit_Status";
import {DaysToGo} from "./DaysToGo";
import React, {useEffect, useState} from "react";

export function CreditBar({creditData, key}){
    const [data, setData] = useState(creditData)

    useEffect(_=>{
        setData(creditData)
    },[creditData])

    return(
        <div key={key} className={`w-full text-left bg-white text-emerald-800 p-4 border-b-4 border-dashed`}>
            <div className={`text-left grid md:grid-cols-2 grid-cols-1 md:grid-flow-col grid-flow-row`}>
                <div className={'w-full'}>
                    <VStack w={'full'}>
                        <div className={'font-semibold border-b-2 p-1 w-full'}>
                            Due Date
                        </div>
                        <div className={'grid w-full grid-flow-col md:grid-cols-2'}>
                            <div className={'font-semibold text-md p-1 w-full'}>
                                {
                                    `${format(new Date(data[firestorePaths.doc.salary.credit_line.date]), "dd MMM, yyyy")}`
                                }
                            </div>
                            <DaysToGo dateISO={data[firestorePaths.doc.salary.credit_line.date]}/>
                        </div>
                        <div className={'font-semibold text-lg pt-2 w-full md:text-right text-left'}>
                            {`Amount: ₹${data[firestorePaths.doc.salary.credit_line.amount]}`}
                        </div>
                    </VStack>
                </div>
                <div className={'grid md:place-content-center place-items-baseline p-2'}>
                    <div>
                        <HStack>
                            <div className={'font-semibold'}>
                                Status:
                            </div>
                            <CreditStatusChip value={data[firestorePaths.doc.salary.credit_line.status]}/>
                        </HStack>
                        <HStack pt={2}>
                            <div className={'font-semibold'}>
                                Last updated:
                            </div>
                            <div>
                                {format(new Date(data[firestorePaths.doc.salary.credit_line.last_updated]), "dd/MMM/yyyy - H:m:s")}
                            </div>
                        </HStack>
                    </div>
                </div>
            </div>
        </div>
    )
}
