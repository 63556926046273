import PanCard from "../../../components/cards/panCard";
import gradientBG from "../../../raw/img/vectors/gradient.svg";
import AadhaarCard from "../../../components/cards/aadhaarCard";
import useProgress from "../../../components/progressBar/hooks/useProgress";
import {useEffect, useState} from "react";
import {HStack, Input, useToast} from "@chakra-ui/react";
import {ConditionalRender} from "../../../components/dataSafe/ConditionalRender";
import {AnimButtonContainer} from "../../../components/buttons/AnimButtonContainer";
import {PrimaryButton} from "../../../components/buttons/PrimaryButton";
import {BiRefresh, BiShield} from "react-icons/bi";
import {ErrorToast, SuccessToast} from "../../../utils/ToastBuilder";
import PanVerify from "../../../firebase/functions/PanVerify";
import useAuthUserState from "../../../hooks/state/useAuthUserState";
import useUserDataState from "../../../hooks/state/useUserDataState";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import {DataSafeRender} from "../../../components/dataSafe/DataSafeRender";
import {Loading} from "../../../components/loading/Loding";
import avatarM from "../../../raw/img/vectors/avatar_m.svg";
import {kycV2RoutePaths} from "../../../routes/kyc_v2/kycV2RoutePaths";
import {AiOutlineArrowRight} from "react-icons/ai";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import {useNavigate} from "react-router-dom";

function PANKYCScreen({}){

    const [authUser, authToken, ___] = useAuthUserState()
    const [userData, referralData, refreshReferralData, loading] = useUserDataState()
    const [progress,_,__,setProgress] = useProgress()

    const toast = useToast()
    const nav = useNavigate()

    const [panNumber, setPanNumber] = useState("")
    const [isPanVerified, setIsPanVerified] = useState(false)
    const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true)
    const [isBusy, setIsBusy] = useState(false)

    const [fullName, setFullName] = useState("")
    const [number, setNumber] = useState("")
    const [type, setType] = useState("")
    const [panStatus, setPanStatus] = useState("")
    const [lastUpdated, setLastUpdated] = useState("")

    const handelVerificationAction = _=>{
        setIsBusy(true)
        PanVerify(authToken, panNumber).then(r => {
            setIsBusy(false)
            if(r.verified === true){
                toast(SuccessToast("Successful", "Verification Complete"))
            }else{
                toast(ErrorToast("Failed to verify", `Reason: ${r.error}`))
            }
        }).catch((e)=>{
            setIsBusy(false)
            if(e.error){
                if(e.error.status === 404) {
                    toast(ErrorToast("Failed to verify", `${e.error.status} : Pan number not fond`))
                }else{
                    toast(ErrorToast("Failed to verify", `${e.error.status} : ${e.message}`))
                }
            }else toast(ErrorToast("Failed to verify", `Unknown error`))
            console.log("error on kyc", e)
        })
    }

    useEffect(_=>{
        if(userData !==null && userData!==undefined){
            setIsPanVerified(userData[firestorePaths.doc.user.pan_valid] === true)
            const panData = userData[firestorePaths.doc.user.pan_data.path]
            if(panData){
                if(panData[firestorePaths.doc.user.pan_data.full_name])
                    setFullName(panData[firestorePaths.doc.user.pan_data.full_name])
                if(panData[firestorePaths.doc.user.pan_data.number])
                    setNumber(panData[firestorePaths.doc.user.pan_data.number])
                if(panData[firestorePaths.doc.user.pan_data.type])
                    setType(panData[firestorePaths.doc.user.pan_data.type])
                if(panData[firestorePaths.doc.user.pan_data.pan_status])
                    setPanStatus(panData[firestorePaths.doc.user.pan_data.pan_status])
                if(panData[firestorePaths.doc.user.pan_data.last_updated])
                    setLastUpdated(panData[firestorePaths.doc.user.pan_data.last_updated])
            }
        }
    },[userData])


    useEffect(_=>{
        setProgress((100 / 4) * 2)
        return _=>{
            setProgress(0)
        }
    },[])

    useEffect(_=>{
        setIsVerifyButtonDisabled(panNumber.length < 10)
    },[panNumber])

    return(
        <div className={'w-full h-full p-4'}>
            <div className={'w-full text-left flex flex-col-reverse lg:flex-row'}>
                <div className={'w-full h-full lg:ml-24 lg:mt-0 mt-8'}>
                    <div className={'text-black/60 text-[1.2em] mb-8'}>Step 2 of 4</div>
                    <div className={'text-2xl font-semibold mb-4'}>Permanent Account Number (PAN) KYC</div>
                    <p className={'lg:mr-12 leading-7'}>
                        Share your address-related information from DigiLocker account for KYC.<br/>
                        Your Aadhaar number/copy will be stored for future reference.
                        The Aadhaar UID should belong to you, it is a 12 digit number available at the bottom of your Aadhaar card.
                    </p>
                    <ConditionalRender
                        condition={!isPanVerified}
                        alt={
                            <DataSafeRender values={[authUser, authToken]} alt={<Loading/>}>
                                <div className={'w-full h-full grid grid-cols-1 gap-16 place-content-between items-center'}>
                                    <div className={'border-[2px] border-black/50 p-4 mt-8 rounded-xl flex items-center content-center'}>
                                        <div className={'grid place-content-center p-4 rounded-lg'}>
                                            <img className={'rounded-xl'}
                                                 src={avatarM}
                                                 width={180} alt={"avatar"}/>
                                        </div>
                                        <div className={'w-full h-full'}>
                                            <HStack>
                                                <div>Name: </div>
                                                <div>{fullName}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>Account Type: </div>
                                                <div>{type}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>PAN number: </div>
                                                <div>{number}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>Status: </div>
                                                <div>{panStatus}</div>
                                            </HStack>
                                            <HStack mt={2}>
                                                <div>PAN Last updated: </div>
                                                <div>{lastUpdated}</div>
                                            </HStack>
                                        </div>
                                    </div>
                                    <AnimButtonContainer className={'w-full place-content-start'}>
                                        <PrimaryButton
                                            className={'py-[16px] px-[32px] shadow-xl'}
                                            onClick={_=>{
                                                nav(`../${kycV2RoutePaths.bank_account}`)
                                            }}>
                                            <HStack>
                                                <div>Next step</div>
                                                <AiOutlineArrowRight/>
                                            </HStack>
                                        </PrimaryButton>
                                    </AnimButtonContainer>
                                    <AnimButtonContainer className={'mt-8 place-content-start'}>
                                        <SecondaryButton className={"py-[8px] px-[4px]"}
                                                         onClick={_=>{setIsPanVerified(false)}}>
                                            <HStack>
                                                <div>Restart process</div>
                                                <BiRefresh/>
                                            </HStack>
                                        </SecondaryButton>
                                    </AnimButtonContainer>
                                </div>
                            </DataSafeRender>
                        }>
                        <div className={'mt-8 lg:mr-24 tracking-[0.9em]'}>
                            <Input
                                className={'text-lg'}
                                borderColor={'black'}
                                _placeholder={{ opacity: 0.7, color: 'black' }}
                                varient={"outline"}
                                value={panNumber}
                                maxLength={10}
                                type={"text"}
                                onChange={e=>{setPanNumber(e.target.value.trim().toUpperCase())}}
                                placeholder={"PAN number (10-digits)"}/>
                        </div>
                        <AnimButtonContainer className={'mt-8 w-full place-content-start'}>
                            <PrimaryButton
                                className={'py-[16px] px-[32px] shadow-xl'}
                                isBusy={isBusy}
                                disabled={isVerifyButtonDisabled}
                                onClick={handelVerificationAction}>
                                <HStack>
                                    <span className={'text-lg'}><BiShield/></span>
                                    <div>Verify PAN</div>
                                </HStack>
                            </PrimaryButton>
                        </AnimButtonContainer>
                    </ConditionalRender>
                </div>
                <div className={'h-full w-full rounded-2xl lg:h-3/5 grid place-content-start justify-center p-4'}>
                    <PanCard className={'mb-16 lg:mt-16'} panNumber={panNumber} verified={isPanVerified} checking={isBusy}/>
                </div>
            </div>
        </div>
    )
}

export default PANKYCScreen
