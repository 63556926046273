import {TextLogo} from "../brand/TextLogo";
import {MdEmail} from "react-icons/md";
import {BsFacebook, BsFillTelephoneFill, BsTwitter, BsYoutube} from "react-icons/bs";
import {HStack} from "@chakra-ui/react";
import {RiInstagramFill} from "react-icons/ri";
import { motion } from "framer-motion"
import AnimDivClick from "../animated/AnimDivClick";
import FadingDivider from "../divider";
function Footer({className}){
    return(
        <div className={`${className} lg:pl-16 lg:pr-16 lg:pt-24 pt-16 pr-6 pl-6 pb-8`}>
            <motion.div
                initial={{opacity: 0.5}} whileInView={{opacity: 1}} transition={{duration: 0.5}}
                className={'grid lg:grid-cols-3 grid-cols-1 lg:gap-4 gap-16'}>
                <div>
                    <motion.div
                        initial={{x: -100}} whileInView={{x: 0}} transition={{duration: 0.5}}
                        className={'mb-8'}>
                        <TextLogo className={'text-[3em] w-full text-center'}/>
                        <div className={'text-left text-xs'}>India's largest trading environment</div>
                    </motion.div>
                    <motion.div
                        initial={{x: -100}} whileInView={{x: 0}} transition={{duration: 0.7}}
                        className={'flex gap-2 items-center'}>
                        <div className={'text-primary'}><MdEmail/></div>
                        <div>
                            <a href={"mailto:support@tradecops.com"} target={"_blank"}>support@tradecops.com</a>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{x: -100}} whileInView={{x: 0}} transition={{duration: 0.9}}
                        className={'flex gap-2 items-center mt-4'}>
                        <div className={'text-primary'}>
                            <BsFillTelephoneFill/>
                        </div>
                        <div>
                            <a href={"tel:+01203118877"} target={"_blank"}>+01203118877</a>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{x: -100}} whileInView={{x: 0}} transition={{duration: 1.1}}
                        className={'flex gap-2 text-left items-center mt-6 mr-16'}>
                        <div>
                            <span> Suite No B-10, H15, H Block, Sector 63, Noida, Uttar Pradesh, 201301</span>
                        </div>
                    </motion.div>
                </div>
                <div>
                    <motion.div initial={{x: -100}} whileInView={{x: 0}} transition={{duration: 0.5}}
                         className={'text-xl font-semibold text-left mb-8'}>Legal</motion.div>
                    <motion.div initial={{x: -100}} whileInView={{x: 0}} transition={{duration: 0.7}}
                                className={'flex gap-2 items-center'}>
                        <AnimDivClick>
                            <a href={"https://tradecops.com/terms-and-conditions/"} target={"_blank"}>
                                Terms and Condition
                            </a>
                        </AnimDivClick>
                    </motion.div>
                    <motion.div initial={{x: -100}} whileInView={{x: 0}} transition={{duration: 0.9}}
                                className={'flex gap-2 items-center mt-4'}>
                        <AnimDivClick>
                            <a href={"https://tradecops.com/disclaimer/"} target={"_blank"}>
                                Disclaimer
                            </a>
                        </AnimDivClick>
                    </motion.div>
                </div>
                <div>
                    <motion.div initial={{x: -100}} whileInView={{x: 0}} transition={{duration: 0.5}}
                         className={'flex text-xl font-semibold text-left mb-8'}>Social</motion.div>
                    <motion.div initial={{x: -100}} whileInView={{x: 0}} transition={{duration: 0.7}}
                         className={'text-2xl'}>
                        <HStack>
                            <BsTwitter/>
                            <div className={'w-[2px]'}/>
                            <BsFacebook/>
                            <div className={'w-[2px]'}/>
                            <RiInstagramFill/>
                            <div className={'w-[2px]'}/>
                            <BsYoutube/>
                        </HStack>
                    </motion.div>
                </div>
            </motion.div>
            <FadingDivider className={'mt-8 mb-8'}/>
            <motion.div initial={{opacity: 0.5}} whileInView={{opacity: 1}} transition={{duration: 0.7}}>
                Copyright 2023 Tradecops.com All rights reserved
            </motion.div>
        </div>
    )
}

export default Footer
