import {createSlice} from "@reduxjs/toolkit";

/**
 * Slicer obj for auth-user state
 * @type {Slice<{data: null, isLoaded: boolean}, {userLoaded: reducers.userLoaded, updateUser: reducers.updateUser}, string>}
 */
const authUserSlicer = createSlice({
    name: 'authUser',
    initialState:{
        isLoaded: false,
        data: null,
        tokenID: null,
    },
    reducers:{
        resetUserState: (state)=>{
          state.isLoaded = false
          state.data = null
          state.tokenID = null
        },
        userLoaded: (state)=>{
            state.isLoaded = true
        },
        updateUser: (state, action)=>{
            state.data = action.payload
            state.isLoaded = true
        },
        updateTokenID: (state, action)=>{
            state.tokenID = action.payload
            state.isLoaded = true
        }
    }
})

export const {resetUserState, userLoaded, updateUser, updateTokenID} = authUserSlicer.actions
export default authUserSlicer.reducer
