import {Button, Input, Textarea, useToast} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {UserModel} from "../../../firebase/models/UserModel";
import {ErrorToast, SuccessToast} from "../../../utils/ToastBuilder";
import {updateAddress} from "../../../firebase/database/update/KycUpdates";
import {useNavigate} from "react-router-dom";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import KYC_Status from "../../../utils/KYC_Status";

export function KYCAddressCollector(props){
    const userData = useSelector(state=>{return state.userData})
    const [userDetails, setUserDetails] = useState(UserModel())
    const [addressText, setAddressText] = useState('')
    const [zipCodeText, setZipCodeText] = useState('')
    const [email, setEmail] = useState('')
    const [isBusy, setIsBusy] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(true)

    const toast = useToast()
    const nav = useNavigate()

    const handleClick = _=>{
        if(addressText === ''){
            toast(ErrorToast('Invalid Address', 'Please enter a valid address'))}
        if(zipCodeText === ''){
            toast(ErrorToast('Invalid ZipCode', 'Please enter a valid zip code'))}
        else if(email === ''){
                toast(ErrorToast('Invalid user data', 'Please try refreshing the page or contact support.'))}
        else{
            setIsBusy(true)
            updateAddress(email, addressText.trim(), zipCodeText.trim()).then(_=>{
                setIsBusy(true)
                toast(SuccessToast('Updated address','Your address details has been uploaded.'))
                nav(-1)
            }).catch(e=>{
                setIsBusy(true)
                toast(ErrorToast('Unable to update to database', e.message))
            })
        }
    }

    useEffect(_=>{
        if(userData !== null && userData !== undefined ){
            if(userData.data !== null && userData.data !== undefined){
                setUserDetails(userData.data)
            }
        }
    },[userData])

    useEffect(_=>{
        if(userDetails[firestorePaths.doc.user.email] !== null && userDetails[firestorePaths.doc.user.email] !== undefined)
            setEmail(userDetails[firestorePaths.doc.user.email])
        if(userDetails[firestorePaths.doc.user.address] !== null && userDetails[firestorePaths.doc.user.address] !== undefined)
            setAddressText(userDetails[firestorePaths.doc.user.address])
        if(userDetails[firestorePaths.doc.user.zip_code] !== null && userDetails[firestorePaths.doc.user.zip_code] !== undefined)
            setZipCodeText(userDetails[firestorePaths.doc.user.zip_code])
        if(userDetails[firestorePaths.doc.user.kyc_status] !== KYC_Status.COMPLETE &&
            userDetails[firestorePaths.doc.user.kyc_status] !== KYC_Status.PENDING)
            setDisableSubmit(false)
        else setDisableSubmit(true)
    },[userDetails])

    return(
        <div className={'min-w-screen min-h-screen grid place-content-center items-center align-middle bg-slate-50 md:p-16'}>
            <div className={'min-w-full md:min-h-fit md:max-w-2/3 md:w-2/3 md:min-w-2/3 bg-white p-8 rounded-lg shadow-2xl'}>
                <div className={'text-3xl text-left font-bold'}>
                    Provide your address (as per Address-proof)
                </div>
                <div className={'mt-2 mb-16 text-left mr-16 p-2 bg-slate-50 rounded-lg font-semibold'}>
                    Please provide us with your address as per your ID proof so that we can confirm the integrity of the documents.
                </div>
                <div className={''}>
                    <div className={'font-semibold text-left pl-2 text-lg text-zinc-500'}>
                        Complete Address :
                    </div>
                    <Textarea size={'lg'} value={addressText} onChange={e=>{setAddressText(e.target.value)}}/>
                </div>
                <div className={'mt-4'}>
                    <div className={'font-semibold text-left pl-2 text-lg text-zinc-500'}>
                        Zip/Postal Code :
                    </div>
                    <Input size={'lg'} value={zipCodeText} onChange={e=>{setZipCodeText(e.target.value)}}/>
                </div>
                <div className={'m-4'}>
                    <Button isLoading={isBusy} colorScheme={'green'} disabled={disableSubmit} onClick={handleClick}>Submit</Button>
                </div>
                <div className={'mt-4'}>
                    <Button className={'w-full'} onClick={_=>{nav(-1)}}>Cancel</Button>
                </div>
            </div>
        </div>
    )
}
