
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {LoadingPageDefaultSection} from "../../components/loading/LoadingPageDefaultSection";
import {mainRoutePaths} from "../../routes/mainRoutePaths";
import {useNavigate} from "react-router-dom";
import {firestorePaths} from "../../firebase/database/firestorePaths";
import KYC_Status from "../../utils/KYC_Status";
import {Fetching} from "../../components/loading/Fetching";
import RegistrationFeesStatus from "../../utils/RegistrationFeesStatus";
import {useToast} from "@chakra-ui/react";
import {ErrorToast} from "../../utils/ToastBuilder";

export function LoadingPage(props){
    const authUser = useSelector(state=>{return state.authUser})
    const userData = useSelector(state=>{return state.userData})
    const userDeposits= useSelector(state=>{return state.userDeposits})
    const userSalary= useSelector(state=>{return state.userSalary})
    const userReferralReturns= useSelector(state=>{return state.userReferralReturns})

    const [userDetails, setUserDetails] = useState(null)
    const [isUserDataLoaded, setIsUserDataLoaded] = useState(false)

    const nav = useNavigate()
    const dispatch = useDispatch()
    const toast = useToast()

    useEffect(_=>{
        if(authUser.isLoaded){
            if(authUser.data){
                if(!authUser.data.emailVerified)
                nav(mainRoutePaths.verifyEmail)
            }
        }
    },[authUser, userData])

    useEffect(_=>{
        if(userDetails){
            if(userDetails[firestorePaths.doc.user.kyc_status] !== null && userDetails[firestorePaths.doc.user.kyc_status] !== undefined){
                if(isUserDataLoaded){
                    if(userDetails[firestorePaths.doc.user.kyc_status] === KYC_Status.INCOMPLETE ||
                        userDetails[firestorePaths.doc.user.kyc_status] === KYC_Status.PENDING ||
                        userDetails[firestorePaths.doc.user.kyc_status] === KYC_Status.REJECTED){
                        nav(mainRoutePaths.kyc_v2)
                    }
                    if((
                            userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.INCOMPLETE ||
                            userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.PENDING ||
                            userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.REJECTED
                        ) &&
                        (
                            userDetails[firestorePaths.doc.user.kyc_status] === KYC_Status.COMPLETE
                        )){
                        nav(mainRoutePaths.registration_fees)
                    }
                    if((userDetails[firestorePaths.doc.user.kyc_status] === KYC_Status.COMPLETE)&&
                    (userDetails[firestorePaths.doc.user.registration_fees_status] === RegistrationFeesStatus.COMPLETE ||
                        userDetails[firestorePaths.doc.user.registration_fees_status] === null ||
                        userDetails[firestorePaths.doc.user.registration_fees_status] === undefined)){
                        nav(mainRoutePaths.dashboard)
                    }
                }
            }
        }
    },[isUserDataLoaded, userDetails])

    useEffect(_=>{
        if(userData){
            if(userData.isLoaded) setIsUserDataLoaded(true)
            if(userData.data && userData.isLoaded){
                setUserDetails(userData.data)
            }
        }
    },[userData])

    return(
        <div className={''}>
            <div className={'w-full flex place-content-center m-auto mt-24 items-center'}>
                <div className={'mt-12 w-fit'}>
                    <LoadingPageDefaultSection/>
                    <Fetching/>
                </div>
            </div>
        </div>
    )
}
