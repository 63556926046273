import {useEffect, useState} from "react";
import {Tag} from "@chakra-ui/react";
import Referral_Returns_Status from "../../utils/Referral_Returns_Status";

export function ReferralReturnStatusChip(props){
    const status = props.status
    const className= props.className
    const [paymentStatus, setPaymentStatus] = useState({color: "gray", text: "Unknown"})

    useEffect(_=>{
        switch (status){
            case Referral_Returns_Status.IN_WALLET: setPaymentStatus({color: "green", text: "In wallet"}); break;
            case Referral_Returns_Status.PENDING_WITHDRAWAL: setPaymentStatus({color: "yellow", text: "Pending withdrawal"}); break;
            case Referral_Returns_Status.REJECTED: setPaymentStatus({color: "red", text: "Rejected"}); break;
            case Referral_Returns_Status.WITHDRAWN: setPaymentStatus({color: "gray", text: "Withdrawn"}); break;
            default: setPaymentStatus({color: "gray", text: "Unknown"}); break;
        }
    },[status])

    return(
        <div className={`${className}`}>
            <Tag className={''} variant={'solid'} colorScheme={paymentStatus.color}>{paymentStatus.text}</Tag>
        </div>
    )
}
