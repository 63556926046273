
import {doc, getFirestore, updateDoc} from "firebase/firestore";
import {firestorePaths} from "../firestorePaths";
import KYC_Status from "../../../utils/KYC_Status";

export function updateKYCDb(field, email, url){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        if(email !== null && email !== undefined && url !== null && url !== undefined){
            const docRef = doc(db,firestorePaths.collections.users,email)
            updateDoc(docRef, {[field]: url})
                .then(_=>resolve(0))
                .catch(e=>reject(e))
        }
    })
}

export function updateAddress(email, address_value, zip_value){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        if(email !== null && email !== undefined && address_value !== undefined && zip_value !== undefined){
            const docRef = doc(db,firestorePaths.collections.users,email)
            updateDoc(docRef,
                {
                    [firestorePaths.doc.user.address]: address_value,
                    [firestorePaths.doc.user.zip_code]: zip_value
                })
                .then(_=>resolve(0))
                .catch(e=>reject(e))
        }
    })
}

export function updateBankDetails(email, value){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        if(email !== null && email !== undefined && value !== undefined){
            const docRef = doc(db,firestorePaths.collections.users,email)
            updateDoc(docRef, {[firestorePaths.doc.user.bank_details.path]: value})
                .then(_=>resolve(0))
                .catch(e=>reject(e))
        }
    })
}

export function submitKYC(email){
    return new Promise((resolve, reject)=>{
        const db = getFirestore()
        if(email !== null && email !== undefined){
            const docRef = doc(db,firestorePaths.collections.users,email)
            updateDoc(docRef, {[firestorePaths.doc.user.kyc_status]: KYC_Status.PENDING})
                .then(_=>resolve(0))
                .catch(e=>reject(e))
        }
    })
}
