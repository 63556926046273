import {motion} from "framer-motion";

function FadingDivider({className}) {
    return(
        <motion.div
            initial={{opacity: 0.5}} whileInView={{opacity: 0.7}} transition={{duration: 0.5}}
            className={`${className} h-[2px] bg-gradient-to-r from-transparent to-transparent via-zinc-500`}></motion.div>
    )
}

export default FadingDivider
