import FadingDivider from "../../divider";
import Emblem from "../../../raw/img/vectors/embing.webp"
import eAadhaar from "../../../raw/img/vectors/eaadhaar.svg"
import sampleQR from "../../../raw/img/vectors/sample_qr.svg"
import useDefaultTilt from "../../animated/hooks";
import TiltDiv from "../../animated/TiltDiv";
import {gsap} from "gsap";
import {TextPlugin} from 'gsap/dist/TextPlugin';
import {useEffect, useRef, useState} from "react";
import stacksVector from "../../../raw/img/vectors/stacks.svg"
import {fadeOutPreset, fadeInPreset} from "../../animated/GSAPEffects"
import noise from "../../../raw/img/graphics/noise.png"
import gradientBG from "../../../raw/img/vectors/gradient.svg";

function AadhaarCard({className="",
                         name="",
                         gender="",
                         uid="",
                         verified= false,
                         checking=false}){
    let defaultOptions = useDefaultTilt()
    defaultOptions = {...defaultOptions,
        /*"full-page-listening":  true,*/
        /*glare: true,
        maxGlare: 0.5,*/}

    let verifiedCardRef = useRef()
    let verifiedTextRef = useRef()
    let cardRef = useRef()

    function generateRandomNumberString(length) {
        let uuid = '';
        for (let i = 0; i < 12; i++) {
            const digit = Math.floor(Math.random() * 10); // Generate a random digit (0-9)
            uuid += digit; // Append the digit to the UUID
        }
        return uuid;
    }

    function getUId(uid=""){
        let result = uid
        while(result.length < 12){
            result = result.concat("x")
        }
        return result
    }

    useEffect(_=>{
        let ctx = gsap.context(_=>{
            gsap.fromTo(".main-card",
                {opacity: 0, rotationX:90, duration: 2},
                {opacity: 1, rotationX: 0, duration: 2})
        })
        return (_=>{
            ctx.kill()
        })
    },[])

    useEffect(_=>{
            let ctx = gsap.context((context)=>{
                const timeLine = gsap.timeline({repeat: checking?-1:0, repeatDelay: 0.2})
                gsap.registerPlugin(TextPlugin);
                if(checking){
                        timeLine.to(".card-digit",
                            {textContent: generateRandomNumberString(12),
                                roundProps: "textContent" })
                }
                else {
                    timeLine.to(".card-digit", {
                        textContent: getUId(generateRandomNumberString(uid.length)),
                        duration: 0.1,
                        roundProps: "textContent"
                    }).to(".card-digit",
                        {textContent: (verified)? "Verified" : getUId(uid),
                            duration: 0})
                }
                if(verified){
                    gsap.to(cardRef.current, fadeOutPreset)
                    gsap.to(verifiedCardRef.current, fadeInPreset)
                }else{
                    gsap.to(verifiedCardRef.current, fadeOutPreset)
                    gsap.to(cardRef.current, fadeInPreset)
                }

                return () => {
                    timeLine.kill();
                }
            })
        return(_=>{
            ctx.kill()
        })
    },[checking, uid, verified])

    return(
            <TiltDiv
                options={defaultOptions}
                className={`${className} main-card transform-gpu hover:rotate-45 lg:text-sm text-xs w-[280px] lg:w-[400px] h-[180px] lg:h-[230px] rounded-2xl backdrop-blur-xl shadow-lg`}>
                <div ref={verifiedCardRef} style={{backgroundImage: `url(${stacksVector})`}} className={`${verified?"opacity-50": "opacity-0"} absolute w-full h-full rounded-lg grid place-content-center`}>
                    <span ref={verifiedTextRef} className={'text-white m-auto text-2xl font-semibold tracking-wider'}>Verified</span>
                </div>
                <div ref={cardRef} className={`relative w-full h-full lg:p-4 p-2lg:p-4 p-2 ${verified? "opacity-0": "opacity-100"} rounded-2xl`}
                     style={{backgroundImage: `url(${noise})`}}>
                    <div className={'flex justify-between items-center content-center'}>
                        <img src={Emblem} width={'25'} alt={"emblem"}/>
                        <div>Aadhaar Card</div>
                        <img src={eAadhaar} width={'50'} alt={"emblem"}/>
                    </div>
                    <div className={'mt-4 grid grid-cols-2'}>
                        <div>
                            <div className={'flex gap-4'}>
                                <div>Name: </div>
                                <div className={'text-left'}>xxxx xx xxxxxx</div>
                            </div>
                            <div className={'flex gap-4 mt-2'}>
                                <div>Gender: </div>
                                <div>xxxxx</div>
                            </div>
                        </div>
                        <div className={'grid place-content-end w-full h-full'}>
                            <img src={sampleQR} className={'lg:w-[100px] w-[50px]'} alt={"emblem"}/>
                        </div>
                    </div>
                    <div className={'mt-4'}>
                        <FadingDivider/>
                    </div>
                    <div className={`card-digit lg:text-xl text-lg w-full text-center flex gap-2 justify-center place-content-center content-baseline lg:tracking-[0.7em] tracking-[0.5em]`}>
                        {
                            "xxxxxxxxx"
                        }
                    </div>
                </div>
            </TiltDiv>
    )
}

export default AadhaarCard
