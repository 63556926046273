import {resetUserState} from "../reducer/authUserReducer";
import {resetUserDataState} from "../reducer/userDataReducer";
import {resetDepositsList} from "../reducer/userDepositsReducer";
import {resetSalaryState} from "../reducer/userSalaryReducer";
import {resetReferralReturns} from "../reducer/userReferralReturnsReducer";
import {dataStore} from "../datastore"

function clearAllState(){
            dataStore.dispatch(resetUserState())
            dataStore.dispatch(resetUserDataState())
            dataStore.dispatch(resetDepositsList())
            dataStore.dispatch(resetReferralReturns())
            dataStore.dispatch(resetSalaryState())
}

export default clearAllState
