import {useNavigate, useParams} from "react-router-dom";
import {Button, Center, CloseButton, Divider, Image, Input, Select, useToast, VStack} from "@chakra-ui/react";
import PaymentTypes from "../../../utils/PaymentType";
import {useEffect, useState} from "react";
import {addDeposit} from "../../../firebase/database/add/AddDeposit";
import {useSelector} from "react-redux";
import {UserModel} from "../../../firebase/models/UserModel";
import {firestorePaths} from "../../../firebase/database/firestorePaths";
import {DepositModel} from "../../../firebase/models/DepositModel";
import {uploadReceipt} from "../../../firebase/storage/uploadReceipt";
import {ErrorToast, SuccessToast} from "../../../utils/ToastBuilder";
import bankLogo from "../../../raw/img/bank_logo.png";
import KYC_Status from "../../../utils/KYC_Status";

export function Payment(props){
    const {amount} = useParams()
    const toast = useToast()
    const nav = useNavigate()

    const userData = useSelector(state=>{return state.userData})

    const [userEmail, setUserEmail] = useState(null)
    const [userDetails, setUserDetails] = useState(UserModel())
    const [image, setImage] = useState({ preview: "", raw: "" })
    const [transactionNumberText, setTransactionNumberText] = useState('')
    const [paymentType, setPaymentType] = useState('')
    const [paymentDate, setPaymentDate] = useState(null)
    const [isSubmitDisabled, setIsSubmitDisabled] =useState(true)
    const [isBusy, setIsBusy] = useState(false)

    const handleChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    }

    const validateFileSize = (file)=>{
        const MAX_FILE_SIZE_KB = 2048
        const FILE_SIZE = file.size / 1024
        return (FILE_SIZE <= MAX_FILE_SIZE_KB)

    }

    function isValid(){
        return(
            transactionNumberText !== "" &&
            (paymentType === PaymentTypes.qr_code ||
                paymentType === PaymentTypes.bank_transfer) &&
            paymentDate !== null &&
            paymentDate !== undefined &&
            paymentDate !== '' &&
            image.raw !== "")
    }

    const submitHandle = ()=>{
        if(isValid()){
            setIsBusy(true)
            if(userEmail !== null && userEmail !== undefined && userEmail !== ''){
                if(image.raw !== '' && image.raw !== null && image.raw !== undefined) {
                    uploadReceipt(userEmail, transactionNumberText, image.raw).then(link=>{
                        if(link !== '' && link !== null && link !== undefined){
                            addDeposit(userEmail,
                                DepositModel(
                                    null,
                                    userEmail,
                                    parseInt(amount),
                                    paymentType,
                                    transactionNumberText,
                                    link,
                                    paymentDate))
                                .then(r =>{
                                    setIsBusy(false)
                                    toast(SuccessToast("Successful", "Deposit submitted for review."))
                                    nav(-1)
                                }).catch(e=>{
                                setIsBusy(false)
                                toast(ErrorToast("Failed", e.message))
                            })
                        }else{
                            setIsBusy(false)
                            toast(ErrorToast("Failed", "Receipt file url invalid"))}
                    }).catch(e=>{
                        setIsBusy(false)
                        toast(ErrorToast("Failed", e.message))
                    })
                }else{
                    setIsBusy(false)
                    toast(ErrorToast("Failed", "Invalid image file"))}
            }else{
                setIsBusy(false)
                toast(ErrorToast("Failed", "User email not defined"))}
        }else{
            toast(ErrorToast("Invalid Fields", "Some fields are missing. Please enter all the details."))
        }
    }

    useEffect(_=>{
        if(image.raw !== null && image.raw !== undefined && image.raw !== ""){
            if(!validateFileSize(image.raw)){
                toast(ErrorToast('File too big', 'File size should not be more than 2 mb'))
                setIsSubmitDisabled(true)
            } else{
                if(paymentDate !== null && paymentDate !== undefined && paymentDate !== '')
                    if(paymentType !== null)
                        if(transactionNumberText !== '')
                                setIsSubmitDisabled(false)
                        else setIsSubmitDisabled(true)
                    else setIsSubmitDisabled(true)
                else setIsSubmitDisabled(true)
            }
        }else setIsSubmitDisabled(true)

    },[image, paymentDate, paymentType, transactionNumberText])

    useEffect(_=>{
        if(userData !== null && userData !== undefined && userData.isLoaded)
            setUserDetails(userData.data)
    },[userData])

    useEffect(_=>{
        if(userDetails !== undefined)
            setUserEmail(userDetails[firestorePaths.doc.user.email])
    },[userDetails])


    return(
        <div className={'w-full max-w-full md:p-8 flex flex-col md:flex-row gap-8 md:gap-4'}>
            <div className={'w-full rounded-xl h-full'}>
                <div className={'w-full bg-emerald-500 text-white rounded-md p-4 shadow-xl shadow-emerald-200 ' +
                    'flex flex-row justify-between'}>
                    <div>
                        <div className={'w-full text-lg text-left font-semibold'}>Amount to be paid</div>
                        <div className={'w-full text-3xl text-left font-semibold'}>₹ {amount}</div>
                    </div>
                </div>
                <div className={'rounded-xl md:mt-20 mt-8 text-black grid grid-flow-row gap-8 md:gap-2'}>
                    <div className={'mt-4 mb-4 text-xl font-bold'}>Payment options and details</div>
                    <div className={'w-full text-left rounded-xl p-2 border-2 border-dashed'}>
                        <div className={'max-w-full text-lg text-center font-semibold gap-4'}>Bank Transaction Details</div>
                        <Center>
                            <Image src={bankLogo}
                                   objectFit={'contain'}
                                   alt={'bank logo'}/>
                        </Center>
                        <div className={'text-lg text-left pl-2'}>
                            Bank Name: <span className={'ml-2 font-bold font-mono'}>Indusind Bank</span>
                        </div>
                        <div className={'text-lg text-left pl-2 font-mono'}>
                            Account no: <span className={'ml-2 font-bold'}>252020304050</span>
                        </div>
                        <div className={'text-lg text-left pl-2 font-mono'}>
                            IFSC: <span className={'ml-2 font-bold'}>INDB0000162</span>
                        </div>
                        <div className={'text-lg text-left pl-2 font-mono'}>
                            Account Name: <span className={'ml-2 font-bold'}>Tradecops Financial Service</span>
                        </div>
                        <div className={'text-lg text-left pl-2 font-mono'}>
                            <span>Bank Address:</span><span className={'ml-2 font-bold'}>BRANCH SEC 63 NOIDA U.P.</span>
                        </div>

                    </div>
                    <div className={'w-full h-full text-center rounded-xl p-2 border-2 border-dashed'}>
                        <div className={'w-full text-lg font-semibold gap-4'}>QR Code</div>
                        <Center>
                            <Image src={`https://firebasestorage.googleapis.com/v0/b/trade-cops-india.appspot.com/o/data%2Ftracecops_qr.jpg?alt=media&token=24ef5f77-ecb7-48bc-9f97-1eb24620ea6f`}
                                   objectFit={'contain'}
                                   alt={'bank logo'}/>
                        </Center>
                    </div>
                </div>
            </div>
            <div className={'w-full rounded-xl p-4 h-full shadow-xl mb-16'}>
                <div className={'w-full text-4xl text-left font-bold'}>Upload receipt</div>
                <div className={'w-full text-left pt-3'}>Please upload the required information of the transaction correctly for verification.</div>
                <div className={'mt-16'}>
                    <VStack className={'gap-4'}>
                        <div className={'w-full'}>
                            <div className={'w-full text-left mb-2 text-zinc-600 text-lg font-semibold'}>Transaction Date</div>
                            <Divider/>
                            <Input placeholder={'Transaction Date'} value={paymentDate !== null ? paymentDate.value: ''}
                                   onChange={e=>{setPaymentDate(e.target.valueAsDate)}} type={'date'}/>
                        </div>
                        <Select placeholder='Transfer Mode' value={paymentType}
                                onChange={e=>{setPaymentType(e.target.value)}}>
                            <option value={PaymentTypes.bank_transfer}>Bank Transfer</option>
                            <option value={PaymentTypes.qr_code}>QR Code</option>
                        </Select>

                        {
                            image.preview !== ""?
                                <div className={'grid w-full min-w-2/3'}>
                                    <CloseButton className={'place-self-end'} size={'lg'}
                                                 onClick={_=>{setImage({ preview: "", raw: "" })}}/>
                                    <Center className={'grid place-content-center p-4'}>
                                        <Image w={'350px'} h={'240px'} className={'w-full md:w-1/2'} borderRadius={'2xl'}
                                               objectFit='contain'
                                               src={image.preview} alt={'preview_document'}/>
                                    </Center>
                                </div>
                                :
                                <div>
                                    <label htmlFor={'img-chooser'}>
                                        <div className={'grid place-content-center text-slate-500 border-slate-300 border-dashed border-4 ' +
                                            'p-4 pt-16 pb-16 rounded-2xl cursor-pointer hover:border-emerald-300 hover:text-emerald-500'}>
                                            <i className="fi fi-rr-picture text-[4.5rem] text-slate-300"></i>
                                            <div className={'text-center m-2 text-lg'}>
                                                Click to upload receipt image ( .jpg )<br/>[ max size: 2 mb ]
                                            </div>
                                        </div>
                                    </label>
                                    <Input id={'img-chooser'} type={'file'} accept={'.jpg'}
                                           style={{ display: "none" }} onChange={handleChange}/>
                                </div>
                        }
                        <Input size={'lg'} value={transactionNumberText}
                               onChange={e=>{setTransactionNumberText(e.target.value)}}
                               placeholder={'Transaction number'} fontSize={'lg'} fontWeight={'semibold'}/>
                        <div className={'w-full flex flex-row-reverse pt-8'}>
                            <Button size={'lg'} disabled={(isSubmitDisabled || isBusy)}
                                    onClick={submitHandle}
                                    isLoading={isBusy}
                                    loadingText={'Submitting'}
                                    colorScheme={'green'}>Submit for Review</Button>
                        </div>
                    </VStack>
                </div>
            </div>
        </div>
    )
}
