import {useEffect, useRef} from "react";
import VanillaTilt from "vanilla-tilt";

function TiltDiv({options= null,
                     className="",
                     children,
                     style={},
                     onMouseEnter=()=>{},
                     onMouseLeave=()=>{}}){
    const tilt = useRef(null);

    useEffect(() => {
        VanillaTilt.init(tilt.current, options);
    }, [options]);

    return(
        <div style={style}
             className={`${className}`}
             ref={tilt}
             onMouseEnter={onMouseEnter}
             onMouseLeave={onMouseLeave()}>
            {children}
        </div>
    )
}

export default TiltDiv
