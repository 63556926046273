import {createSlice} from "@reduxjs/toolkit";

const userReferralReturnsReducer = createSlice({
    name: 'userReferralReturns',
    initialState:{
        isLoaded: false,
        data: [],
    },
    reducers:{
        resetReferralReturns: (state)=>{
            state.isLoaded = false
            state.data = []
        },
        pushReferralReturns: (state, action)=>{
            state.data.push(action.payload)
            state.isLoaded = true
        },
        setReferralReturnsList: (state, action)=>{
            state.data = action.payload
            state.isLoaded = true
        },
        referralReturnsListLoaded: (state)=>{
            state.isLoaded = true
        }
    }
})

export const  {resetReferralReturns, pushReferralReturns, setReferralReturnsList, referralReturnsListLoaded} = userReferralReturnsReducer.actions
export default userReferralReturnsReducer.reducer
