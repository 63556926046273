import {Spinner} from "@chakra-ui/react";
import {Logo} from "../brand/Logo";

export function LoadingPageDefaultSection(props){
    return(
        <div>
            <Logo/>
        </div>
    )
}
