import {useEffect, useState} from "react";
import {ErrorToast} from "../../../utils/ToastBuilder";
import {IMAGE_DEFAULT} from "../ImageUploader";
import {AiOutlineEdit, AiOutlineUser} from "react-icons/ai";
import {Avatar, Box, Input, useToast} from "@chakra-ui/react";
import {PrimaryButton} from "../../buttons/PrimaryButton";

function ProfileImageSelector({className="", imageValueChanger = null}){

    const toast = useToast()

    const [image, setImage] = useState(IMAGE_DEFAULT)

    const validateFileSize = (file)=>{
        const MAX_FILE_SIZE_KB = 2048
        const FILE_SIZE = file.size / 1024
        return (FILE_SIZE <= MAX_FILE_SIZE_KB)

    }
    const handleChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    }

    useEffect(_=>{
        if(image.raw !== "" && image.preview !== ""){
            if(validateFileSize(image.raw)) {
                if(imageValueChanger !== null)
                    imageValueChanger?.(image)
            }
            else{
                toast(ErrorToast("Media error", "File too big! Select another image."))
                if(imageValueChanger !== null)
                    imageValueChanger?.(IMAGE_DEFAULT)
            }
        }else{
            if(imageValueChanger !== null)
                imageValueChanger?.(IMAGE_DEFAULT)
        }
    },[image])

    return(
            <div className={`${className} max-w-[200px] text-center grid grid-col place-content-center`}>
                <label htmlFor={'img-chooser'}>
                    <Box className={'w-[200px] m-auto grid bg-red place-content-center'}>
                        <Avatar className={'relative'}
                                src={image.preview}
                                w={200}
                                h={200}
                                icon={<AiOutlineUser fontSize={"1.5em"}/>}
                                bg={"#bbbbbb"}/>
                        <div className={'w-[200px] h-[200px] rounded-[1000px] hover:bg-white/40 bg-transparent hover:border-[2px] border-black/10 hover:cursor-pointer backdrop-blur-none opacity-0 hover:opacity-100 hover:backdrop-blur-xl absolute grid place-content-center'}>
                            <div className={"text-black text-3xl"}>
                                <AiOutlineEdit/>
                            </div>
                        </div>
                    </Box>
                </label>
                <Input id={'img-chooser'} type={'file'} accept={'.jpg'}
                       style={{ display: "none" }} onChange={handleChange}/>
                <div className={'max-w-[200px] min-w-[200px] m-auto mt-4'}>
                    {
                        image.preview !== ""?
                            <PrimaryButton
                                onClick={_=>{setImage({ preview: "", raw: "" })}}>
                                Clear
                            </PrimaryButton>
                        :
                        <span>
                        Click on the image to select a profile picture
                        </span>
                    }
                </div>
            </div>
    )
}

export default ProfileImageSelector
