import {Logo} from "../brand/Logo";
import {Text} from "@chakra-ui/react";

export function NavButton(props){
    const text = props.text
    const icon = props.icon
    const className = props.className
    const action = props.action

    return(
        <div className={`w-full hover:bg-black hover:text-white text-[1.2em] text-left rounded-md cursor-pointer py-4 pl-4 ${className}`}
            onClick={action}>
            {text}
        </div>
    )
}
