export const mainRoutePaths = {
    login: "login",
    registration: "registration",
    registrationReferral: "registration/:referral",
    dashboard: "dashboard",
    forgotPassword: "forgot-password",
    verifyEmail: "verify-email",
    kyc: "kyc",
    kyc_v2: "kyc_v2",
    registration_fees: "registration_fees",
    test: "test",
}
