import KYC_Status from "../../utils/KYC_Status";

export const UserModel = (email=null,
                          first_name=null,
                          middle_name=null,
                          last_name=null,
                          referral=null,
                          dob=null,
                          phone=null,
                          registered_date = null,

                          referral_code=null,
                          address=null,
                          zip_code = null,
                          pic_url=null,
                          id_proof_url=null,
                          address_proof_url = null,
                          kyc_status = null,
                          pan_url=null,
                          cheque_book_url=null,
                          bank_account_no=null,
                          bank_account_ifsc=null,
                          bank_account_holder_name=null,
                          bank_name= null,
                          bank_address=null,
                          bank_account_type=null,
                          kyc_comment = null,
                          signature_url=null,
                          admin_referral_allowed = false,
                          referral_access_requested = false,
                          registration_eligible = true,
                          registration_fees_status = null,
                          registration_fees_id = null,
                          amount = 0)=>{
    return {
        email:email,
        first_name:first_name,
        middle_name:middle_name,
        last_name:last_name,
        referral:referral,
        referral_code: referral_code,
        dob:dob,
        phone:phone,
        address:address,
        zip_code: zip_code,
        kyc_status: kyc_status,
        kyc_comment: kyc_comment,
        pic_url:pic_url,
        id_proof_url:id_proof_url,
        address_proof_url: address_proof_url,
        pan_url:pan_url,
        cheque_book_url:cheque_book_url,
        bank_details: {
            bank_account_no:bank_account_no,
            bank_account_holder_name: bank_account_holder_name,
            bank_account_ifsc:bank_account_ifsc,
            bank_name:bank_name,
            bank_address:bank_address,
            bank_account_type:bank_account_type,
        },
        signature_url: signature_url,
        amount: amount,
        admin_referral_allowed: admin_referral_allowed,
        referral_access_requested: referral_access_requested,
        registered_date: registered_date,
        registration_eligible: registration_eligible,
        registration_fees_status: registration_fees_status,
        registration_fees_id: registration_fees_id,
    }
}
