import {
    Drawer, DrawerBody,
    DrawerCloseButton,
    DrawerContent, DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Icon,
    useDisclosure
} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import {AiOutlineAlignRight} from "react-icons/ai";
import {NavProfileSection} from "./NavProfileSection";
import {Loading} from "../loading/Loding";
import {NavButton} from "./NavButton";
import {dashboardRoutePaths} from "../../routes/dashboard/dashboardRoutePaths";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Logo} from "../brand/Logo";
import {TextLogo} from "../brand/TextLogo";
import {AlternateLogoutButton} from "../buttons/AlternateLogoutButton";

export default function NavDrawer(props){
    const { isOpen, onOpen, onClose } = useDisclosure()
    const className = props.className
    const btnRef = useRef()

    const nav = useNavigate()
    const userData = useSelector(state=>{return state.userData})
    const [userDetails, setUserDetails] = useState()

    useEffect(_=>{
        if(userData !== null && userData !== undefined){
            if(userData.data !== null && userData.data !== undefined && userData.isLoaded){
                setUserDetails(userData.data)
            }
        }
    },[userData])

    useEffect(_=>{

    },[userDetails])

    return(
        <div className={className}>
            <div className={'w-full p-4 text-left'} ref={btnRef} onClick={onOpen}>
                <div className={'flex gap-4 items-center'}>
                    <Icon color={'black'} w={6} h={6} as={AiOutlineAlignRight}/>
                    <TextLogo className={'text-lg'}/>
                </div>
            </div>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay bg={'rgba(255,255,255,0.1)'}/>
                <DrawerContent bg={"rgba(255,255,255,0)"} className={'bg-white/80 backdrop-blur-2xl'}>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <TextLogo className={'text-2xl'}/>
                    </DrawerHeader>
                    <DrawerBody>
                        <div className={'grid gap-4 grid-flow-row'}>
                            {/*<div className={''}><Logo/></div>*/}
                            {(userDetails !== null && userDetails !== undefined)?
                                <NavProfileSection user={userDetails}/>:<div><Loading/></div>
                            }
                            <NavButton text={'Home'} icon={'fi fi-rr-home'} action={_=>{
                                nav('')
                                onClose()
                            }}/>
                            <NavButton text={'Profile'} icon={'fi fi-rr-user'} action={_=>{
                                nav(dashboardRoutePaths.profile)
                                onClose()
                            }}/>
                            <NavButton text={'Deposit'} icon={'fi fi-rr-credit-card'} action={_=>{
                                nav(dashboardRoutePaths.deposit)
                                onClose()
                            }}/>
                            <NavButton text={'Referrals'} icon={'fi fi-rr-users'} action={_=>{
                                nav(dashboardRoutePaths.referral)
                                onClose()
                            }}/>
                            <NavButton text={'Salary'} icon={'fi fi-rr-users'} action={_=>{
                                nav(dashboardRoutePaths.salary)
                                onClose()
                            }}/>
                            <div className={'mt-6 w-full'}>
                                <AlternateLogoutButton/>
                            </div>
                        </div>
                    </DrawerBody>
                    <DrawerFooter>
                        India's first trading environment
                        {/*TODO: Social media handles*/}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </div>
    )
}
