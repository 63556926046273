import AnimDivClick from "../animated/AnimDivClick";
import {logout} from "../../firebase/auth/callbacks";
import {HStack} from "@chakra-ui/react";
import {BiLogOut} from "react-icons/bi";

function SecondaryButton({className= "", onClick, children}){
    return(
        <AnimDivClick
            className={`${className} grid place-content-center font-semibold w-fit h-full border-black hover:text-primary border-b-2 border-black hover:border-primary`}
            onClick={onClick}>
            {children}
        </AnimDivClick>
    )
}

export default SecondaryButton
