import useDefaultTilt from "../../animated/hooks";
import {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {fadeInPreset, fadeOutPreset} from "../../animated/GSAPEffects";
import TiltDiv from "../../animated/TiltDiv";
import stacksVector from "../../../raw/img/vectors/stacks.svg";
import noise from "../../../raw/img/graphics/noise.png";
import Emblem from "../../../raw/img/vectors/embing.webp";
import eAadhaar from "../../../raw/img/vectors/eaadhaar.svg";
import sampleQR from "../../../raw/img/vectors/sample_qr.svg";
import FadingDivider from "../../divider";
import {TextPlugin} from 'gsap/dist/TextPlugin';
import {AiOutlineBank} from "react-icons/ai";
import {TextLogo} from "../../brand/TextLogo";
import {HStack} from "@chakra-ui/react";

function BankCard({className="",
                      name="",
                      ifsc="",
                      accountNumber="",
                      confirmAccountNumber="",
                      bankName= "",
                      verified= false,
                      checking=false}){
    let defaultOptions = useDefaultTilt()
    defaultOptions = {...defaultOptions,
        /*"full-page-listening":  true,*/
        /*glare: true,
        maxGlare: 0.5,*/}

    let verifiedCardRef = useRef()
    let verifiedTextRef = useRef()
    let cardRef = useRef()

    function generateRandomNumberString(length) {
        let uuid = '';
        for (let i = 0; i < 10; i++) {
            const digit = Math.floor(Math.random() * 10); // Generate a random digit (0-9)
            uuid += digit; // Append the digit to the UUID
        }
        return uuid;
    }

    function getUId(uid=""){
        let result = uid
        while(result.length < 10){
            result = result.concat("x")
        }
        return result
    }

    useEffect(_=>{
        let ctx = gsap.context(_=>{
            gsap.fromTo(".main-card",
                {opacity: 0, rotationX:90, duration: 2},
                {opacity: 1, rotationX: 0, duration: 2})
        })
        return (_=>{
            ctx.kill()
        })
    },[])

    useEffect(_=>{
        let ctx = gsap.context((context)=>{
            const timeLine = gsap.timeline({repeat: checking?-1:0, repeatDelay: 0.2})
            gsap.registerPlugin(TextPlugin);
            if(checking){
                timeLine.to(".card-digit",
                    {textContent: generateRandomNumberString(10),
                        roundProps: "textContent" })
            }
            else {
                timeLine.to(".card-digit", {
                    textContent: getUId(generateRandomNumberString(accountNumber.length)),
                    duration: 0.1,
                    roundProps: "textContent"
                }).to(".card-digit",
                    {textContent: (verified)? "Verified" : getUId(accountNumber),
                        duration: 0})
            }
            if(verified){
                gsap.to(cardRef.current, fadeOutPreset)
                gsap.to(verifiedCardRef.current, fadeInPreset)
            }else{
                gsap.to(verifiedCardRef.current, fadeOutPreset)
                gsap.to(cardRef.current, fadeInPreset)
            }

            return () => {
                timeLine.kill();
            }
        })
        return(_=>{
            ctx.kill()
        })
    },[checking, accountNumber, ifsc, name, verified])

    return(
        <TiltDiv
            options={defaultOptions}
            className={`${className} main-card transform-gpu hover:rotate-45 lg:text-sm text-xs w-[300px] lg:w-[400px] h-[200px] lg:h-[230px] rounded-2xl backdrop-blur-xl shadow-lg`}>
            <div ref={verifiedCardRef} style={{backgroundImage: `url(${stacksVector})`}} className={`${verified?"opacity-50": "opacity-0"} absolute w-full h-full rounded-lg grid place-content-center`}>
                <span ref={verifiedTextRef} className={'text-white m-auto text-2xl font-semibold tracking-wider'}>Verified</span>
            </div>
            <div ref={cardRef} className={`relative w-full h-full lg:p-4 p-2 lg:p-4 p-2 ${verified? "opacity-0": "opacity-100"} rounded-2xl`}
                 style={{backgroundImage: `url(${noise})`}}>
                <div className={'flex justify-between items-center content-center'}>
                    <div><TextLogo className={'text-lg'}/></div>
                    <div className={'font-semibold'}>Bank Verification</div>
                </div>
                <div className={'m-4 w-full text-left'}>
                    <HStack mt={6} w={'full'}>
                        <div>IFSC: </div>
                        <div>{ifsc}</div>
                    </HStack>
                    <div className={`mt-3 card-digit lg:text-xl text-lg w-full text-left lg:tracking-[0.4em] tracking-[0.2em] ${accountNumber === confirmAccountNumber? "text-black": "text-red-500"}`}>
                        {
                            "xxxxxxxxx"
                        }
                    </div>
                    <HStack mt={2} w={'full'}>
                        <div className={'w-full text-left'}>{name}</div>
                    </HStack>
                </div>
                <div className={'mt-4'}>
                    <FadingDivider/>
                </div>
                <div className={'mt-4 visa-digit-logo grid place-content-center'}>
                    {bankName}
                </div>
            </div>
        </TiltDiv>
    )
}

export default BankCard
